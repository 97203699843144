enum Permissions {
    ORDERS_READ = "orders.read",
    ORDERS_PAY = "orders.pay",
    LOCATIONS_CREATE = "locations.create",
    LOCATIONS_READ = "locations.read",
    LOCATIONS_UPDATE = "locations.update",
    LOCATIONS_DELETE = "locations.delete",
    CATALOGS_CREATE = "catalogs.create",
    CATALOGS_READ = "catalogs.read",
    CATALOGS_UPDATE = "catalogs.update",
    CATALOGS_DELETE = "catalogs.delete",
    AUTHENTICATION = "authentication",
    USERS_CREATE = "users.create",
    USERS_READ = "users.read",
    USERS_UPDATE = "users.update",
    USERS_DELETE = "users.delete",
    CUSTOMERS_CREATE = "customers.create",
    CUSTOMERS_READ = "customers.read",
    CUSTOMERS_UPDATE = "customers.update",
    CUSTOMERS_DELETE = "customers.delete",
    ACCOUNTS_CREATE = "accounts.create",
    ACCOUNTS_READ = "accounts.read",
    ACCOUNTS_UPDATE = "accounts.update",
    ACCOUNTS_DELETE = "accounts.delete",
    PRODUCTS_CREATE = "products.create",
    PRODUCTS_READ = "products.read",
    PRODUCTS_UPDATE = "products.update",
    PRODUCTS_DELETE = "products.delete",
    DEALS_CREATE = "deals.create",
    DEALS_READ = "deals.read",
    DEALS_UPDATE = "deals.update",
    DEALS_DELETE = "deals.delete",
    OPTIONS_CREATE = "options.create",
    OPTIONS_READ = "options.read",
    OPTIONS_UPDATE = "options.update",
    OPTIONS_DELETE = "options.delete",
    DISCOUNTS_CREATE = "discounts.create",
    DISCOUNTS_READ = "discounts.read",
    DISCOUNTS_UPDATE = "discounts.update",
    DISCOUNTS_DELETE = "discounts.delete",
    CONNECTORS_CREATE = "connectors.create",
    CONNECTORS_READ = "connectors.read",
    CONNECTORS_UPDATE = "connectors.update",
    CONNECTORS_DELETE = "connectors.delete",
    PAYMENTS_CREATE = "payments.create",
    PAYMENTS_READ = "payments.read",
    PAYMENTS_UPDATE = "payments.update",
    PAYMENTS_DELETE = "payments.delete",
    QRCODES_CREATE = "qrcodes.create",
    QRCODES_READ = "qrcodes.read",
    QRCODES_UPDATE = "qrcodes.update",
    QRCODES_DELETE = "qrcodes.delete",
    TABLES_CREATE = "tables.create",
    TABLES_READ = "tables.read",
    TABLES_UPDATE = "tables.update",
    TABLES_DELETE = "tables.delete",
    TABLES_DISABLE = "tables.disable",
    TABLELINKS_CREATE = "tablelinks.create",
    TABLELINKS_READ = "tablelinks.read",
    TABLELINKS_UPDATE = "tablelinks.update",
    TABLELINKS_DELETE = "tablelinks.delete",
    PRINTERS_CREATE = "printers.create",
    PRINTERS_READ = "printers.read",
    PRINTERS_UPDATE = "printers.update",
    PRINTERS_DELETE = "printers.delete",
    DELIVERY_CREATE = "delivery.create",
    DELIVERY_READ = "delivery.read",
    DELIVERY_UPDATE = "delivery.update",
    DELIVERY_DELETE = "delivery.delete",
    STATS_CREATE = "stats.create",
    STATS_READ = "stats.read",
    STATS_UPDATE = "stats.update",
    STATS_DELETE = "stats.delete",
    SUGGESTIONS_CREATE = "suggestions.create",
    SUGGESTIONS_READ = "suggestions.read",
    SUGGESTIONS_UPDATE = "suggestions.update",
    SUGGESTIONS_DELETE = "suggestions.delete",
    THEME_CREATE = "theme.create",
    THEME_READ = "theme.read",
    THEME_UPDATE = "theme.update",
    THEME_DELETE = "theme.delete",
    TRANSLATIONS_CREATE = "translations.create",
    TRANSLATIONS_READ = "translations.read",
    TRANSLATIONS_UPDATE = "translations.update",
    TRANSLATIONS_DELETE = "translations.delete",
    TAXES_CREATE = "taxes.create",
    TAXES_READ = "taxes.read",
    TAXES_UPDATE = "taxes.update",
    TAXES_DELETE = "taxes.delete",
    LOYALTIES_CREATE = "loyalties.create",
    LOYALTIES_READ = "loyalties.read",
    LOYALTIES_UPDATE = "loyalties.update",
    LOYALTIES_DELETE = "loyalties.delete",
    NOTIFICATIONS_CREATE = "notifications.create",
    NOTIFICATIONS_READ = "notifications.read",
    NOTIFICATIONS_UPDATE = "notifications.update",
    NOTIFICATIONS_DELETE = "notifications.delete",
    INVOICES_CREATE = "invoices.create",
    INVOICES_READ = "invoices.read",
    INVOICES_UPDATE = "invoices.update",
    INVOICES_DELETE = "invoices.delete",
    INVENTORIES_CREATE = "inventories.create",
    INVENTORIES_READ = "inventories.read",
    INVENTORIES_UPDATE = "inventories.update",
    INVENTORIES_DELETE = "inventories.delete",
    PIN_CREATE = "pin.create",
    PIN_UPDATE = "pin.update",
    PIN_DELETE = "pin.delete",
    PIN_READ = "pin.read",
    REFUND = "refund",
    CHALLENGES_LIST = "challenges.list",
    CHALLENGES_READ = "challenges.read",
    CHALLENGES_ACCEPT = "challenges.accept",
    TRENDS_LIST = "trends.list",
    TRENDS_READ = "trends.read",
}
export default Permissions;