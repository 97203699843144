import { Box, useMediaQuery, useTheme } from '@mui/material';
import log from 'loglevel';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DisplayHeader from '../../Common/components/DisplayHeader';
import { AddButtonRedirect } from '../../Common/models/AddButtonRedirect';
import * as ROUTES from '../../config/routes';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import OrderActions from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import DealDisplay from '../components/DealDisplay';
import DealActions from '../redux/actions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

type Props = { desktop?: boolean }
/**
 * Front page of a deal type Menu
 * @param props 
 * 
 */
const Menu = (props: Props) => {

    const query = useQuery()
    const deal_ref = query.get("item");
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory()

    const { order } = useTypedSelector((state: RootState) => state.order)
    const { selectedLocation, selectedCatalog, tableLinkId, selectedTable } = useTypedSelector(state => state.locations)
    const { listItems, dealRef, dealPrice } = useTypedSelector(state => state.deals)
    const authenticationState = useTypedSelector((state: RootState) => state.authentication);

    const { tableLinkKey } = useParams<ROUTES.RouteParams>();

    const smView = useMediaQuery(theme.breakpoints.up("sm"))

    const [display, setDisplay] = useState<boolean>(false)
    const [isInteractive, setIsInteractive] = useState<boolean>(false)

    let selectedDeal: DealExt | undefined = useMemo(() => {
        if (selectedCatalog && selectedCatalog.data) {
            return selectedCatalog.data.deals.filter(deal => deal.ref === deal_ref)[0]
        }
    }, [deal_ref, selectedCatalog]);

    useEffect(() => {
        if (selectedTable.service_type && selectedTable.service_type !== SupportedServiceType.VIEW) {
            setIsInteractive(true)
        }
    }, [selectedTable.service_type])


    /**
     * Function in charge to reset deal State after adding the deal to order
     */
    const submitDealAndReset = (redirect: AddButtonRedirect) => {

        const userId = authenticationState.data.user_authentication_state.user?.uid;

        if (!selectedCatalog || !selectedLocation || !userId) {
            log.error("Missing data to add deal to order", { selectedCatalog, selectedLocation, userId });
            return;
        }

        dispatch(OrderActions.addDeal(dealRef, listItems, selectedCatalog, selectedLocation, selectedTable, userId,));
        dispatch(DealActions.resetDealListItems());

        switch (redirect) {

            case AddButtonRedirect.SUMMARY_PAGE:
                history.push(ROUTES.getOrderSummaryFullRoute(tableLinkKey));
                break;

            default:
                history.push(ROUTES.getCategoriesFullRoute(tableLinkKey));
                break;
        }
    }
    /**
     * useEffect use to clean dealState if the dealSelected change and init the new deal Selected
     */
    useEffect(() => {
        if (deal_ref && deal_ref !== dealRef) {
            dispatch(DealActions.resetDealListItems())
        }
        dispatch(DealActions.initDeal(selectedDeal, selectedCatalog!, selectedLocation!, order, selectedTable));
    }, [deal_ref, dispatch, dealRef])
    //useEffect use to control if the deal selection is Complete (one item by sku)
    useEffect(() => {
        if (listItems && selectedDeal && selectedDeal.lines && listItems.length === selectedDeal.lines.length) {
            setDisplay(true)
            log.debug(`Deal is fully defined, display button (deal price: ${dealPrice})`)
        } else {
            log.debug(`Deal requires ${selectedDeal?.lines.length}, only ${listItems.length} defined`, listItems)
        }

    }, [listItems, listItems.length, selectedDeal])

    if (selectedDeal) {

        return (

            <Box
                p={0}
                height="100%"
                overflow={smView ? "hidden" : "scroll"}
                ml={smView ? 2 : 0}
                style={
                    smView
                        ? { backgroundColor: theme.palette.background.default }
                        : { backgroundColor: theme.palette.background.paper }
                }
            >
                {!smView &&
                    <DisplayHeader
                        selectedProductOrDeal={selectedDeal}
                        routeToGoBack={ROUTES.getCategoriesFullRoute(tableLinkId)}
                        backToTitle={selectedLocation?.name}
                    />
                }
                <DealDisplay
                    deal={selectedDeal}
                    interactif={isInteractive}
                    onSubmit={submitDealAndReset}
                    displayOrderButton={display}
                />
            </Box>
        )
    }
    return null
}

export default Menu;
