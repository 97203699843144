import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StarIcon from '@mui/icons-material/Star';
import { Alert, AlertTitle, Box, Button, TextField, Typography, useTheme } from '@mui/material';
import 'firebase/analytics';
import firebase from 'firebase/app';
import log from 'loglevel';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { DeviceContext } from '../../App';
import useShowAlcoholDisclaimer from '../../Common/hooks/useShowAlcoholDisclaimer';
import DefaultImage from '../../assets/aperitif.png';
import * as ROUTES from '../../config/routes';
import { EXTRA_SMALL_HEIGHT_TABLET } from '../../config/useDeviceContext';
import { OptionList, Sku, Tags } from '../../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderItem, OrderOption } from '../../my-lemonade-library/model/Order';
import { ProductExtended, SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import AnalyticsAddToCartEvent from '../../my-lemonade-library/src/analytics/models/AnalyticsAddToCartEvent';
import analyticsHelper from '../../my-lemonade-library/src/analytics/services/AnalyticsHelper';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import OrderAction from '../../orders/redux/OrderActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import tagService from '../../tags/services/tagService';
import ProductModal from '../pages/ProductModal';
import productService from '../services/ProductService';
import AddProductButton from './AddProductButton';
import AlcoholDisclaimerModal from './AlcoholDisclaimerModal';
import ProductOptions from './ProductOptions';

export interface ProductDetailPageProps { }

// ONLY ON DESKTOP & FOR PRODUCTS
const ProductDetailPage: React.FC<ProductDetailPageProps> = (props) => {

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();

    const { selectedCatalog, selectedLocation, selectedTable } = useTypedSelector(state => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);

    const { tablet_portrait, mobile_device, desktop_device } = useContext(DeviceContext)

    const match = useRouteMatch<ROUTES.RouteParams>();

    const { productRef, tableLinkKey, categoryRef } = match.params;

    const [selectedProduct, setSelectedProduct] = useState<ProductExtended | null>(null);
    const [orderItem, setOrderItem] = useState<OrderItem | null>(null);
    const [optionsModalOpened, setOptionsModalOpened] = useState<boolean>(false);
    const [itemNote, setItemNote] = useState<string>("")

    // If true, the skus/options modal will open and quantity buttons won't show
    const [shouldOpenModal, setShouldOpenModal] = useState<boolean>(true)
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [openAlcoholModal, setOpenAlcoholModal] = useState<boolean>(false)
    const [selectedSku, setSelectedSku] = useState<SkuExtended>({} as Sku);
    const [selectedOptions, setSelectedOptions] = useState<OrderOption[]>([])
    const [hasOptionError, setHasOptionError] = useState<OptionList[] | null>(null)
    const [showOptionError, setShowOptionError] = useState<boolean>(false)
    const displayProductNameFromProductOptionsComponent: boolean = false;

    const selectedCategory = useMemo(() => {
        const categoriesMap = selectedCatalog?.data?.categoriesMap

        return categoriesMap?.[categoryRef] ?? null
    }, [categoryRef, selectedCatalog?.data?.categoriesMap])

    const shouldDisplayAlcoholModal = useShowAlcoholDisclaimer(selectedProduct)

    useEffect(() => {
        if (selectedProduct) {
            setOrderItem({
                product_ref: selectedProduct.ref,
                sku_ref: selectedProduct.skus[0].ref,
                options: [],
                product_name: selectedProduct.name,
                quantity: 1,
                price: selectedProduct.skus[0].price
            })
        }
    }, [selectedProduct])

    useEffect(() => {
        if (productRef && selectedCatalog) {
            const selectedProduct = selectedCatalog.data.products.find(product => product.ref === productRef)
            if (selectedProduct) {
                setSelectedProduct(selectedProduct)
            } else {
                history.push(ROUTES.getCategoriesFullRoute(tableLinkKey))
            }
        }
    }, [productRef, selectedCatalog, history, tableLinkKey])

    useEffect(() => {
        if (!tablet_portrait || (selectedProduct && selectedProduct.skus.length < 2 && !selectedProduct.skus[0].option_list_refs)) {
            setShouldOpenModal(false);
        } else {
            setShouldOpenModal(true);
        }
    }, [tablet_portrait, selectedProduct]);

    useEffect(() => {
        if (!selectedProduct && !shouldOpenModal) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    }, [selectedProduct, shouldOpenModal])

    const imageToRender = (product: ProductExtended, categoryIcon?: string) => {
        if (product.image) {
            return product.image
        } else {
            if (categoryIcon) {
                return categoryIcon
            } else {
                return DefaultImage
            }
        }
    }

    const addQuantity = () => {
        if (orderItem) {
            setOrderItem({
                ...orderItem,
                quantity: productService.addOneQuantity(orderItem.quantity)
            })
        }
    }
    const removeQuantity = () => {
        if (orderItem) {
            setOrderItem({
                ...orderItem,
                quantity: productService.removeOneQuantity(orderItem.quantity)
            })
        }
    }

    const handleAddProductClick = () => {
        if (shouldDisplayAlcoholModal) {
            setOpenAlcoholModal(true)
        } else {
            addProduct()
        }
    }

    const handleSelectedOptionsChange = (selectedOptions: OrderOption[], invalidOptionLists: OptionList[] | null) => {
        if (invalidOptionLists) {
            log.debug(`${invalidOptionLists?.length} invalid options`, invalidOptionLists);
        }
        setHasOptionError(invalidOptionLists);
        setSelectedOptions(selectedOptions);
    }

    const addProduct = () => {
        if (hasOptionError && hasOptionError.length > 0) {
            setShowOptionError(true);
        } else {

            if (
                selectedProduct
                && selectedCatalog
                && orderItem
                && selectedLocation
                && !shouldOpenModal
            ) {
                let catalog = selectedCatalog
                if (catalog) {
                    const addToCartEvent: AnalyticsAddToCartEvent = analyticsHelper.getAddToCartEvenForOneItem(
                        catalog.account_id ? catalog.account_id : "",
                        catalog.location_id,
                        catalog.currency,
                        selectedProduct,
                        selectedProduct.skus[0].ref,
                        productService.getProductPriceWithOptions(selectedCatalog, selectedProduct, selectedProduct.skus[0], [], orderItem.quantity),
                        orderItem.quantity
                    );
                    firebase.analytics().logEvent(firebase.analytics.EventName.ADD_TO_CART, addToCartEvent);
                }
                dispatch((OrderAction.addItem(
                    selectedProduct.ref,
                    selectedProduct.skus[0].ref,
                    selectedOptions,
                    catalog!,
                    selectedLocation,
                    selectedTable,
                    selectedProduct.name,
                    orderItem.quantity,
                    selectedProduct.skus[0].price,
                    itemNote ?? "",
                    data.user_authentication_state.user?.uid ? data.user_authentication_state.user.uid : undefined,
                )))
                history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
            }
            setOptionsModalOpened(true)
        }
    }

    const closeOptionsModal = () => {

        setOptionsModalOpened(false);
        setItemNote("");
    }

    if (selectedProduct && orderItem && selectedCatalog) {

        return (
            <>
                <ProductModal
                    open={optionsModalOpened}
                    closeModal={closeOptionsModal}
                    selectedProduct={selectedProduct}
                    hideQuantityButton={false}
                    quantityPreSelected={orderItem.quantity}
                    note={itemNote}
                    setNote={setItemNote}
                />

                <AlcoholDisclaimerModal
                    open={openAlcoholModal}
                    onClose={() => setOpenAlcoholModal(false)}
                    onAccept={() => {
                        setOpenAlcoholModal(false)
                        addProduct()
                    }}
                />

                {/**
                 * Hide image if inner height is to small
                 */}
                <Box
                    p={mobile_device || tablet_portrait ? theme.spacing(0, 1, 0, 1) : theme.spacing(0, 0, 2, 0)}
                    display="flex"
                    flexDirection="column"
                    width={!tablet_portrait ? "100%" : "95%"}
                    style={{
                        margin: "0 auto",
                        backgroundColor: !tablet_portrait ? theme.palette.background.paper : "",
                        borderTopLeftRadius: !tablet_portrait ? "10px" : 0,
                        borderTopRightRadius: !tablet_portrait ? "10px" : 0
                    }}
                    height="100%"
                    overflow="scroll"
                >
                    {window.innerHeight > EXTRA_SMALL_HEIGHT_TABLET && selectedProduct.image &&
                        <Box
                            pb={!tablet_portrait ? 0 : 3}
                            width="100%"
                            minHeight={!tablet_portrait ? "40%" : "30%"}
                            style={{
                                background: `url(${imageToRender(selectedProduct, selectedCategory?.icon)})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "10px"
                            }}
                        />
                    }

                    <Box
                        pt={!tablet_portrait ? 0 : 2}
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent={!tablet_portrait ? "center" : undefined}
                        alignItems={!tablet_portrait ? "center" : "flex-start"}
                        flex={1}
                    >

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => history.goBack()}
                            startIcon={
                                <ArrowBackIosIcon
                                    sx={{ color: theme.palette.secondary.main }}
                                />
                            }
                            style={{
                                position: !tablet_portrait ? "absolute" : "static",
                                backgroundColor: !tablet_portrait ? "white" : "transparent",
                                top: !tablet_portrait ? "50px" : "auto",
                                left: !tablet_portrait ? "400px" : "auto",
                                zIndex: !tablet_portrait ? 1 : "auto",
                            }}
                        >
                            <FormattedMessage id="product_detail.back.button" />
                        </Button>

                        <Box
                            pt={3}
                            pb={3}
                            display="flex"
                            alignItems="center"
                            width="90%"
                            mt={!selectedProduct.image ? "50px" : "0"}>
                            <Typography variant="h2" color="textPrimary" style={{ paddingRight: theme.spacing(2) }}>
                                <FormattedMessage id={translationService.getProductNameTranslationKey(selectedProduct)} />
                            </Typography>
                            {selectedProduct.best_seller ? (
                                <StarIcon color="primary" />
                            ) : ""}
                        </Box>

                        {selectedProduct.description &&

                            <Box pb={2} width="90%">
                                <Typography variant="subtitle1" color="textSecondary" style={{ paddingBottom: theme.spacing(2) }}>
                                    <FormattedMessage id="product_detail.description.title" />
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    <FormattedMessage id={translationService.getProductDescriptionTranslationKey(selectedProduct)} />
                                </Typography>
                            </Box>
                        }

                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            {selectedProduct.tags?.map((tag: Tags, index: number) =>
                                <Box key={index} >
                                    {tagService.renderTag(tag, theme.palette.secondary.main, true)}
                                </Box>
                            )}
                        </Box>

                        {
                            !tablet_portrait &&
                            <Box
                                display='flex'
                                flexDirection='column'
                                width={1}
                            >
                                {(showOptionError && hasOptionError && hasOptionError.length) &&

                                    <Alert severity="error">

                                        <AlertTitle>
                                            <Typography variant="h5" color="error">
                                                {intl.formatMessage({ id: "options.hasError.title" })}
                                            </Typography>
                                        </AlertTitle>

                                        <Typography variant="h5" color="error">
                                            {intl.formatMessage({ id: "options.hasError.message" })}
                                        </Typography>

                                    </Alert>
                                }


                                <ProductOptions
                                    selectedProduct={selectedProduct ?? []}
                                    selectedSku={selectedSku}
                                    setSkuSelected={setSelectedSku}
                                    selectedOptions={selectedOptions}
                                    onSelectedOptionsChange={handleSelectedOptionsChange}
                                    optionError={hasOptionError}
                                    displayProductName={displayProductNameFromProductOptionsComponent}
                                />


                            </Box>
                        }

                        {
                            selectedProduct.allow_customer_notes
                                && selectedTable.service_type !== SupportedServiceType.VIEW ? (

                                <Box
                                    width={(!mobile_device && !tablet_portrait) ? "70%" : "100%"}
                                    px={mobile_device ? 0 : 0}
                                    mt={4}
                                    alignSelf={!tablet_portrait && !mobile_device ? "center" : "flex-start"}
                                    mb={4}
                                >
                                    <TextField
                                        placeholder={intl.formatMessage({ id: 'Order.cart.customer_notes.dialog.placeholder' })}
                                        fullWidth={true}
                                        multiline
                                        InputProps={{ disableUnderline: true }}
                                        value={itemNote}
                                        onChange={e => setItemNote(e.target.value)}
                                        style={{ width: '100%', border: 'none' }}
                                    />
                                </Box>

                            ) : ""
                        }
                        <Box mt="auto" alignSelf="center" width='300px'>
                            <AddProductButton
                                hideQuantityButton={shouldOpenModal}
                                onAddProduct={handleAddProductClick}
                                price={productService.getProductPriceWithOptions(selectedCatalog, selectedProduct, selectedProduct.skus[0], [], orderItem.quantity)}
                                onAdd={addQuantity}
                                onRemove={removeQuantity}
                                quantity={orderItem.quantity}
                                isButtonDisabled={isButtonDisabled}
                                product={selectedProduct}
                                addToDeal={false}  // Not handling deals in this component
                                displayFastCheckoutButton={!desktop_device}
                            />
                        </Box>
                    </Box>

                </Box>


            </>
        )
    } else {
        return null
    }
}

export default ProductDetailPage;
