import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../Common/helper/TextHelper';
import { Option, OptionLine } from '../../my-lemonade-library/model/Catalog';
import { CatalogEntityType } from '../../my-lemonade-library/src/catalogs/models/CatalogEntity';
import { OptionLineExtBO } from '../../my-lemonade-library/src/catalogs/models/OptionLineExtBO';
import { OptionListExtBO } from '../../my-lemonade-library/src/catalogs/models/OptionListExtBO';
import { moneyToNumber, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RADIO_SIZE } from '../configs/styleConfig';

interface ProductOptionsRadioListProps {
    option_list: OptionListExtBO
    selected?: Option
    onChange: (value: { option: Option, option_line: OptionLine, option_list: OptionListExtBO }) => void;
    readonly: boolean;
}

const ProductOptionsRadioList = (props: ProductOptionsRadioListProps) => {
    const { option_list, selected, onChange } = props;

    const theme = useTheme();
    const intl = useIntl();

    const renderItem = useCallback((option_line: OptionLineExtBO) => {

        const { option_ref, option, option: { disable } } = option_line;

        const value = `${option_list.ref}-${option_ref}`;

        let label = capitalizeFirstLetter(
            intl.formatMessage({
                id: translationService.getOptionNameTranslationKey(option),
                defaultMessage: option.name,
            })
        )

        if (disable) {
            label += ` (${intl.formatMessage({ id: 'product.disable' })})`
        }

        return (

            <Box key={value}>
                <Divider />
                <Box
                    display='flex'
                    width={1}
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    data-test={`option_${option_ref}`}
                    my={1}
                    onClick={() => {
                        if (disable) return

                        onChange({
                            option,
                            option_line,
                            option_list
                        })
                    }}
                >
                    <FormControlLabel
                        value={value}
                        disabled={disable}
                        style={{
                            display: "flex",
                            flex: 1
                        }}
                        checked={selected?.ref === option_ref}
                        control={
                            !props.readonly ?
                                <Radio
                                    data-test={`controller_option_${option_ref}`}
                                    icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                    checkedIcon={<CheckCircleIcon />}
                                    color='primary'
                                /> :
                                <Box data-test={`box_option_${option_ref}`} width={RADIO_SIZE} height={RADIO_SIZE} />
                        }
                        label={label}
                    />

                    <Box display='flex' flexDirection='row'>
                        {
                            option_line.price && moneyToNumber(option_line.price) !== 0 &&

                            <Typography
                                variant="body1"
                                style={{ color: theme.palette.text.disabled }}
                            >
                                {MoneyToStringWithSymbol(option_line.price)}
                            </Typography>
                        }

                    </Box>
                </Box>
            </Box>
        );
    }, [intl, onChange, option_list, props.readonly, selected?.ref, theme.palette.text.disabled])

    return (
        <RadioGroup
            style={{ width: '100%' }}
            aria-label={CatalogEntityType.OPTION}
            name={CatalogEntityType.OPTION}
            value={`${option_list.ref}-${selected?.ref}`}
        >
            {option_list?.option_lines.map(renderItem)}
        </RadioGroup >
    )
}

export default ProductOptionsRadioList;