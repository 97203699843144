import Fuse from 'fuse.js';
import _ from "lodash";
import { DateTime } from 'luxon';
import { BaseCatalogEntity, Catalog, CatalogData, Categorie, CategoriesAndProducts, Deal, DealLine, DEFAULT_LANGUAGE, Option, OptionLine, OptionList, PricingEffect, Product, Sku } from "../../../model/Catalog";
import { CatalogExtended } from "../../../model/catalogExtended/CatalogExtended";
import DealExt from "../../../model/DealExtended/DealExt";
import { Location } from "../../../model/Location";
import { Order, OrderItem } from "../../../model/Order";
import { ProductExtended, SkuExtended } from "../../../model/ProductExtended/ProductExtended";
import { DataFyreBrandProduct } from '../../brands/models/DataFyreBrandProduct';
import defaultCategoryTemplates from '../../categories/configs/DefaultCategoryTemplates';
import CategoryTemplate from '../../categories/models/CategoryTemplate';
import { FIRESTORE_BACKUPS_COLLECTION } from "../../common/configs/CommonFirestoreConfig";
import { DEFAULT_CURRENCY, Money, moneyToNumberCheck, numberToMoney, updateCurrencyOfMoney } from "../../common/models/Money";
import { extractDateFromTimestampOrString } from "../../common/services/DateHelper";
import { log } from "../../common/services/LogService";
import { getStoragePublicUrl } from "../../common/services/StorageHelper";
import { CatalogMergeIgnoredEntities, IgnoredField, IgnoreOptionFieldsConfig, IgnoreOptionListFieldsConfig, IgnoreProductFieldsConfig, IgnoreTaxFieldsConfig } from "../../connectors/models/IgnoredField";
import { computeStartingPriceAndMaxPrice } from '../../deals/services/DealHelper';
import CatalogItemDisabled from '../../inventories/models/CatalogItemDisabled';
import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import { atLeastOneSkuEnabledInProduct } from "../../products/helpers/ProductHelpers";
import productService from "../../products/services/ProductService";
import { ReferentialCategories } from '../../referential/models/ReferentialCategories';
import { checkInvalidRestrictions, getMergedRestrictions, getRestrictionsArray, isEntityAvailableForTableArea } from "../../restrictions/services/RestrictionsService";
import { getAllLocationTableAreasRefs } from "../../tables/helpers/TableHelpers";
import { Tax } from "../../taxes/models/Tax";
import { FIRESTORE_CATALOGS_COLLECTION } from "../configs/CatalogFirestoreConfig";
import CatalogAnomaly from "../models/anomalies/CatalogAnomaly";
import CatalogAnomalyType from "../models/anomalies/CatalogAnomalyType";
import CheckedCatalog from "../models/anomalies/CheckedCatalog";
import DuplicateEntity from "../models/anomalies/DuplicateEntity";
import InvalidPercentage, { PercentageProperty } from "../models/anomalies/InvalidPercentage";
import InvalidPrice, { PriceProperty } from "../models/anomalies/InvalidPrice";
import InvalidReference from "../models/anomalies/InvalidReference";
import InvalidTableAreaRef, { CatalogEntityWithTableAreaRestriction } from "../models/anomalies/InvalidTableAreaRef";
import MinimumOneRefInArray from "../models/anomalies/MinimumOneRefInArray";
import MissingEntityRef from "../models/anomalies/MissingEntityRef";
import CatalogDiffResult from "../models/CatalogDiffResult";
import { CatalogEntity, CatalogEntityType, CatalogEntityWithDisable } from "../models/CatalogEntity";
import CatalogEntityDiffResult from "../models/CatalogEntityDiffResult";
import CatalogErrors from "../models/CatalogErrors";
import { CatalogExtBO } from "../models/CatalogExtBO";
import CatalogLink from "../models/CatalogLink";
import { fixCatalogAnomaly } from "./CatalogAnomaliesService";

export const CHIDL_CATALOG_ID_PREFIX_LENGTH = 6;
export const CHIDL_CATALOG_ID_PREFIX_SEPARATOR = "|";
const FUSE_THRESHOLD = 0;

/**
 * Higher threshold, it's ok not to match the right category template
 */
const FUSE_THRESHOLD_CATEGORY = 0.4;
const FUSE_MIN_MATCH_CHAR_CATEGORY = 2;

/**
 * Path in bucket
 * @param gcpProjectId
 * @param accountId 
 * @param locationId 
 * @param catalogId 
 * @param catalogEntityType 
 * @param catalogEntityRef 
 * @param extension 
 * @returns 
 */
export const getCatalogEntityStoragePath = (gcpProjectId: string, accountId: string, locationId: string, catalogId: string, catalogEntityType: CatalogEntityType, catalogEntityRef: string, extension: string): string => {
    const storageRelativePath = getCatalogEntityStorageRelativePath(accountId, locationId, catalogId, catalogEntityType, catalogEntityRef, extension);
    return getStoragePublicUrl(gcpProjectId, storageRelativePath)
}

/**
 * Get the storage path for backup
 */
export const getCatalogBackupStoragePath = (accountId: string, locationId: string, catalogId: string, suffixWithExtension: string): string => {
    return `${getCatalogFirestoreDocPath(accountId, locationId, catalogId)}${suffixWithExtension}`;
}

export const getCatalogEntityStorageRelativePath = (accountId: string, locationId: string, catalogId: string, catalogEntityType: CatalogEntityType, catalogEntityRef: string, extension: string): string => {
    // Use ac, lo, ca to shorten url
    const storageRelativePath = `ac/${accountId}/lo/${locationId}/ca/${catalogId}/${catalogEntityType}/${catalogEntityRef}.${extension}`;
    return storageRelativePath;
}

export const getCatalogsFirestoreCollectionPath = (accountId: string, locationId: string): string => {
    return `${getLocationFirestoreDocPath(accountId, locationId)}/${FIRESTORE_CATALOGS_COLLECTION}`;
}

export const getCatalogFirestoreDocPath = (accountId: string, locationId: string, catalogId: string): string => {
    return `${getCatalogsFirestoreCollectionPath(accountId, locationId)}/${catalogId}`;
}

export const getCatalogBackupsFirestoreCollectionPath = (accountId: string, locationId: string, catalogId: string): string => {
    return `${getCatalogFirestoreDocPath(accountId, locationId, catalogId)}/${FIRESTORE_BACKUPS_COLLECTION}`;
}

export const getCatalogBackupFirestoreDocPath = (accountId: string, locationId: string, catalogId: string, backupId: string): string => {
    return `${getCatalogBackupsFirestoreCollectionPath(accountId, locationId, catalogId)}/${backupId}`;
}

/**
 * Get the key identifying the category name translation
 * @param product
 */
export const getCategorytNameTranslationKey = (
    category: Categorie | undefined
): string | undefined => {
    if (category) {
        return getCategoryNameTranslationKeyFromRef(category.ref);
    }
    return;
}

export const getCategoryNameTranslationKeyFromRef = (ref: string): string | undefined => {
    if (ref) {
        return `category_name_${ref}`;
    }
    return;
}

export const isAssociatedWithChildCatalog = (catalogEntity: BaseCatalogEntity, childLocationId: string, childCatalogId: string): boolean => {
    return (catalogEntity.child_location ?
        (catalogEntity.child_location.location_id === childLocationId &&
            catalogEntity.child_location.catalog_id === childCatalogId) : false);
}

export const getParentCatalogRef = (childCatalogId: string, childCatalogEntityRef: string): string => {
    if (childCatalogEntityRef) {
        const childCatalogPrefix = childCatalogId.slice(0, Math.min(CHIDL_CATALOG_ID_PREFIX_LENGTH, childCatalogId.length));
        // Be robust to multiple calls
        if (!childCatalogEntityRef.startsWith(childCatalogPrefix)) {
            return `${childCatalogPrefix}${CHIDL_CATALOG_ID_PREFIX_SEPARATOR}${childCatalogEntityRef}`;
        }
    }
    return childCatalogEntityRef;
}

export const getChildCatalogRef = (parentCatalogEntityRef: string, childCatalogId?: string): string => {
    const separatorIndex = parentCatalogEntityRef.indexOf(CHIDL_CATALOG_ID_PREFIX_SEPARATOR);
    if (separatorIndex > -1) {
        const prefix = parentCatalogEntityRef.substring(0, separatorIndex);
        const childCatalogRef = parentCatalogEntityRef.substring(separatorIndex + 1);
        if (!childCatalogId || childCatalogId.startsWith(prefix)) {
            return childCatalogRef;
        } else {
            log.error(`Ref ${parentCatalogEntityRef} does not seem to be a ref of the child catalog ${childCatalogId}`);
        }
    } else {
        log.error(`Ref ${parentCatalogEntityRef} does not seem to be a child catalog ref: missing separator`);
    }
    return parentCatalogEntityRef;
}


export const updateChildCategoryRefsToParentRefs = (category: Categorie) => {
    if (!category.child_location || !category.child_location.catalog_id) {
        throw new Error("The category does not have a child location, impossible to update refs")
    }
    const childCatalogId = category.child_location.catalog_id;
    category.ref = getParentCatalogRef(childCatalogId, category.ref)
}

export const updateChildProductRefsToParentRefs = (product: Product) => {
    if (!product.child_location || !product.child_location.catalog_id) {
        throw new Error("The product does not have a child location, impossible to update refs")
    }
    const childCatalogId = product.child_location.catalog_id;
    product.ref = getParentCatalogRef(childCatalogId, product.ref)
    product.category_ref = getParentCatalogRef(childCatalogId, product.category_ref)
    // Change sku ref too
    product.skus?.forEach((sku) => {
        sku.ref = getParentCatalogRef(childCatalogId, sku.ref);
        sku.option_list_refs = sku.option_list_refs?.map((optionListRef) => getParentCatalogRef(childCatalogId, optionListRef));
    })
}

export const updateChildDealRefsToParentRefs = (clonedEntity: Deal) => {
    if (!clonedEntity.child_location || !clonedEntity.child_location.catalog_id) {
        throw new Error("The deal does not have a child location, impossible to update refs")
    }
    const childCatalogId = clonedEntity.child_location.catalog_id;
    clonedEntity.ref = getParentCatalogRef(childCatalogId, clonedEntity.ref);
    if (clonedEntity.category_ref) {
        clonedEntity.category_ref = getParentCatalogRef(childCatalogId, clonedEntity.category_ref);
    }
    clonedEntity.lines?.forEach((dealLine) => {
        dealLine.ref = getParentCatalogRef(childCatalogId, dealLine.ref);
        dealLine.skus?.forEach((dealLineSku) => {
            dealLineSku.ref = getParentCatalogRef(childCatalogId, dealLineSku.ref);
        })
    })
}

export const updateChildOptionListRefsToParentRefs = (clonedEntity: OptionList) => {
    if (!clonedEntity.child_location || !clonedEntity.child_location.catalog_id) {
        throw new Error("The option list does not have a child location, impossible to update refs")
    }
    const childCatalogId = clonedEntity.child_location.catalog_id;
    clonedEntity.ref = getParentCatalogRef(childCatalogId, clonedEntity.ref)
    clonedEntity.option_lines?.forEach((optionLine) => {
        optionLine.option_ref = getParentCatalogRef(childCatalogId, optionLine.option_ref);
    })
}

export const updateChildOptionRefsToParentRefs = (clonedEntity: Option) => {
    if (!clonedEntity.child_location || !clonedEntity.child_location.catalog_id) {
        throw new Error("The option does not have a child location, impossible to update refs")
    }
    const childCatalogId = clonedEntity.child_location.catalog_id;
    clonedEntity.ref = getParentCatalogRef(childCatalogId, clonedEntity.ref)
}

/**
 * Merge the child catalog into the parent catalog
 * @param catalog 
 * @returns 
 */
export const mergeChildCatalog = (parentCatalog: Catalog, childCatalog: Catalog, childLocationName?: string): Catalog => {
    const childCatalogId = childCatalog.id ? childCatalog.id : "";
    const childLocationId = childCatalog.location_id;
    const rootParentCategory = parentCatalog.data.categories.find((category) =>
        !category.parent_ref && isAssociatedWithChildCatalog(category, childLocationId, childCatalogId));
    if (!rootParentCategory) {
        throw CatalogErrors.CHILD_ROOT_CATEGORY_NOT_FOUND;
    }
    const childLocationConfig: CatalogLink = rootParentCategory.child_location!;
    if (!childLocationConfig.location_name && childLocationName) {
        childLocationConfig.location_name = childLocationName
    }

    // Keep data not associated with child catalog (except root category)
    // TODO: what about suggestion, discount, etc ?
    const excludedCategoryRefs = parentCatalog.data.categories.filter((entity) => entity.parent_ref === rootParentCategory.ref).map((entity) => entity.ref);
    parentCatalog.data.categories = parentCatalog.data.categories.filter((entity) => !entity.parent_ref || entity.parent_ref !== rootParentCategory.ref);

    parentCatalog.data.products = parentCatalog.data.products.filter((entity) => entity.category_ref !== rootParentCategory.ref && !excludedCategoryRefs.includes(entity.category_ref));

    // TODO: search for option & deals only used/using excluded products
    parentCatalog.data.deals = parentCatalog.data.deals.filter((entity) => !isAssociatedWithChildCatalog(entity, childLocationId, childCatalogId));
    parentCatalog.data.option_lists = parentCatalog.data.option_lists.filter((entity) => !isAssociatedWithChildCatalog(entity, childLocationId, childCatalogId));

    // TODO: keep image, name if already defined in parent catalog ?

    // Add child catalog categories, products, deals, options list by cloning and adding a ref
    // We keep order as defined by sublocation
    const excludedSubCategories: { [key: string]: Categorie } = {}
    childCatalog.data.categories.forEach((category) => {
        //Attach it to the root category
        if (!category.parent_ref) {
            const clonedEntity = _.cloneDeep(category);
            clonedEntity.parent_ref = rootParentCategory.ref;
            clonedEntity.child_location = _.cloneDeep(childLocationConfig);
            updateChildCategoryRefsToParentRefs(clonedEntity);
            parentCatalog.data.categories.push(clonedEntity);
        } else {
            // Sub categories are excluded for now
            excludedSubCategories[category.ref] = category;
        }
    })

    childCatalog.data.products.forEach((entity) => {
        const clonedEntity = _.cloneDeep(entity);

        clonedEntity.child_location = _.cloneDeep(childLocationConfig);
        // If subcategory
        const foundExcludedSubCategory = excludedSubCategories[clonedEntity.category_ref];
        if (foundExcludedSubCategory) {
            clonedEntity.category_ref = foundExcludedSubCategory.parent_ref!;
        }
        updateChildProductRefsToParentRefs(clonedEntity);

        parentCatalog.data.products.push(clonedEntity);
    })

    childCatalog.data.deals.forEach((entity) => {
        const clonedEntity = _.cloneDeep(entity);
        clonedEntity.child_location = _.cloneDeep(childLocationConfig);
        updateChildDealRefsToParentRefs(clonedEntity);

        parentCatalog.data.deals.push(clonedEntity);
    })

    childCatalog.data.option_lists.forEach((entity) => {
        const clonedEntity = _.cloneDeep(entity);
        clonedEntity.child_location = _.cloneDeep(childLocationConfig);
        updateChildOptionListRefsToParentRefs(clonedEntity);

        parentCatalog.data.option_lists.push(clonedEntity);
    });

    childCatalog.data.options.forEach((entity) => {
        const clonedEntity = _.cloneDeep(entity);
        clonedEntity.child_location = _.cloneDeep(childLocationConfig);
        updateChildOptionRefsToParentRefs(clonedEntity);

        parentCatalog.data.options.push(clonedEntity);
    });

    return parentCatalog;
}

export const deleteChildLocationAssociation = (catalog: Catalog, childLocationId: string, childCatalogId: string) => {
    catalog.data.categories.forEach((category) => {
        if (isAssociatedWithChildCatalog(category, childLocationId, childCatalogId)) {
            delete category.child_location;
        }
    })
    catalog.data.products.forEach((product) => {
        if (isAssociatedWithChildCatalog(product, childLocationId, childCatalogId)) {
            delete product.child_location;
        }
    })
    catalog.data.deals.forEach((deal) => {
        if (isAssociatedWithChildCatalog(deal, childLocationId, childCatalogId)) {
            delete deal.child_location;
        }
    })
    catalog.data.option_lists.forEach((optionList) => {
        if (isAssociatedWithChildCatalog(optionList, childLocationId, childCatalogId)) {
            delete optionList.child_location;
        }
    })
}

export function getCatalogEntityDiffResult(oldCatalogEntities: BaseCatalogEntity[], newCatalogEntities: BaseCatalogEntity[]): CatalogEntityDiffResult | null {

    const entityDiffRes: CatalogEntityDiffResult = {
        created_refs: [],
        deleted_refs: [],
        updated_refs: [],
        reordered_refs: []
    }

    entityDiffRes.created_refs = newCatalogEntities.reduce((createdRefs: string[], current) => {
        const found = oldCatalogEntities.find(entity => entity.ref === current.ref)

        if (!found) {
            createdRefs.push(current.ref)
        }

        return createdRefs
    }, [])

    entityDiffRes.deleted_refs = oldCatalogEntities.reduce((deletedRefs: string[], current) => {
        const found = newCatalogEntities.find(entity => entity.ref === current.ref)

        if (!found) {
            deletedRefs.push(current.ref)
        }

        return deletedRefs
    }, [])

    entityDiffRes.updated_refs = newCatalogEntities.reduce((updatedRefs: string[], current) => {
        const oldEntity = oldCatalogEntities.find(entity => entity.ref === current.ref)

        if (oldEntity) {
            if (!_.isEqual(oldEntity, current)) {
                updatedRefs.push(current.ref)
            }
        }

        return updatedRefs
    }, [])

    entityDiffRes.reordered_refs = oldCatalogEntities.reduce((reorderedRefs: string[], current, index) => {
        const newCatalogIndex = newCatalogEntities.findIndex(entity => entity.ref === current.ref)
        const isInCreated = entityDiffRes.created_refs.some(ref => current.ref === ref)
        const isInDeleted = entityDiffRes.deleted_refs.some(ref => current.ref === ref)

        if (
            newCatalogIndex !== index
            && !isInCreated
            && !isInDeleted
        ) {
            reorderedRefs.push(current.ref)
        }

        return reorderedRefs
    }, [])

    if (
        entityDiffRes.created_refs.length === 0
        && entityDiffRes.deleted_refs.length === 0
        && entityDiffRes.updated_refs.length === 0
        && entityDiffRes.reordered_refs.length === 0
    ) {
        return null
    }

    return entityDiffRes

}

export function getCatalogDiffResult(oldCatalog: Catalog, newCatalog: Catalog): CatalogDiffResult {

    const catalogDiffResult: CatalogDiffResult = {
        account_id: newCatalog.account_id!,
        location_id: newCatalog.location_id!,
        catalog_id: newCatalog.id!,
        data: {}
    }
    catalogDiffResult.data.categories = getCatalogEntityDiffResult(oldCatalog.data.categories, newCatalog.data.categories);
    catalogDiffResult.data.products = getCatalogEntityDiffResult(oldCatalog.data.products, newCatalog.data.products);
    catalogDiffResult.data.option_lists = getCatalogEntityDiffResult(oldCatalog.data.option_lists, newCatalog.data.option_lists);
    catalogDiffResult.data.deals = getCatalogEntityDiffResult(oldCatalog.data.deals, newCatalog.data.deals);
    catalogDiffResult.data.taxes = getCatalogEntityDiffResult(
        oldCatalog.taxes ?? [],
        newCatalog.taxes ?? []
    );
    catalogDiffResult.data.suggestions = getCatalogEntityDiffResult(
        oldCatalog.data.suggestions ?? [],
        newCatalog.data.suggestions ?? []
    );
    catalogDiffResult.data.discounts = getCatalogEntityDiffResult(
        oldCatalog.data.discounts ?? [],
        newCatalog.data.discounts ?? []
    );
    return catalogDiffResult;
}

/**
 * Function use to merge two Catalog
 * Use old catalog as base, in order to keep all parameters and configurations add from MyLemonade bo (tax, discount ...)
 * Check new catalog data and ignored_fields map to update old catalog
 * if a field is ignored, old catalog value will be unchanged.
 * Both catalogs are checked by default before merge
 * Able to skip check with false boolean
 * @param oldCatalog 
 * @param newCatalog 
 * @param location
 * @param ignored_fields 
 * @param catalogNeedsCheck
 * @returns 
 */
export function getMergedCatalog(
    oldCatalog: Catalog,
    newCatalog: Catalog,
    location: Pick<Location, "table_areas">,
    ignored_fields: IgnoredField | undefined,
    ignored_entities: CatalogMergeIgnoredEntities | undefined,
    catalogNeedsCheck: boolean | undefined = true,
    newItemsCategoryInfo?: {
        ref: string,
        name: string,
    }
) {

    /**
     * 4 map check : Products / Categories / Options / Deal
     * For each one of the old catalog =>
     * 
     *   STEP 1: check if entity exists in new catalog
     * 
     *     if true: update necessary fields (based on ignored fields)
     *     if false: don't keep them (except for categories)
     * 
     *   STEP 2: check for new entities (not in the old catalog), and push them
     */

    if (catalogNeedsCheck) {
        const checkedNewCatalog = checkCatalog(newCatalog, location, true);
        if (checkedNewCatalog.check_result?.anomalies?.length) {
            log.info(`${checkedNewCatalog.check_result?.anomalies?.length} have been detected in new catalog during import`, checkedNewCatalog.check_result.anomalies);
        }
        const checkedOldCatalog = checkCatalog(oldCatalog, location, true);
        if (checkedOldCatalog.check_result?.anomalies?.length) {
            log.info(`${checkedOldCatalog.check_result?.anomalies?.length} have been detected in old catalog during import`, checkedOldCatalog.check_result.anomalies);
        }

    }

    // Taking the old catalog general information: do not take the products, categories, deals and options for now.
    // Those will be set just below after the merge processes
    let mergedCatalog: Catalog = _.cloneDeep({
        ...newCatalog,  // Setting the new information such as the name
        data: {
            ...oldCatalog.data,  // Keeping the old data, will be merged below
            products: [],
            categories: [],
            deals: [],
            option_lists: [],
            options: [],
        }
    });

    /////////////////
    // PRODUCTS
    /////////////////

    const mergedProductData: Product[] = [];
    const prod_ignore = ignored_fields?.products;

    // If newItemsCategoryInfo is defined we are merging master catalog 
    // so we don't want to delete items from old catalog
    if (newItemsCategoryInfo) {
        mergedProductData.push(..._.cloneDeep(oldCatalog.data.products));
    } else {
        oldCatalog.data.products.forEach((oldProduct: Product) => {

            let newProduct = newCatalog.data.products.find(pr => pr.ref === oldProduct.ref)

            // STEP 1: check if entity exists in new catalog
            if (newProduct && !ignored_entities?.products) {
                const productUpdated = getMergedProduct(oldProduct, newProduct, prod_ignore);
                mergedProductData.push(productUpdated);
            }
        })
    }

    const mergedCategoryData: Categorie[] = [];

    // STEP 2: check for new entities (not in the old catalog), and push them
    if (!ignored_entities?.products) {
        newCatalog.data.products.forEach(product => {
            const isInOldCatalog = oldCatalog.data.products.find(pdt => product.ref === pdt.ref);
            if (!isInOldCatalog) {
                const newProduct = _.cloneDeep(product)
                if (newItemsCategoryInfo) {
                    newProduct.category_ref = newItemsCategoryInfo.ref
                    const categoryAlreadyExist = oldCatalog.data.categories.find(pr => pr.ref === newItemsCategoryInfo.ref)
                    if (!categoryAlreadyExist && !mergedCategoryData.find((cat) => cat.ref === newItemsCategoryInfo.ref)) {
                        const newCategory: Categorie = {
                            name: newItemsCategoryInfo.name,
                            ref: newItemsCategoryInfo.ref,
                            disable: true,  // The purpose of this category is to mask new items from the webapp menu
                        }
                        mergedCategoryData.push(newCategory);
                    }
                }
                mergedProductData.push(newProduct);
            }
        })
    }

    mergedCatalog.data.products = mergedProductData;


    /////////////////
    // CATEGORIES
    /////////////////

    const cat_ignore = ignored_fields?.categories;

    // If newItemsCategoryInfo is defined we are merging master catalog 
    // so we don't want to delete categories from old catalog
    if (newItemsCategoryInfo) {
        mergedCategoryData.push(..._.cloneDeep(oldCatalog.data.categories));
    } else {
        oldCatalog.data.categories.forEach((oldCategory: Categorie) => {

            const newCategory = newCatalog.data.categories.find(pr => pr.ref === oldCategory.ref);

            // STEP 1: check if entity exists in new catalog
            if (newCategory && !ignored_entities?.categories) {

                const categoryUpdated: Categorie = {
                    ...oldCategory,
                    description: (cat_ignore?.description || newCategory.description === undefined) ? oldCategory.description : newCategory.description,
                    display: (cat_ignore?.display || newCategory.display === undefined) ? oldCategory.display : newCategory.display,
                    icon: (cat_ignore?.icon || newCategory.icon === undefined) ? oldCategory.icon : newCategory.icon,
                    name: (cat_ignore?.name || newCategory.name === undefined) ? oldCategory.name : newCategory.name,
                    parent_ref: (cat_ignore?.parent_ref || newCategory.parent_ref === undefined) ? oldCategory.parent_ref : newCategory.parent_ref,
                    restrictions: getMergedRestrictions(
                        getRestrictionsArray(oldCategory.restrictions),
                        getRestrictionsArray(newCategory.restrictions)
                    ),
                }
                mergedCategoryData.push(categoryUpdated);
            }
            else {
                mergedCategoryData.push(oldCategory);
            }
        });
    }

    // STEP 2: check for new entities (not in the old catalog), and push them
    if (!ignored_entities?.categories) {
        newCatalog.data.categories.forEach(category => {
            const isInOldCatalog = oldCatalog.data.categories.find(cate => category.ref === cate.ref);
            if (!isInOldCatalog) {
                mergedCategoryData.push(category);
            }
        })
    }

    mergedCatalog.data.categories = mergedCategoryData;


    /////////////////
    // DEALS
    /////////////////

    const deal_ignore = ignored_fields?.deals;
    const mergedDealData: Deal[] = [];

    // If newItemsCategoryInfo is defined we are merging master catalog 
    // so we don't want to delete deals from old catalog
    if (newItemsCategoryInfo) {
        mergedDealData.push(..._.cloneDeep(oldCatalog.data.deals));
    } else {
        oldCatalog.data.deals.forEach((oldDeal: Deal) => {

            let newDeal = newCatalog.data.deals.find(pr => pr.ref === oldDeal.ref);

            // STEP 1: check if entity exists in new catalog
            if (newDeal && !ignored_entities?.deals) {

                const linesToMerge: DealLine[] = []

                oldDeal.lines.forEach(line => {

                    const newLine = newDeal?.lines.find(ln => ln.ref === line.ref);

                    if (newLine) {
                        const lineTomerge = {
                            ...line,
                            name: deal_ignore?.lines?.name ? line.name : newLine.name,
                        }
                        linesToMerge.push(lineTomerge);
                    }
                    else {
                        linesToMerge.push(line);
                    }
                })

                const dealUpdated: Deal = {
                    ...oldDeal,
                    name: (deal_ignore?.name || newDeal.name === undefined) ? oldDeal.name : newDeal.name,
                    lines: (deal_ignore?.lines || newDeal.lines === undefined) ? oldDeal.lines : newDeal.lines,
                    restrictions: getMergedRestrictions(
                        getRestrictionsArray(oldDeal.restrictions),
                        getRestrictionsArray(newDeal.restrictions)
                    ),
                }

                mergedDealData.push(dealUpdated);
            }
        })
    }

    // STEP 2: check for new entities (not in the old catalog), and push them
    if (!ignored_entities?.deals) {
        newCatalog.data.deals.forEach(deal => {
            const isInOldCatalog = oldCatalog.data.deals.find(dl => deal.ref === dl.ref);
            if (!isInOldCatalog) {
                const newDeal: Deal = _.cloneDeep(deal)
                if (newItemsCategoryInfo) {
                    newDeal.category_ref = newItemsCategoryInfo.ref
                    const categoryAlreadyExist = oldCatalog.data.categories.find(pr => pr.ref === newItemsCategoryInfo.ref)
                    if (!categoryAlreadyExist && !mergedCategoryData.find((cat) => cat.ref === newItemsCategoryInfo.ref)) {
                        const newCategory: Categorie = {
                            name: newItemsCategoryInfo.name,
                            ref: newItemsCategoryInfo.ref,
                            disable: true,  // The purpose of this category is to mask new items from the webapp menu
                        }
                        mergedCategoryData.push(newCategory);
                    }
                }
                mergedDealData.push(newDeal);
            }
        })
    }

    mergedCatalog.data.deals = mergedDealData;


    /////////////////
    // OPTION LISTS
    /////////////////

    const optionListIgnore = ignored_fields?.option_lists;
    const mergedOptionsListData: OptionList[] = []

    //If newItemsCategoryInfo is defined we are merging master catalog 
    // so we don't want to delete items from old catalog
    if (newItemsCategoryInfo) {
        mergedOptionsListData.push(..._.cloneDeep(oldCatalog.data.option_lists));
    } else {
        oldCatalog.data.option_lists.forEach((oldCatalogOptionList: OptionList) => {

            const newCatalogOptionList = newCatalog.data.option_lists.find(ol => ol.ref === oldCatalogOptionList.ref)

            // STEP 1: check if entity exists in new catalog
            if (newCatalogOptionList && !ignored_entities?.option_lists) {

                const optionListUpdated = getMergedOptionList(oldCatalogOptionList, newCatalogOptionList, optionListIgnore)
                mergedOptionsListData.push(optionListUpdated);
            }
        })
    }

    // STEP 2: check for new entities (not in the old catalog), and push them
    if (!ignored_entities?.option_lists) {
        newCatalog.data.option_lists.forEach(option_list => {
            const isInOldCatalog = oldCatalog.data.option_lists.find(optl => option_list.ref === optl.ref);
            if (!isInOldCatalog) {
                mergedOptionsListData.push(option_list);
            }
        })
    }

    mergedCatalog.data.option_lists = mergedOptionsListData;

    /////////////////
    // OPTIONS
    /////////////////

    const optionsIgnore = ignored_fields?.option_lists?.options;
    const mergedOptionsData: Option[] = [];

    // If newItemsCategoryInfo is defined we are merging master catalog 
    // so we don't want to delete items from old catalog
    if (newItemsCategoryInfo) {
        mergedOptionsData.push(..._.cloneDeep(oldCatalog.data.options));
    } else {
        oldCatalog.data.options.forEach((oldCatalogOption) => {

            const newCatalogOption = newCatalog.data.options.find(o => o.ref === oldCatalogOption.ref);

            // STEP 1: check if entity exists in new catalog
            if (newCatalogOption && !ignored_entities?.options) {
                const optionUpdated = getMergedOption(oldCatalogOption, newCatalogOption, optionsIgnore);
                mergedOptionsData.push(optionUpdated);
            }
        })
    }

    // STEP 2: check for new entities (not in the old catalog), and push them
    if (!ignored_entities?.options) {
        newCatalog.data.options.forEach((newCatalogOption) => {
            const isInOldCatalog = oldCatalog.data.options.find(o => o.ref === newCatalogOption.ref);
            if (!isInOldCatalog) {
                mergedOptionsData.push(newCatalogOption);
            }
        })
    }

    mergedCatalog.data.options = mergedOptionsData;


    /////////////////
    // TAXES
    /////////////////
    const taxIgnore = ignored_fields?.taxes;
    mergedCatalog.taxes = getMergedTaxes(oldCatalog.taxes, newCatalog.taxes, taxIgnore, ignored_entities?.taxes);

    return mergedCatalog;
}

export function getMergedTaxes(
    oldTaxes: Tax[] | undefined,
    newTaxes: Tax[] | undefined,
    ignoreTaxFieldsConfig?: IgnoreTaxFieldsConfig,
    ignoreTaxEntity?: boolean,
): Tax[] | undefined {

    const mergedTaxData: Tax[] = [];
    oldTaxes?.forEach((oldTax: Tax) => {
        const newTax = newTaxes?.find(pr => pr.ref === oldTax.ref);

        // STEP 1: check if entity exists in new catalog
        if (newTax) {

            const taxUpdated = {
                ...oldTax,
                name: (ignoreTaxFieldsConfig?.name || !newTax.name) ? oldTax.name : newTax.name,
            }
            mergedTaxData.push(taxUpdated);
        }
        else {
            mergedTaxData.push(oldTax);
        }
    });

    if (!ignoreTaxEntity) {
        newTaxes?.forEach(newTax => {
            const isInOldCatalog = oldTaxes?.find(oldTax => oldTax.ref === newTax.ref);
            if (!isInOldCatalog) {
                mergedTaxData.push(newTax);
            }
        })
    }

    return mergedTaxData;
}

export function getMergedProduct(oldProduct: Product, newProduct: Product, ignoreProductFieldsConfig?: IgnoreProductFieldsConfig): Product {
    const skusToMerge: Sku[] = [];

    oldProduct.skus.forEach(oldSku => {

        const newSku = newProduct?.skus.find(sk => sk.ref === oldSku.ref);

        if (newSku) {
            skusToMerge.push(getMergedSku(oldSku, newSku, ignoreProductFieldsConfig?.skus?.name));
        }
        else {
            skusToMerge.push(oldSku);
        }
    });

    const productUpdated: Product = {
        ...oldProduct,
        category_ref: ((ignoreProductFieldsConfig?.category_ref || newProduct.category_ref === undefined) && oldProduct.category_ref) ? oldProduct.category_ref : newProduct.category_ref,
        best_seller: (ignoreProductFieldsConfig?.best_seller || newProduct.best_seller === undefined) ? oldProduct.best_seller : newProduct.best_seller,
        description: (ignoreProductFieldsConfig?.description || newProduct.description === undefined) ? oldProduct.description : newProduct.description,
        description_tags: (ignoreProductFieldsConfig?.description_tags || newProduct.description_tags === undefined) ? oldProduct.description_tags : newProduct.description_tags,
        image: (ignoreProductFieldsConfig?.image || newProduct.image === undefined) ? oldProduct.image : newProduct.image,
        skus: (ignoreProductFieldsConfig?.skus || newProduct.skus === undefined) ? skusToMerge : newProduct.skus,
        tags: (ignoreProductFieldsConfig?.tags || newProduct.tags === undefined) ? oldProduct.tags : newProduct.tags,
        name: (ignoreProductFieldsConfig?.name || newProduct.name === undefined) ? oldProduct.name : newProduct.name,
        restrictions: getMergedRestrictions(
            getRestrictionsArray(oldProduct.restrictions),
            getRestrictionsArray(newProduct.restrictions)
        ),
    }
    if (newProduct.tax_ref) {
        productUpdated.tax_ref = newProduct.tax_ref;
    }
    return productUpdated;
}

export function getMergedSku(oldSku: Sku, newSku: Sku, ignoreNewName?: boolean): Sku {
    const skuToMerge: Sku = {
        ...oldSku,
        name: (ignoreNewName || newSku.name === undefined) ? oldSku.name : newSku.name,
        price: newSku.price,
        price_overrides: newSku.price_overrides,
        option_list_refs: newSku.option_list_refs
    }
    return skuToMerge;
}

// TODO: refactor to use new options system
// TODO: new function getMergedOption
export function getMergedOptionList(
    oldCatalogOptionList: OptionList,
    newCatalogOptionList: OptionList,
    optionListIgnore?: IgnoreOptionListFieldsConfig,
): OptionList {

    const optionLinesToMerge: OptionLine[] = [];

    oldCatalogOptionList.option_lines.forEach(oldOptionLine => {

        const newOptionLine = newCatalogOptionList?.option_lines.find((newOptionLine) => oldOptionLine.option_ref === newOptionLine.option_ref);

        if (newOptionLine) {

            const optionLineToMerge: OptionLine = {
                ...oldOptionLine,
                price: newOptionLine.price,
            }
            if (newOptionLine.extra_price) {
                optionLineToMerge.extra_price = newOptionLine.extra_price;
            }
            optionLinesToMerge.push(optionLineToMerge);
        }
    });

    newCatalogOptionList.option_lines.forEach((newOptionLine) => {
        const isInOldCatalog = oldCatalogOptionList.option_lines.find((oldOptionLine) => newOptionLine.option_ref === oldOptionLine.option_ref);
        if (!isInOldCatalog) {
            optionLinesToMerge.push(newOptionLine);
        }
    });

    const optionListUpdated: OptionList = {
        ...oldCatalogOptionList,
        name: (optionListIgnore?.name || !newCatalogOptionList.name) ? oldCatalogOptionList.name : newCatalogOptionList.name,
        option_lines: optionLinesToMerge,
        connector_type: newCatalogOptionList.connector_type,
        max: (newCatalogOptionList.max !== undefined) ? newCatalogOptionList.max : oldCatalogOptionList.max,
        min: (newCatalogOptionList.min !== undefined) ? newCatalogOptionList.min : oldCatalogOptionList.min,
        repeatable: (newCatalogOptionList.repeatable !== undefined) ? newCatalogOptionList.repeatable : oldCatalogOptionList.repeatable,
        max_before_extra: (newCatalogOptionList.max_before_extra !== undefined) ? newCatalogOptionList.max_before_extra : oldCatalogOptionList.max_before_extra,
        restrictions: getMergedRestrictions(
            getRestrictionsArray(oldCatalogOptionList.restrictions),
            getRestrictionsArray(newCatalogOptionList.restrictions)
        ),
    }
    return optionListUpdated;
}

// TODO: test it
export function getMergedOption(
    oldCatalogOption: Option,
    newCatalogOption: Option,
    optionIgnore?: IgnoreOptionFieldsConfig,
): Option {

    const optionUpdated: Option = {
        ...oldCatalogOption,
        name: (optionIgnore?.name || !newCatalogOption.name) ? oldCatalogOption.name : newCatalogOption.name,
        description: (optionIgnore?.description || !newCatalogOption.description) ? oldCatalogOption.description : newCatalogOption.description,
    }
    return optionUpdated;
}

/**
 * Remvoe empty referential fields from products & categories to reduce the document size
 */
export function removeEmptyReferential(catalog: Catalog): Catalog {
    if (!catalog.data) {
        return catalog;
    }

    const products = _.map(catalog?.data?.products, product => {
        if (!product.referential) {
            return product;
        }

        return {
            ...product,
            referential: _.pickBy(product.referential, _.identity) as DataFyreBrandProduct
        }
    });

    const categories = _.map(catalog?.data?.categories, (category): Categorie => {
        if (!category.referential) {
            return category;
        }

        return {
            ...category,
            referential: _.pickBy(category.referential, _.identity) as ReferentialCategories
        }
    });

    catalog.data.products = products;
    catalog.data.categories = categories;

    return catalog;
}

export function deleteExtendedProperties(catalog: Catalog) {
    const catalogDataExtended = catalog as CatalogExtended;
    delete catalogDataExtended.data.categoriesAndProducts;
    delete catalogDataExtended.data.categoriesMap;
    delete catalogDataExtended.data.productsByCategory;
    delete catalogDataExtended.data.productsMap;
    delete catalogDataExtended.data.skusMap;
    delete catalogDataExtended.data.dealsMap;
    delete catalogDataExtended.data.dealsByCategory;
    delete catalogDataExtended.data.categoriesAndProducts;
    delete catalogDataExtended.data.mainCategories;
    delete catalogDataExtended.data.subCategories;

    const catalogBo = catalogDataExtended as CatalogExtBO;

    catalogBo.data.option_lists?.forEach((optionList) => {
        delete optionList.used_by_products;

        optionList?.option_lines?.forEach((optionLine) => {
            delete (optionLine as any)?.option;
        })
    })

    catalogBo.data.options?.forEach((option) => {
        delete option.used_by_products;
        delete option.used_by_option_list;
    })
}

export type CatalogFlatEntity = (Categorie | Product | Sku | Deal | OptionList | Option);

/**
 * Retrieve flat catalog from catalog. Note: the entities returned are cloned deep.
 * @param catalog 
 * @param removeSingleSkus if true, skus alone in their products will be removed from the list
 * @param foldedCategories if defined, the categories with the given refs will be folded
 * @returns 
 */
export const getFlatCatalog = (
    catalog: Catalog,
    removeSingleSkus?: boolean,
    foldedCategories?: string[]
): CatalogFlatEntity[] => {

    const flatCatalog: CatalogFlatEntity[] = [];

    const categories = catalog.data.categories.filter((cat) => !cat.parent_ref);
    const subCategories = catalog.data.categories.filter((cat) => cat.parent_ref);

    categories.forEach((category) => {
        const isCategoryFolded = foldedCategories?.includes(category.ref);

        // Disable child sub cat, products and skus
        if (category.disable) {
            catalog.data.products.forEach((product) => {
                if (product.category_ref === category.ref) {
                    product.isParentDisable = true;
                    product.skus.forEach((sku) => {
                        sku.isParentDisable = true;
                    });
                }
            });
        }

        // Push product which have the current category as parent
        pushCategoryAndProduct(category, flatCatalog, catalog, removeSingleSkus, isCategoryFolded);

        subCategories.forEach((subCategory) => {

            if (subCategory.parent_ref === category.ref) {

                if (category.disable) {
                    subCategory.isParentDisable = true;
                }

                // Disable child sub cat, products and skus
                if (subCategory.disable || category.disable) {
                    catalog.data.products.forEach((product) => {
                        if (product.category_ref === subCategory.ref) {
                            product.isParentDisable = true;
                            product.skus.forEach((sku) => {
                                sku.product_ref = product.ref;
                                sku.isParentDisable = true;
                            });
                        }
                    });
                }

                // Push product who have sub cat parent
                pushCategoryAndProduct(subCategory, flatCatalog, catalog, removeSingleSkus, isCategoryFolded);
            }
        });
    });

    const options_by_key = _.keyBy(catalog.data.options, option => option.ref);

    catalog.data.option_lists.forEach((optionList) => {
        optionList.entity_type = CatalogEntityType.OPTION_LIST;
        flatCatalog.push(optionList);

        _.forEach(optionList.option_lines, (option_line) => {
            const option = options_by_key[option_line.option_ref];

            if (!option) {
                return
            }

            option.entity_type = CatalogEntityType.OPTION;
            option.isParentDisable = optionList.disable;

            flatCatalog.push(option);
        })
    });

    catalog.data.options.forEach((option) => {
        option.entity_type = CatalogEntityType.OPTION;
        flatCatalog.push(option);
    });

    catalog.data.deals.forEach((deal) => {
        deal.entity_type = CatalogEntityType.DEAL;
        flatCatalog.push(deal);
    });

    return _.cloneDeep(flatCatalog);
}

/**
 * Function add one below the other item :
 * Add root category, product of root cat if have it, and sku
 * Add sub cat with his product if have it, and sku
 * Add option list, with his option
 * Add deal with his option
 * @param category : categorie working on, can be root or sub
 * @param flatCatalog : received flat catalog that we handle
 * @param catalog : global catalog
 * @param rootCategorie : for working subCategorie, product and sku under rootCategorie
 * @param removeSingleSkus if true, skus alone in their products will be removed from the list
 * @param isCategoryFolded if true, the category will be folded and products not diplayed
 * @returns flat catalog updated
 */
export const pushCategoryAndProduct = (
    category: Categorie,
    flatCatalog: CatalogFlatEntity[],
    catalog: Catalog,
    removeSingleSkus?: boolean,
    isCategoryFolded?: boolean
): void => {

    category.entity_type = CatalogEntityType.CATEGORY;
    flatCatalog.push(category);

    if (!isCategoryFolded) {
        catalog.data.products.forEach((product) => {

            if (product.category_ref === category.ref) {

                product.entity_type = CatalogEntityType.PRODUCT;
                flatCatalog.push(product);

                // Do not push the skus if this condition is met
                if (removeSingleSkus && product.skus.length === 1) { }
                else {

                    product.skus.forEach((sku) => {
                        sku.entity_type = CatalogEntityType.SKU;
                        flatCatalog.push(sku);
                    });

                }
            }
        });
    }
}

export const affectFirstProductImageToCategory = (catalog: Catalog) => {

    catalog.data.categories.forEach((category) => {
        if (!category.icon) {
            const foundProductWithImage = catalog.data.products?.find((product) => product.category_ref === category.ref && product.image);
            category.icon = foundProductWithImage?.image;
            if (category.parent_ref) {
                const foundParentCategory = catalog.data.categories.find((parentCat) => parentCat.ref === category.parent_ref);
                if (foundParentCategory && !foundParentCategory.icon) {
                    foundParentCategory.icon = category.icon;
                }
            }
        }
    })
}

export const checkCatalog = (catalog: Catalog, location: Pick<Location, "table_areas">, fix?: boolean): CheckedCatalog => {

    const anomalies: CatalogAnomaly[] = [];

    // TODO: check price format and value not < 0 too
    // TODO: finalize tax checking

    // The sku ref must be unique among all products
    const allSkusByRefs: { [key: string]: { entity: CatalogEntity; index: number; } } = {};

    // All the table area refs of the location.
    const allTableAreasOfLocation: string[] = getAllLocationTableAreasRefs(location.table_areas);

    // Cheking the catalog for invalid restrictions
    checkInvalidRestrictions(getRestrictionsArray(catalog.restrictions), catalog, CatalogEntityType.CATALOG, anomalies, fix);

    catalog.data.option_lists?.forEach((optionList) => {

        const options: Option[] = [];
        optionList.option_lines.forEach((optionLine) => {
            const foundOption = catalog.data.options?.find(o => o.ref === optionLine.option_ref);
            if (foundOption) {
                options.push(foundOption);
            }
        })

        // TODO: anomaly ?
        if (optionList.type === 'single') {
            optionList.min = 1;
            optionList.max = 1;
        }

        const optionListMissingOneOption = checkCatalogEntityMissingAtLeastOneRefInObj(CatalogEntityType.OPTION, CatalogEntityType.OPTION_LIST, optionList, options);
        if (optionListMissingOneOption) {
            // TODO: adding it to a list of invalid or removing from the map 
            // Therefore, removed from products using it
            anomalies.push(optionListMissingOneOption);
        }
    });

    const checkedOptionLists = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.OPTION_LIST, catalog.data.option_lists, fix);
    anomalies.push(...checkedOptionLists.duplicates);
    anomalies.push(...checkedOptionLists.missingRefs);

    const checkedOptions = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.OPTION, catalog.data.options, fix);
    anomalies.push(...checkedOptions.duplicates);
    anomalies.push(...checkedOptions.missingRefs);

    const checkedTaxes = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.TAX, catalog.taxes, fix);
    anomalies.push(...checkedTaxes.duplicates);
    anomalies.push(...checkedTaxes.missingRefs);

    const savedOriginalCategories = [...catalog.data.categories];
    const checkedCategories = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.CATEGORY, catalog.data.categories, fix);
    anomalies.push(...checkedCategories.duplicates);
    anomalies.push(...checkedCategories.missingRefs);
    anomalies.push(...checkCatalogEntityInvalidTableAreaRef(CatalogEntityType.CATEGORY, catalog.data.categories, allTableAreasOfLocation, fix));
    savedOriginalCategories.forEach((category) => {
        if (category.parent_ref) {
            const invalidCategoryref = checkCatalogEntityInvalidRef(CatalogEntityType.CATEGORY, CatalogEntityType.CATEGORY, category.parent_ref, checkedCategories.entityByRef, category);
            if (invalidCategoryref) {
                log.debug(`Invalid parent category ${invalidCategoryref.entity.ref} for category ${invalidCategoryref.parent_ref}`)
                anomalies.push(invalidCategoryref);
            }
        }
    });

    const checkedProducts = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.PRODUCT, catalog.data.products, fix);
    anomalies.push(...checkedProducts.duplicates);
    anomalies.push(...checkedProducts.missingRefs);
    anomalies.push(...checkCatalogEntityInvalidTableAreaRef(CatalogEntityType.PRODUCT, catalog.data.products, allTableAreasOfLocation, fix));

    // WARNING: Needs to be done after checking for duplicate option lists, categories and products
    catalog.data.products?.forEach((product) => {

        product.skus?.forEach((sku, index) => {
            sku.product_ref = product.ref;
            if (sku.option_list_refs) {
                const skuInvalidOptionLists = checkCatalogEntityInvalidRefs(CatalogEntityType.OPTION_LIST, CatalogEntityType.SKU, sku.option_list_refs, checkedOptionLists.entityByRef, sku, fix, product.ref);
                anomalies.push(...skuInvalidOptionLists);
            }
            const invalidSkuPrice = checkCatalogEntityMandatoryPrice(CatalogEntityType.SKU, index, sku, "price", catalog.currency, sku.price);
            if (invalidSkuPrice) {
                log.debug(`Invalid price for sku ${sku.ref}: ${sku.price} (fixed: ${invalidSkuPrice.fixed_price})`)
                anomalies.push(invalidSkuPrice);
                if (fix) {
                    // TODO: move fix at the end
                    fixCatalogAnomaly(invalidSkuPrice, catalog);
                }
            }
        })
        if (product.tax_ref) {
            const invalidTaxref = checkCatalogEntityInvalidRef(CatalogEntityType.TAX, CatalogEntityType.PRODUCT, product.tax_ref, checkedTaxes.entityByRef, product);
            if (invalidTaxref) {
                if (fix) {
                    // TODO: check if best thing to do
                    delete product.tax_ref;
                    invalidTaxref.fixed = true;
                }
                anomalies.push(invalidTaxref);
            }
        }
        // Checking invalid restrictions
        checkInvalidRestrictions(getRestrictionsArray(product.restrictions), product, CatalogEntityType.PRODUCT, anomalies, fix);

        // Use allSkusByRefs to check that the ref is unique among all products
        const skusCount = product.skus.length
        const checkedSkus = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.SKU, product.skus, fix, product.ref, allSkusByRefs);
        anomalies.push(...checkedSkus.duplicates);
        anomalies.push(...checkedSkus.missingRefs);
        if (!skusCount || checkedSkus.duplicates.length + checkedSkus.missingRefs.length === skusCount) {
            log.debug(`Product ${product.ref} does not have at least one valid sku`)
            anomalies.push(getCatalogEntityMissingAtLeastOneRefInObj(CatalogEntityType.SKU, CatalogEntityType.PRODUCT, product));
        }

        const invalidCategoryref = checkCatalogEntityInvalidRef(CatalogEntityType.CATEGORY, CatalogEntityType.PRODUCT, product.category_ref, checkedCategories.entityByRef, product);
        if (invalidCategoryref) {
            log.debug(`Invalid category ${invalidCategoryref.invalid_ref} for product ${invalidCategoryref.name ?? invalidCategoryref.entity.ref}`)
            anomalies.push(invalidCategoryref);
        }
    });

    const checkedDeals = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.DEAL, catalog.data.deals, fix);
    anomalies.push(...checkedDeals.duplicates);
    anomalies.push(...checkedDeals.missingRefs);
    anomalies.push(...checkCatalogEntityInvalidTableAreaRef(CatalogEntityType.DEAL, catalog.data.deals, allTableAreasOfLocation, fix));

    // WARNING: Needs to be done after checking for duplicate products and deals
    catalog.data.deals?.forEach((deal) => {
        if (deal.category_ref) {
            const invalidCategoryref = checkCatalogEntityInvalidRef(CatalogEntityType.CATEGORY, CatalogEntityType.DEAL, deal.category_ref, checkedCategories.entityByRef, deal);
            if (invalidCategoryref) {
                log.debug(`Invalid category ${invalidCategoryref.entity.ref} for deal ${invalidCategoryref.parent_ref}`)
                anomalies.push(invalidCategoryref);
            }
        }

        if (!deal.lines || deal.lines.length === 0) {
            anomalies.push(getCatalogEntityMissingAtLeastOneRefInObj(CatalogEntityType.DEAL_LINE, CatalogEntityType.DEAL, deal));
        } else {
            let firstInvalidDealLine: MinimumOneRefInArray | null = null
            deal.lines?.forEach((dealLine, index) => {

                // Check deal line pricing_value
                const dealLineInvalidPricingValue = checkCatalogEntityPricingEffect(CatalogEntityType.DEAL_LINE, index, dealLine, catalog.currency, dealLine, deal.ref);
                if (dealLineInvalidPricingValue) {
                    anomalies.push(dealLineInvalidPricingValue);
                    if (fix) {
                        // TODO: move fix at the end
                        fixCatalogAnomaly(dealLineInvalidPricingValue, catalog);
                    }
                }

                // Check deal line sku reference
                const dealLineSkuCount = dealLine.skus.length
                const dealLinesCheck = checkCatalogEntityInvalidRefsInObj(CatalogEntityType.SKU, CatalogEntityType.DEAL_LINE, dealLine.skus, allSkusByRefs, dealLine, fix, deal.ref);
                anomalies.push(...dealLinesCheck);
                // No more sku in the deal line
                // No need to have several as the deal will be removed
                if (!firstInvalidDealLine && (!dealLineSkuCount || dealLineSkuCount === dealLinesCheck.length)) {
                    firstInvalidDealLine = getCatalogEntityMissingAtLeastOneRefInObj(CatalogEntityType.SKU, CatalogEntityType.DEAL_LINE, dealLine, deal.ref);
                    anomalies.push(firstInvalidDealLine);
                }
            })

            // Check deal line ref
            const checkedDealLines = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.DEAL_LINE, deal.lines, false, deal.ref);

            // TODO: move this in fixCatalogAnomaly function (CatalogAnomalyService)
            if (fix) {
                checkedDealLines.missingRefs.forEach((lineAnomaly) => {
                    const line = deal.lines[lineAnomaly.index];
                    line.ref = `${deal.ref}_${lineAnomaly.index}`;
                    lineAnomaly.fixed = true;
                })
                checkedDealLines.duplicates.forEach((duplicateAnomaly) => {
                    deal.lines.splice(duplicateAnomaly.duplicate_index, 1);
                    duplicateAnomaly.fixed = true;
                })
            }

            anomalies.push(...checkedDealLines.duplicates);
            anomalies.push(...checkedDealLines.missingRefs);
        }
        // Checking invalid restrictions
        checkInvalidRestrictions(getRestrictionsArray(deal.restrictions), deal, CatalogEntityType.DEAL, anomalies, fix);
    });

    // Create missing categories
    if (fix) {
        const allInvalidCategoryRefs: InvalidReference[] = anomalies.filter((anomaly) =>
            anomaly.anomaly_type === CatalogAnomalyType.INVALID_REFERENCE
            && anomaly.invalid_ref_type === CatalogEntityType.CATEGORY
        ) as InvalidReference[];

        // TODO: move this in fixCatalogAnomaly function (CatalogAnomalyService)
        allInvalidCategoryRefs?.forEach((invalidCategoryRef) => {
            if (!checkedCategories.entityByRef[invalidCategoryRef.invalid_ref]) {
                const newCategory: Categorie = {
                    ref: invalidCategoryRef.invalid_ref,
                    name: invalidCategoryRef.invalid_ref,
                    disable: true
                };
                const newLength = catalog.data.categories.push(newCategory)
                checkedCategories.entityByRef[invalidCategoryRef.invalid_ref] = {
                    entity: newCategory,
                    index: newLength - 1
                }
                invalidCategoryRef.fixed = true;
            }
        })
    }

    // WARNING: Needs to be done after checking for duplicate products and before checking for duplicate suggestions
    catalog.data.suggestions?.forEach((suggestion) => {
        const suggestionSkusCheck = checkCatalogEntityInvalidRefsInObj(CatalogEntityType.SKU, CatalogEntityType.SUGGESTION, suggestion.skus, allSkusByRefs, suggestion, fix);
        anomalies.push(...suggestionSkusCheck);
    })
    const checkedSuggestions = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.SUGGESTION, catalog.data.suggestions, fix);
    anomalies.push(...checkedSuggestions.duplicates);
    anomalies.push(...checkedSuggestions.missingRefs);

    catalog.data.discounts?.forEach((discount, index) => {
        const invalidDiscountPricingValue = checkCatalogEntityPricingEffect(CatalogEntityType.DISCOUNT, index, discount, catalog.currency, discount);
        if (invalidDiscountPricingValue) {
            anomalies.push(invalidDiscountPricingValue);
            if (fix) {
                // TODO: move fix at the end
                fixCatalogAnomaly(invalidDiscountPricingValue, catalog);
            }
        }
        // Checking invalid restrictions
        checkInvalidRestrictions(getRestrictionsArray(discount.restrictions), discount, CatalogEntityType.DISCOUNT, anomalies, fix);
    })
    const checkedDiscounts = checkCatalogEntityMissingRefsAndDuplicates(CatalogEntityType.DISCOUNT, catalog.data.discounts, fix);
    anomalies.push(...checkedDiscounts.duplicates);
    anomalies.push(...checkedDiscounts.missingRefs);
    anomalies.push(...checkCatalogEntityInvalidTableAreaRef(CatalogEntityType.DISCOUNT, catalog.data.discounts, allTableAreasOfLocation, fix));

    // Checking for invalid restriction anomalies in the categories
    catalog.data.categories?.forEach((category) => {
        checkInvalidRestrictions(getRestrictionsArray(category.restrictions), category, CatalogEntityType.CATEGORY, anomalies, fix);
    });

    // NOTE: At the moment, don't support inconsistent restrictions
    // First: looking for inconsistent restrictions with the Location restrictions
    // spreadRestrictionsToLowerCatalogEntities(catalog, location.restriction, true, !fix, anomalies);
    // Then: looking for inconsistent restrictions with the Catalog restrictions
    // spreadRestrictionsToLowerCatalogEntities(catalog, catalog.restrictions, false, !fix, anomalies);

    if (fix) {
        anomalies.forEach((catalogAnomaly) => {
            fixCatalogAnomaly(catalogAnomaly, catalog);
        });
    }

    const catalogChecked: CheckedCatalog = {
        ...catalog,
        check_result: {
            anomalies: anomalies,
        }
    };

    return catalogChecked;
}

export const checkCatalogEntityInvalidRefs = (type: CatalogEntityType, parentType: CatalogEntityType, entityRefs: string[], entityByRef: { [key: string]: { entity: CatalogEntity; index: number; } }, parent: CatalogEntity, fix?: boolean, parentParentRef?: string): InvalidReference[] => {
    const invalidEntityRefs: InvalidReference[] = [];
    entityRefs?.forEach((entityRef, index) => {

        const invalidEntityRef = checkCatalogEntityInvalidRef(type, parentType, entityRef, entityByRef, parent, index, parentParentRef);
        if (invalidEntityRef) {
            invalidEntityRefs.push(invalidEntityRef);
        }
    });
    invalidEntityRefs.sort((a, b) => b.index - a.index);
    if (fix && invalidEntityRefs.length) {
        invalidEntityRefs.forEach((invalidEntityRef) => {
            entityRefs.splice(invalidEntityRef.index, 1);
            invalidEntityRef.fixed = true;
        })
        log.debug(`Kept ${type} in parent ${parentType} ref ${parent.ref} after fix: ${entityRefs.length}`)
    }
    return invalidEntityRefs;
}

export const checkCatalogEntityPricingEffect = (type: CatalogEntityType, index: number, entity: CatalogEntity, currency: string, pricing_strategy: { pricing_effect: PricingEffect; pricing_value?: Money | number; }, parentRef?: string): InvalidPrice | InvalidPercentage | null => {
    //console.log(`Checking pricing effect for ${type} with ref ${entity.ref}`);
    if (pricing_strategy) {
        if (pricing_strategy.pricing_effect === PricingEffect.EFFECT_FIXED_PRICE ||
            pricing_strategy.pricing_effect === PricingEffect.EFFECT_PRICE_OFF) {
            return checkCatalogEntityMandatoryPrice(type, index, entity, "pricing_value", currency, pricing_strategy.pricing_value as Money, parentRef);
        } else if (pricing_strategy.pricing_effect === PricingEffect.EFFECT_PERCENTAGE_OFF) {
            return checkCatalogEntityMandatoryPercentage(type, index, entity, "pricing_value", pricing_strategy.pricing_value as number, parentRef);
        }
    }
    return null;
}

export const checkCatalogEntityMandatoryPrice = (type: CatalogEntityType, index: number, entity: CatalogEntity, priceProperty: PriceProperty, currency: string, price: Money, parentRef?: string): InvalidPrice | null => {

    if (price) {
        return checkCatalogEntityPrice(type, index, entity, priceProperty, currency, price, parentRef);
    } else {
        return {
            type: type,
            index: index,
            entity: entity,
            price: "",
            price_property: priceProperty,
            fixed_price: `0.00 ${currency}`,
            anomaly_type: CatalogAnomalyType.INVALID_PRICE,
            parent_ref: parentRef
        }
    }
}

export const checkCatalogEntityMandatoryPercentage = (type: CatalogEntityType, index: number, entity: CatalogEntity, percentageProperty: PercentageProperty, percentage: number, parentRef?: string): InvalidPercentage | null => {

    let invalid = false;
    let fixedPercentage: number;
    //console.log(`Checking percentage value ${percentage} for ${type} with ref ${entity.ref}`);
    if (percentage !== null && percentage !== undefined) {
        if (typeof percentage === "number") {
            if (percentage >= 0 && percentage <= 100) {
                return null;
            } else {
                log.error(`Invalid percentage value: ${percentage} is not between 0 and 100 (name: ${entity.name}, type: ${type})`)
                invalid = true;
                fixedPercentage = 0;
            }
        } else {
            log.error(`Invalid percentage value: ${percentage} is not a number (name: ${entity.name}, type: ${type})`)
            const parsedPercentage = parseInt(percentage);
            if (!isNaN(parsedPercentage) && parsedPercentage >= 0 && parsedPercentage <= 100) {
                fixedPercentage = parsedPercentage;
            } else {
                fixedPercentage = 0;
            }
            invalid = true;
        }
    } else {
        log.error(`Invalid percentage value: ${percentage} is null or undefined (name: ${entity.name}, type: ${type})`)
        invalid = true;
        fixedPercentage = 0;
    }

    if (invalid) {
        return {
            type: type,
            index: index,
            entity: entity,
            percentage: percentage,
            percentage_property: percentageProperty,
            fixed_percentage: fixedPercentage,
            anomaly_type: CatalogAnomalyType.INVALID_PERCENTAGE,
            parent_ref: parentRef
        }
    }
    return null;
}

export const checkCatalogEntityPrice = (type: CatalogEntityType, index: number, entity: CatalogEntity, priceProperty: PriceProperty, currency: string, price?: Money, parentRef?: string): InvalidPrice | null => {
    if (price) {
        const checkedPrice = moneyToNumberCheck(price, undefined, undefined, undefined, entity);
        if (checkedPrice === null) {
            //console.log(`Checked price ${price} with val ${checkedPrice}`)
            const parsedPrice = parseFloat(price);
            let fixedPrice: Money;
            if (!isNaN(parsedPrice)) {
                fixedPrice = numberToMoney(parsedPrice, currency)
            } else {
                fixedPrice = `0.00 ${currency}`;
            }
            return {
                type: type,
                index: index,
                entity: entity,
                price: price,
                price_property: priceProperty,
                fixed_price: fixedPrice,
                anomaly_type: CatalogAnomalyType.INVALID_PRICE,
                parent_ref: parentRef
            }
        }
    }
    return null;
}

export const checkCatalogEntityMissingAtLeastOneRefInObj = (childType: CatalogEntityType, entityType: CatalogEntityType, entity: CatalogEntity, entityRefs: { ref: string }[] | string[], parentRef?: string): MinimumOneRefInArray | null => {
    if (!entityRefs || !entityRefs.length) {
        return getCatalogEntityMissingAtLeastOneRefInObj(childType, entityType, entity, parentRef);
    }
    return null;
}

export const getCatalogEntityMissingAtLeastOneRefInObj = (childType: CatalogEntityType, entityType: CatalogEntityType, entity: CatalogEntity, parentRef?: string): MinimumOneRefInArray => {
    return {
        type: entityType,
        entity: entity,
        child_type: childType,
        parent_ref: parentRef,
        anomaly_type: CatalogAnomalyType.MINIMUM_ONE_REF_IN_ARRAY
    }
}

export const checkCatalogEntityInvalidRefsInObj = (type: CatalogEntityType, parentType: CatalogEntityType, entityRefs: { ref: string }[], entityByRef: { [key: string]: { entity: CatalogEntity; index: number; } }, parent: CatalogEntity, fix?: boolean, parentParentRef?: string): InvalidReference[] => {
    const invalidEntityRefs: InvalidReference[] = [];
    entityRefs?.forEach((entityRef, index) => {

        const invalidEntityRef = checkCatalogEntityInvalidRef(type, parentType, entityRef.ref, entityByRef, parent, index, parentParentRef);
        if (invalidEntityRef) {
            invalidEntityRefs.push(invalidEntityRef);
        }
    });
    invalidEntityRefs.sort((a, b) => b.index - a.index);
    if (fix && invalidEntityRefs.length) {
        invalidEntityRefs.forEach((invalidEntityRef) => {
            entityRefs.splice(invalidEntityRef.index, 1);
            invalidEntityRef.fixed = true;
        })
        log.debug(`Kept ${type} in parent ${parentType} ref ${parent.ref} after fix: ${entityRefs.length}`)
    }
    return invalidEntityRefs;
}

export const checkCatalogEntityInvalidRef = (childType: CatalogEntityType, parentType: CatalogEntityType, childRef: string, entityByRef: { [key: string]: { entity: CatalogEntity; index: number; } }, parent: CatalogEntity, index?: number, parentParentRef?: string): InvalidReference | null => {

    if (!entityByRef[childRef]) {
        log.debug(`Invalid ${childType} ref ${childRef} in parent ${parentType} ref ${parent.ref}`)
        return {
            index: index ? index : 0,
            entity: parent,
            invalid_ref: childRef,
            invalid_ref_type: childType,
            type: parentType,
            parent_ref: parentParentRef,
            anomaly_type: CatalogAnomalyType.INVALID_REFERENCE
        }
    }
    return null;
}

export interface CatalogMissingRefsAndDuplicatesCheckResult {
    entityByRef: { [key: string]: { entity: CatalogEntity; index: number; } };
    duplicates: DuplicateEntity[];
    missingRefs: MissingEntityRef[];
}

export const checkCatalogEntityMissingRefsAndDuplicates = (
    catalogEntityType: CatalogEntityType,
    catalogEntityList: CatalogEntity[] | undefined,
    fix?: boolean,
    parentRef?: string,
    existingEntityByRef?: { [key: string]: { entity: CatalogEntity; index: number; } }
): CatalogMissingRefsAndDuplicatesCheckResult => {

    const duplicateEntitites: DuplicateEntity[] = [];
    const missingRefs: MissingEntityRef[] = [];
    const entityByRef: { [key: string]: { entity: CatalogEntity; index: number; } } = existingEntityByRef ? existingEntityByRef : {};

    if (catalogEntityList) {
        catalogEntityList?.forEach((entity, index) => {
            if (entity.ref) {
                if (!entityByRef[entity.ref]) {
                    // Add it to the map
                    entityByRef[entity.ref] = {
                        entity: entity,
                        index: index
                    };
                } else {
                    // Product already in the map
                    duplicateEntitites.push({
                        entity: entity,
                        duplicate_index: index,
                        ref_index: entityByRef[entity.ref].index,
                        type: catalogEntityType,
                        parent_ref: parentRef,
                        anomaly_type: CatalogAnomalyType.DUPLICATE_ENTITY
                    });
                }
            } else {
                missingRefs.push({
                    index: index,
                    type: catalogEntityType,
                    parent_ref: parentRef,
                    entity,
                    anomaly_type: CatalogAnomalyType.MISSING_ENTITY_REF
                })
            }
        });
        duplicateEntitites.sort((a, b) => b.duplicate_index - a.duplicate_index);
        missingRefs.sort((a, b) => b.index - a.index);

        if (fix) {
            duplicateEntitites.forEach((duplicateEntity) => {
                catalogEntityList.splice(duplicateEntity.duplicate_index, 1);
                duplicateEntity.fixed = true;
            });
            missingRefs.forEach((missingRef) => {
                catalogEntityList.splice(missingRef.index, 1);
                missingRef.fixed = true;
            });
        }
    }

    return {
        duplicates: duplicateEntitites,
        entityByRef: entityByRef,
        missingRefs: missingRefs,
    }
}

/**
 * Takes an entity list (ex: all the categories) and checks if the mentionned table_areas
 * (in the restrictions) are all valid.
 * @param catalogEntityType 
 * @param catalogEntityList 
 * @param allTableAreasOfLocation 
 * @param fix 
 * @returns 
 */
const checkCatalogEntityInvalidTableAreaRef = (
    catalogEntityType: CatalogEntityType,
    catalogEntityList: CatalogEntityWithTableAreaRestriction[] | undefined,
    allTableAreasOfLocation: string[],
    fix?: boolean,
): InvalidTableAreaRef[] => {

    const invalidTableAreaRefs: InvalidTableAreaRef[] = [];

    catalogEntityList?.forEach((entity) => {

        const entityRestrictions = getRestrictionsArray(entity.restrictions);

        entityRestrictions?.forEach((restriction) => {

            restriction.table_areas_refs?.forEach((areaRef) => {
                if (!allTableAreasOfLocation.includes(areaRef)) {

                    // TODO: use an InvalidReference ?
                    const invalidTableAreaRef: InvalidTableAreaRef = {
                        entity,
                        area_ref: areaRef,
                        type: catalogEntityType,
                        anomaly_type: CatalogAnomalyType.INVALID_TABLE_AREA_REF
                    }

                    // Do not push another already in the list
                    if (!invalidTableAreaRefs.find((invalidTableAreaRef) => (
                        invalidTableAreaRef.entity.ref === entity.ref
                        && invalidTableAreaRef.area_ref === areaRef
                    ))) {
                        invalidTableAreaRefs.push(invalidTableAreaRef);
                    }
                }
            });

        });
    });

    if (fix) {

        invalidTableAreaRefs.forEach((invalidTableAreaRef) => {

            const entity = invalidTableAreaRef.entity;
            const entityRestrictions = getRestrictionsArray(entity.restrictions);

            entityRestrictions?.forEach((restriction) => {

                const areaRefs = restriction.table_areas_refs;
                const indexToDelete = areaRefs?.findIndex((areaRef) => areaRef === invalidTableAreaRef.area_ref);
                if (indexToDelete !== undefined && indexToDelete > -1) {
                    areaRefs?.splice(indexToDelete, 1);
                    if (areaRefs?.length === 0) {
                        delete restriction.table_areas_refs;
                        invalidTableAreaRef.fixed = true;
                    }
                }
            });
        });
    }

    return invalidTableAreaRefs;
}

/**
 * Filter categories, products and deals using table areas restrictions.
 * WARNING: here we don't care about the time. We only filter by table_area_refs. Maybe some
 * of the entities are not actually available at the time, but this will be checked later in the webapp process.
 * @param catalog 
 * @param tableAreaRef 
 */
export const filterCatalogWithTableArea = (catalog: Catalog, tableAreaRef: string) => {

    if (tableAreaRef) {

        // Exclude categories for which the current restriction(s) (if any) does not contain the table area
        let allowedTableAreaCategories = catalog.data.categories.filter((category) => isEntityAvailableForTableArea(category, tableAreaRef));
        let allowedTableAreaCategoriesRef = allowedTableAreaCategories.map((category) => category.ref);

        //If the parent category has been excluded, exclude the category too
        allowedTableAreaCategories = allowedTableAreaCategories.filter((category) => !category.parent_ref || allowedTableAreaCategoriesRef.includes(category.parent_ref));
        allowedTableAreaCategoriesRef = allowedTableAreaCategories.map((category) => category.ref);
        catalog.data.categories = allowedTableAreaCategories;

        const filteredOptionLists = catalog.data.option_lists.filter((optionList) => {
            if (isEntityAvailableForTableArea(optionList, tableAreaRef)) {
                return true;
            }
            return false;
        }
        );
        catalog.data.option_lists = filteredOptionLists;
        // Remove the removed option list in products
        catalog.data.products.forEach((product) => {
            product.skus?.forEach((sku) => {
                sku.option_list_refs?.forEach((optionListRef) => {
                    const foundOptionList = catalog.data.option_lists?.find((optionList) => optionList.ref === optionListRef)
                    if (!foundOptionList) {
                        sku.option_list_refs?.splice(sku.option_list_refs.indexOf(optionListRef), 1);
                    }
                });
            });
        });

        // Product category has not been excluded and no table area restriction or restriction that includes the table area ref
        const filteredProducts = catalog.data.products.filter((product) =>
            allowedTableAreaCategoriesRef.includes(product.category_ref)
            && isEntityAvailableForTableArea(product, tableAreaRef)
        );


        // Deal category exists and has not been excluded and no table area restriction or restriction that includes the table area ref
        catalog.data.deals = catalog.data.deals.filter(
            (deal) => (
                !deal.category_ref
                || allowedTableAreaCategoriesRef.includes(deal.category_ref)
            )
                && isEntityAvailableForTableArea(deal, tableAreaRef)
        );

        // Check deal products
        catalog.data.deals.forEach((deal) => {
            deal.lines.forEach((dealLine) => {
                dealLine.skus?.forEach((dealLineSku) => {
                    const foundProduct = productService.getProductBasedOnSkuRef(dealLineSku.ref, catalog);
                    if (foundProduct) {
                        const foundProductInFiltered = filteredProducts.find((filteredProduct) => filteredProduct.ref === foundProduct.ref);
                        if (!foundProductInFiltered) {
                            // Deal product has been removed, add it to be able to order the deal
                            // TODO: find a better way
                            foundProduct.category_ref = "";
                            filteredProducts.push(foundProduct);
                        }
                    }
                })
            })
        });
        catalog.data.products = filteredProducts;
    }

}

export const getOptionListWithOption = (optionRef: string, catalog: Catalog): OptionList | undefined => {
    return catalog.data.option_lists.find((optionList) => optionList.option_lines?.find((optionLine) => optionLine.option_ref === optionRef))
}

export const removeUnusedOptionlists = (catalog: Catalog) => {
    const usedOptionList: OptionList[] = [];
    catalog.data.products?.forEach((product) => {
        product.skus?.forEach((sku) => {
            sku.option_list_refs?.forEach((optionListRef) => {
                const foundOptionList = catalog.data.option_lists?.find((optionList) => optionList.ref === optionListRef)
                if (foundOptionList && !usedOptionList.includes(foundOptionList)) {
                    usedOptionList.push(foundOptionList);
                }
            })
        })
    });
    catalog.data.option_lists = usedOptionList;
}

export const updateCatalogPriceCurrency = (catalog: Catalog): Catalog => {
    const currencyCode = catalog.currency
    const updatedCatalog: Catalog = _.cloneDeep(catalog)

    if (catalog.restrictions && Object.values(catalog.restrictions).length > 0) {
        Object.values(catalog.restrictions).forEach(res => {
            if (res.min_order_amount) {
                res.min_order_amount = updateCurrencyOfMoney(res.min_order_amount, currencyCode)
            }
        })
    }

    if (updatedCatalog.data.products.length > 0) {
        updatedCatalog.data.products.forEach(prod => {

            if (prod.skus.length > 0) {
                prod.skus.forEach(sku => {
                    sku.price = updateCurrencyOfMoney(sku.price, currencyCode)

                    if (sku.price_overrides && sku.price_overrides.length > 0) {
                        sku.price_overrides.forEach(po => po.price = updateCurrencyOfMoney(po.price, currencyCode))
                    }
                })
            }

            if (prod.restrictions && Object.values(prod.restrictions).length > 0) {
                Object.values(prod.restrictions).forEach(res => {
                    if (res.min_order_amount) {
                        res.min_order_amount = updateCurrencyOfMoney(res.min_order_amount, currencyCode)
                    }
                })
            }
        })
    }

    if (updatedCatalog.data.option_lists.length > 0) {
        updatedCatalog.data.option_lists.forEach((optionList) => {
            if (optionList.option_lines.length > 0) {
                optionList.option_lines.forEach((optionLine) => {
                    optionLine.price = updateCurrencyOfMoney(optionLine.price, currencyCode)
                })
            }
        })
    }

    if (updatedCatalog.data.deals.length > 0) {
        updatedCatalog.data.deals.forEach(deal => {
            if (deal.lines.length > 0) {
                deal.lines.forEach(line => {
                    if (line.pricing_value && _.isString(line.pricing_value)) {
                        line.pricing_value = updateCurrencyOfMoney(line.pricing_value as string, currencyCode)
                    }

                    if (line.skus.length > 0) {
                        line.skus.forEach(sku => {
                            if (sku.extra_charge) {
                                sku.extra_charge = updateCurrencyOfMoney(sku.extra_charge, currencyCode)
                            }
                        })
                    }
                })
            }

            if (deal.restrictions && Object.values(deal.restrictions).length > 0) {
                Object.values(deal.restrictions).forEach(res => {
                    if (res.min_order_amount) {
                        res.min_order_amount = updateCurrencyOfMoney(res.min_order_amount, currencyCode)
                    }
                })
            }
        })
    }

    if (updatedCatalog.data.discounts && updatedCatalog.data.discounts.length > 0) {
        updatedCatalog.data.discounts.forEach(discount => {
            if (discount.restrictions && Object.values(discount.restrictions).length > 0) {
                Object.values(discount.restrictions).forEach(res => {
                    if (res.min_order_amount) {
                        res.min_order_amount = updateCurrencyOfMoney(res.min_order_amount, currencyCode)
                    }
                })
            }

            if (discount.pricing_value && _.isString(discount.pricing_value)) {
                discount.pricing_value = updateCurrencyOfMoney(discount.pricing_value as string, currencyCode)
            }
        })
    }

    return updatedCatalog
}

/**
 * Returns an array with all the parents entities of an entity
 * @param item 
 * @param catalog 
 * @returns 
 */
export const getParentEntities = (item: BaseCatalogEntity, catalog: Catalog): BaseCatalogEntity[] => {
    const parents: BaseCatalogEntity[] = []

    switch (item.entity_type) {
        case CatalogEntityType.CATEGORY:
            const mainCat = catalog.data.categories.find(entity => entity.ref === (item as Categorie).parent_ref)
            if (mainCat) {
                parents.push(mainCat)
            }
            break

        case CatalogEntityType.PRODUCT:
            const category = catalog.data.categories.find(entity => entity.ref === (item as Product).category_ref)
            if (category) {
                const supCat = getParentEntities(category, catalog)
                if (supCat.length > 0) {
                    parents.push(...supCat)
                }
                parents.push(category)
            }
            break

        case CatalogEntityType.DEAL:
            const dealCat = catalog.data.categories.find(entity => entity.ref === (item as Deal).category_ref)
            if (dealCat) {
                const supCat = getParentEntities(dealCat, catalog)
                if (supCat.length > 0) {
                    parents.push(...supCat)
                }
                parents.push(dealCat)
            }
            break

        case CatalogEntityType.SKU:
            const product = catalog.data.products.find(entity => entity.ref === (item as Sku).product_ref)
            if (product) {
                const cat = getParentEntities(product, catalog)
                if (cat.length > 0) {
                    parents.push(...cat)
                }
                parents.push(product)
            }
            break

        case CatalogEntityType.OPTION:
            const optionList = catalog.data.option_lists.find(entity => entity.ref === (item as Option).option_list_ref)
            if (optionList) {
                parents.push(optionList)
            }
            break

        default:
            break
    }

    return parents
}

/**
 * Goes trough the catalog and converts all the dates into a real Date object.
 * Without this function, some dates would end up as strings in firestore, causing
 * timezone problems.
 * WARN: still a work in progress, at the moment only disabled_up_to_time fields are converted
 */
export const convertCatalogDates = (catalog: Catalog, removeOldDisableUpToTime: boolean): void => {

    const dateNow = new Date();
    catalog.data.products.forEach(product => {
        if (product.disable_up_to_time) {
            product.disable_up_to_time = extractDateFromTimestampOrString(product.disable_up_to_time);
            if (!product.disable_up_to_time || product.disable_up_to_time < dateNow) {
                delete product.disable_up_to_time;
            }
        }
    });

    catalog.data.categories.forEach(category => {
        if (category.disable_up_to_time) {
            category.disable_up_to_time = extractDateFromTimestampOrString(category.disable_up_to_time);
            if (!category.disable_up_to_time || category.disable_up_to_time < dateNow) {
                delete category.disable_up_to_time;
            }
        }
    });

    catalog.data.option_lists.forEach(optionList => {
        if (optionList.disable_up_to_time) {
            optionList.disable_up_to_time = extractDateFromTimestampOrString(optionList.disable_up_to_time);
            if (!optionList.disable_up_to_time || optionList.disable_up_to_time < dateNow) {
                delete optionList.disable_up_to_time;
            }
        }
    });

    catalog.data.deals.forEach(deal => {
        if (deal.disable_up_to_time) {
            deal.disable_up_to_time = extractDateFromTimestampOrString(deal.disable_up_to_time);
            if (!deal.disable_up_to_time || deal.disable_up_to_time < dateNow) {
                delete deal.disable_up_to_time;
            }
        }
    });
}

/**
 * This function processes a "simple" catalog and converts it into a "extended" catalog.
 * For this, use "const catalogExt = catalog as CatalogExtended;" and then pass "catalogExt" to the function.
 * You can also give an already extended catalog, and the function will refresh the extended fields.
 * @param catalog 
 */
export const processCatalogExtendedData = (catalog: CatalogExtended): void => {

    const categories = catalog.data.categories;
    const categoriesMap: { [key: string]: Categorie } = {};
    categories.forEach(function (category) {
        categoriesMap[category.ref] = category;
    });

    // Sort products by categories
    const products = catalog.data.products;

    // Disable products if they have no enabled skus
    products.forEach((product) => {
        if (!atLeastOneSkuEnabledInProduct(product)) {
            product.disable = true;
        }
    });

    const productsByCategory: { [key: string]: Product[] } = {};
    const productsMap: { [key: string]: Product } = {};
    const skusMap: { [key: string]: SkuExtended } = {};
    if (products) {
        products.forEach(function (product) {
            productsMap[product.ref] = product;
            if (product.skus && product.skus.length > 0) {
                product.skus.forEach((sku) => {
                    if (!skusMap[sku.ref]) {
                        skusMap[sku.ref] = sku;
                    } else {
                        log.error(`Duplicate sku ref ${sku.ref}`);
                    }
                });
            } else {
                log.error(`No sku for product ${product.ref}`);
            }
            let productCategory: null | Categorie = null;
            if (product.category_ref) {
                productCategory = categoriesMap[product.category_ref];
                if (!productCategory) {
                    log.error(`Category ${product.category_ref} not found for product ${product.ref}`);
                }
            }
            if (productCategory) {
                addProductToCategory(productsByCategory, categoriesMap, product, productCategory);
            }
        });
    } else {
        catalog.data.products = [];
    }

    // Sort deals by categories
    const deals = catalog.data.deals;
    const dealsByCategory: { [key: string]: Deal[] } = {};
    const dealsMap: { [key: string]: Deal } = {};
    if (deals) {
        deals.forEach(function (deal) {
            dealsMap[deal.ref] = deal;
            let dealCategory: null | Categorie = null;
            if (deal.category_ref) {
                dealCategory = categoriesMap[deal.category_ref];
                if (!dealCategory) {
                    log.error(`Category ${deal.category_ref} not found for deal ${deal.ref}`);
                }
            }
            if (dealCategory) {
                addDealToCategory(dealsByCategory, dealsMap, deal, dealCategory);
            }
        });
    } else {
        catalog.data.deals = [];
    }

    /**
     * Create an array with all the categories/sub and their products to display
     * with DisplayProductScroll
     * Also create two arrays: mainCategories and subCategories
     */

    const computedMainCats: Categorie[] = catalog.data.categories.filter((cat) => (!cat.parent_ref || cat.parent_ref === "") && (!cat.disable || cat.display_if_disabled));
    const computedSubCats: Categorie[] = catalog.data.categories.filter((cat) => cat.parent_ref && cat.parent_ref !== "" && (!cat.disable || cat.display_if_disabled));
    const finalComputedMainCats: Categorie[] = _.cloneDeep(computedMainCats)

    const mainCatIndexesToRemove: number[] = [];
    const catsAndProds: CategoriesAndProducts[] = computedMainCats.reduce(
        (acc: CategoriesAndProducts[], current, index) => {

            const subCats = computedSubCats.filter((cat) => cat.parent_ref === current.ref);

            if (subCats.length > 0) {

                acc.push({ data: current, type: CatalogEntityType.CATEGORY, mainCat: current.ref });

                subCats.forEach((subCat) => {

                    const productsForSubCat: ProductExtended[] = products.filter((product) => (product.category_ref === subCat.ref));
                    const dealsForCategory: DealExt[] = deals.filter((deal) => deal.category_ref === subCat.ref);

                    if (productsForSubCat.length > 0 || dealsForCategory.length > 0) {
                        acc.push({ data: subCat, type: CatalogEntityType.SUB_CATEGORY, mainCat: current.ref, subCat: subCat.ref });

                        if (dealsForCategory.length > 0) {
                            dealsForCategory.forEach((deal) => {
                                acc.push({ data: deal, type: CatalogEntityType.DEAL, mainCat: current.ref, subCat: subCat.ref })
                            })
                        }

                        if (productsForSubCat.length > 0) {
                            productsForSubCat.forEach((prod) => {
                                acc.push({ data: prod, type: CatalogEntityType.PRODUCT, mainCat: current.ref, subCat: subCat.ref });
                            });
                        }
                    }
                });

            }
            else {

                const productsWithoutSubCat: ProductExtended[] = products.filter((product) => (product.category_ref === current.ref));
                const dealsForCategory: DealExt[] = deals.filter((deal) => deal.category_ref === current.ref);

                if (productsWithoutSubCat.length > 0 || dealsForCategory.length > 0) {
                    acc.push({ data: current, type: CatalogEntityType.CATEGORY, mainCat: current.ref });

                    if (dealsForCategory.length > 0) {
                        dealsForCategory.forEach((deal) => {
                            acc.push({ data: deal, type: CatalogEntityType.DEAL, mainCat: current.ref })
                        })
                    }

                    if (productsWithoutSubCat.length > 0) {
                        productsWithoutSubCat.forEach((prod) => {
                            acc.push({ data: prod, type: CatalogEntityType.PRODUCT, mainCat: current.ref });
                        });
                    }
                } else {
                    mainCatIndexesToRemove.push(index);
                }
            }

            return acc;
        }, []);

    // Removing the main categories
    for (let i = mainCatIndexesToRemove.length - 1; i >= 0; i--) {
        finalComputedMainCats.splice(mainCatIndexesToRemove[i], 1);
    }

    // Processing suggestions to remove suggestions products with options
    // TODO: suggestions with options
    const suggestions = catalog.data.suggestions;
    if (suggestions) {
        catalog.data.suggestions = suggestions.filter((suggestion) => {
            suggestion.skus = suggestion.skus.filter((sku) => {
                const prodSku = skusMap[sku.ref]
                if (prodSku) {
                    if (!prodSku.option_list_refs) {
                        return true
                    }
                    const hasNoOptions = prodSku.option_list_refs.length === 0
                    if (hasNoOptions) {
                        return true
                    }
                    log.warn(`Suggestion ${suggestion.ref} has a product with options ${prodSku.ref}. This product will not be displayed`)
                }
                log.error(`Suggestion ${suggestion.ref} has a product ${sku.ref} that does not exist. This product will not be displayed`)
                return false
            })
            return suggestion.skus.length > 0
        })
    }

    catalog.data.productsByCategory = productsByCategory;
    catalog.data.productsMap = productsMap;
    catalog.data.skusMap = skusMap;
    catalog.data.categoriesMap = categoriesMap;
    catalog.data.dealsMap = dealsMap;
    catalog.data.dealsByCategory = dealsByCategory;
    catalog.data.categoriesAndProducts = catsAndProds;
    catalog.data.mainCategories = finalComputedMainCats;
    catalog.data.subCategories = computedSubCats;
}

/**
 * Sub-function of convertCatalogToCatalogExtended
 * Moved from the webapp
 * @param productsByCategory 
 * @param categoriesMap 
 * @param product 
 * @param category 
 */
const addProductToCategory = (
    productsByCategory: { [key: string]: Product[] },
    categoriesMap: { [key: string]: Categorie },
    product: Product, category: Categorie
) => {

    let categoryProducts = productsByCategory[category.ref];
    if (!categoryProducts) {
        categoryProducts = [];
        productsByCategory[category.ref] = categoryProducts;
    }
    categoryProducts.push(product);
    if (category.parent_ref) {
        const parentCategory = categoriesMap[category.parent_ref];
        //
        if (parentCategory) {
            if (parentCategory.ref !== category.ref) {
                addProductToCategory(productsByCategory, categoriesMap, product, parentCategory);
            } else {
                category.parent_ref = undefined;
                log.error(`Category ${category.ref} is referencing itself as parent category, category.parent_ref was set to null`);
            }
        }
    }
}

/**
 * Sub-function of convertCatalogToCatalogExtended
 * Moved from the webapp
 * @param dealsByCategory 
 * @param categoriesMap 
 * @param deal 
 * @param category 
 */
const addDealToCategory = (
    dealsByCategory: { [key: string]: Deal[] },
    categoriesMap: { [key: string]: Categorie },
    deal: Deal, category: Categorie
) => {

    let categoryDeals = dealsByCategory[category.ref];
    if (!categoryDeals) {
        categoryDeals = [];
        dealsByCategory[category.ref] = categoryDeals;
    }
    categoryDeals.push(deal);
    if (category.parent_ref) {
        const parentCategory = categoriesMap[category.parent_ref];
        //
        if (parentCategory) {
            if (parentCategory.ref !== category.ref) {
                addDealToCategory(dealsByCategory, categoriesMap, deal, parentCategory);
            } else {
                category.parent_ref = undefined;
                log.error(`Category ${category.ref} is referencing itself as parent category, category.parent_ref was set to null`);
            }
        }
    }
}

type LookupOptions = {
    /**
     * Which fields to consider when performing a lookup for existing product
     * By default it will look for product match by ref, if ref is not set it will look for product by name
     * 
     * If you want to perform only lookup based on ref, set keys to ["ref"]
     */
    keys?: ('name' | 'ref')[]
}

export const checkItemsFromOrderAndAddToCatalog = (catalog: CatalogExtended, order: Order, options: LookupOptions = { keys: ["ref", "name"] }): ProductExtended[] => {
    const { products, categories } = getMissingProductsAndCategoriesFromOrder(catalog, order, options)

    if (products.length) {
        addProductsToCatalog(catalog, products)
    }

    if (categories.length) {
        addCategoriesToCatalog(catalog, categories)
    }

    return products;
}

export const getMissingProductsAndCategoriesFromOrder = (catalog: CatalogExtended, order: Order, options?: LookupOptions): { products: ProductExtended[], categories: Categorie[] } => {
    const new_order_items: OrderItem[] = getOrderItemsToAddToCatalog(catalog, order, options);

    const response: { products: ProductExtended[], categories: Categorie[] } = {
        categories: [],
        products: []
    }

    if (new_order_items.length) {
        response.products = convertOrderItemsToProducts(new_order_items);
    }

    response.categories = getCategoriesToAddToCatalog(catalog, order.items, options);

    return response
}

export const getCategoriesToAddToCatalog = (catalog: CatalogExtended, items: OrderItem[], options: LookupOptions = { keys: ["ref"] }): Categorie[] => {
    const { keys = ["ref"] } = options;

    const catalog_categories = catalog?.data?.categories;

    const { by_ref, by_name } = _.reduce<
        Categorie,
        {
            by_ref: Record<string, Categorie>,
            by_name: Record<string, Categorie[]>
        }
    >(
        catalog_categories,
        (acc, item) => {
            const { ref, name } = item;

            const { by_name, by_ref } = acc

            by_ref[ref] = item;
            by_name[name] = [...(by_name[name] || []), item];

            return acc
        },
        {
            by_ref: {},
            by_name: {}
        }
    );

    const missing = _.reduce<OrderItem, Record<string, Categorie>>(
        items,
        (acc, item) => {
            const { categories_refs, categories_names } = item;

            // TODO: support for multiple categories
            const ref = categories_refs?.[0] ?? "";
            const name = categories_names?.[0] ?? "";

            if (keys.includes("ref") && !!ref) {
                if (acc[ref]) {
                    return acc
                }

                const match = !!by_ref[ref];

                if (!match) {
                    acc[ref] = {
                        ref,
                        name,
                        entity_type: CatalogEntityType.CATEGORY,
                    }
                }
            }

            if (keys.includes("name") && !ref && !!name) {
                const matches = by_name[name];

                /**
                 * Found match by name
                 */
                if ((Array.isArray(matches) && matches.length > 0)) {
                    return acc;
                }

                const fuse = new Fuse(catalog.data.categories, {
                    isCaseSensitive: false,
                    threshold: FUSE_THRESHOLD,
                    keys: ['name']
                });

                const exists = fuse.search(name).length > 0;

                if (exists) {
                    return acc;
                }

                acc[name] = {
                    ref,
                    name,
                    entity_type: CatalogEntityType.CATEGORY,
                };
            }

            return acc
        },
        {}
    )

    return Object.values(missing);
}

/**
 * Find products that are in the order but not in the catalog
 */
export const getOrderItemsToAddToCatalog = (catalog: CatalogExtended, order: Order, options: LookupOptions = { keys: ["ref"] }): OrderItem[] => {
    const { keys = ["ref"] } = options;

    const { by_ref, by_name } = _.reduce<
        ProductExtended,
        {
            by_ref: Record<string, ProductExtended>,
            by_name: Record<string, ProductExtended[]>
        }
    >(
        catalog?.data?.products,
        (acc, item) => {
            const { ref, name } = item;

            const { by_name, by_ref } = acc

            by_ref[ref] = item;
            by_name[name] = [...(by_name[name] || []), item];

            return acc
        },
        {
            by_ref: {},
            by_name: {}
        }
    );

    const missing = _.reduce<OrderItem, Record<string, OrderItem>>(
        order?.items,
        (acc, item) => {
            const { product_ref: ref, product_name: name } = item;

            /**
             * Matching product by ref if available
             */
            if (keys.includes("ref") && !!ref) {
                if (acc[ref]) {
                    return acc
                }

                const match = !!by_ref[ref];

                if (!match) {
                    acc[ref] = item
                }
            }

            /**
             * If product ref is not found, try searching for product by name
             */
            if (keys.includes("name") && !ref && !!name) {
                const matches = by_name[name];

                /**
                 * Found match by name
                 */
                if ((Array.isArray(matches) && matches.length > 0)) {
                    return acc;
                }

                acc[name] = item;
            }

            return acc
        },
        {}
    )

    return Object.values(missing)
}

export const convertOrderItemsToProducts = (orderItems: OrderItem[]): ProductExtended[] => {
    const products: Product[] = []

    orderItems.forEach((item) => {
        const product: Product = {
            ref: item.product_ref,
            name: item.product_name,
            entity_type: CatalogEntityType.PRODUCT,
            category_ref: item.categories_refs && item.categories_refs.length ? item.categories_refs[0] : '',
            skus: [{
                ref: item.sku_ref,
                name: item.sku_name || item.product_name,
                price: item.price,
                entity_type: CatalogEntityType.SKU,
            }]
        }
        products.push(product)
    })

    return products
}

const addCategoriesToCatalog = (catalog: CatalogExtended, categories: Categorie[]) => {
    if (!catalog.data.categories) {
        catalog.data.categories = []
    }

    categories.forEach((category) => {
        catalog.data.categories.push(category)
    })
}

const addProductsToCatalog = (catalog: CatalogExtended, products: ProductExtended[]) => {
    if (!catalog.data.products) {
        catalog.data.products = []
    }

    products.forEach((product) => {
        catalog.data.products.push(product)
    })
}

const affectDefaultCategoryTemplate = (categoryRef?: string, categoryName?: string, locale?: string): Categorie => {
    if (categoryName) {
        const options: Fuse.IFuseOptions<CategoryTemplate> = {
            isCaseSensitive: false,
            threshold: FUSE_THRESHOLD_CATEGORY,
            minMatchCharLength: FUSE_MIN_MATCH_CHAR_CATEGORY,
            ignoreLocation: true,
            includeScore: true,
            keys: [`likely_names_per_language.${locale || DEFAULT_LANGUAGE}`]
        }
        const fuse = new Fuse(defaultCategoryTemplates, options)
        const categorySearchResult = fuse.search(categoryName)
        // Try to find for each part too
        const categoryNameSpaceParts = categoryName.split(" ");
        if (categoryNameSpaceParts.length > 1) {
            categoryNameSpaceParts.forEach((categoryNameSpacePart) => {
                if (categoryNameSpacePart.length > FUSE_MIN_MATCH_CHAR_CATEGORY) {
                    const categoryNameSpacePartSearchResult = fuse.search(categoryNameSpacePart)
                    if (categoryNameSpacePartSearchResult.length > 0) {
                        categorySearchResult.push(categoryNameSpacePartSearchResult[0])
                    }
                }
            });
        }

        const categoryNameSlashParts = categoryName.split("/");
        if (categoryNameSlashParts.length > 1) {
            categoryNameSlashParts.forEach((categoryNameSlashPart) => {
                if (categoryNameSlashPart.length > FUSE_MIN_MATCH_CHAR_CATEGORY) {
                    const categoryNameSpacePartSearchResult = fuse.search(categoryNameSlashPart)
                    if (categoryNameSpacePartSearchResult.length > 0) {
                        categorySearchResult.push(categoryNameSpacePartSearchResult[0])
                    }
                }
            });
        }

        categorySearchResult.sort((a, b) => (a.score ?? 1) - (b.score ?? 1))

        if (categorySearchResult?.length > 0) {
            const enhancedCategory: Categorie = {
                ref: categoryRef || categorySearchResult[0].item.ref,
                name: categoryName || categorySearchResult[0].item.name,
                entity_type: CatalogEntityType.CATEGORY,
                icon: categorySearchResult[0].item.icon,
                referential: categorySearchResult[0].item.referential,
            }
            return enhancedCategory
        }
    }

    const category: Categorie = {
        ref: categoryRef || '',
        name: categoryName || '',
        entity_type: CatalogEntityType.CATEGORY,
    }
    return category
}

/**
 * Get all the entities in a catalog for which refs are matching the given.
 * Used by Rushour to disable duplicated entities.
 * @param entityRef 
 * @param catalog 
 * @param isDisabled 
 * @returns An array with the matching entities
 */
const getAllCatalogEntitiesByRef = (entityRef: string, catalog: Catalog): CatalogEntityWithDisable[] => {

    const catalogId = catalog.id
    const foundEntities: CatalogEntityWithDisable[] = []

    catalog.data.products.forEach((product) => {
        if (product.ref === entityRef) {
            log.info(`Product ${entityRef} found in products in catalog ${catalogId}`)
            foundEntities.push(product)
        }
        if (product.skus) {
            product.skus.forEach((sku) => {
                if (sku.ref === entityRef) {
                    log.info(`Product ${entityRef} found in skus in catalog ${catalogId}`)
                    foundEntities.push(sku)
                }
            })
        }
    })

    // TODO: remove someday, legacy options behavior
    catalog.data.option_lists.forEach((optionList, index) => {
        optionList.options?.forEach((option, optIndex) => {
            if (option.ref === entityRef) {
                log.info(`Product ${entityRef} found in option lists in catalog ${catalogId}`)
                foundEntities.push(option)
            }
        })
    })

    catalog.data.options.forEach((option, index) => {
        if (option.ref === entityRef) {
            log.info(`Product ${entityRef} found in options in catalog ${catalogId}`)
            foundEntities.push(option)
        }
    })

    catalog.data.deals.forEach((deal) => {
        if (deal.ref === entityRef) {
            log.info(`Product ${entityRef} found in deals in catalog ${catalogId}`)
            foundEntities.push(deal)
        }
    })

    return foundEntities
}

/**
 * in the export
 * @param catalog 
 * @returns 
 */
export const getCsvFlatCatalog = (catalog: Catalog): string[][] => {

    const result: string[][] = [];
    const pushElement = (
        ref: string | undefined,
        name: string | undefined,
        entityType: string | undefined,
        parentRef: string | undefined,
        categoryRef: string | undefined,
        price: string | undefined,
        description: string | undefined,
    ): void => {

        const formattedDescription = description?.replace(/(?:\r\n|\r|\n)/g, '\\n');

        result.push([
            ref ?? "",
            name ?? "",
            entityType ?? "",
            parentRef ?? "",
            categoryRef ?? "",
            price ?? "",
            formattedDescription ?? "",
        ]);
    }

    pushElement("ref", "name", "entity_type", "parent_ref", "category_ref", "price", "description");  // Header

    const flatCatalog = getFlatCatalog(catalog);
    flatCatalog.forEach((entity) => {
        switch (entity.entity_type) {

            case CatalogEntityType.CATEGORY:
                const typedCategory = entity as Categorie;
                pushElement(
                    typedCategory.ref,
                    typedCategory.name,
                    CatalogEntityType.CATEGORY,
                    typedCategory.parent_ref,
                    "",
                    "",
                    typedCategory.description,
                );
                break;

            case CatalogEntityType.PRODUCT:
                const typedProduct = entity as Product;
                pushElement(
                    typedProduct.ref,
                    typedProduct.name,
                    CatalogEntityType.PRODUCT,
                    "",
                    typedProduct.category_ref,
                    "",
                    typedProduct.description,
                );
                break;

            case CatalogEntityType.SKU:
                const typedSku = entity as Sku;
                pushElement(
                    typedSku.ref,
                    typedSku.name,
                    CatalogEntityType.SKU,
                    typedSku.product_ref,
                    "",
                    typedSku.price,
                    typedSku.description,
                );
                break;

            case CatalogEntityType.DEAL:
                const typedDeal = entity as Deal;
                pushElement(
                    typedDeal.ref,
                    typedDeal.name,
                    CatalogEntityType.DEAL,
                    "",
                    typedDeal.category_ref,
                    computeStartingPriceAndMaxPrice(typedDeal, catalog)?.minPrice,
                    typedDeal.description,
                );
                break;

            case CatalogEntityType.OPTION_LIST:
                const typedOptionList = entity as OptionList;
                pushElement(
                    typedOptionList.ref,
                    typedOptionList.name,
                    CatalogEntityType.OPTION_LIST,
                    "",
                    "",
                    "",
                    typedOptionList.description,
                );
                break;

            // case CatalogEntityType.OPTION_LINE:
            //     const typedOptionLine = entity as OptionLine;
            //     pushElement(
            //         typedOptionLine.option_ref,
            //         "",
            //         CatalogEntityType.OPTION_LINE,
            //         typedOptionLine.option_list_ref,
            //         "",
            //         typedOptionLine.price,
            //         "",
            //     );
            //     break;

            case CatalogEntityType.OPTION:
                const typedOption = entity as Option;
                pushElement(
                    typedOption.ref,
                    typedOption.name,
                    CatalogEntityType.OPTION,
                    "",
                    "",
                    "",
                    typedOption.description,
                );
                break;
        }
    });

    return result;
}

export const updateCatalogDisabledItems = (catalog: Catalog, reference_time = new Date(), disabled_items: CatalogItemDisabled[]): CatalogData => {
    const catalogData = catalog?.data;

    const reference_date_time = DateTime.fromJSDate(reference_time);

    for (const disabled_item of disabled_items) {
        const { disable_up_to_time, ref, entity_type } = disabled_item;

        const disabled_date_time = DateTime.fromJSDate(disable_up_to_time);

        if (!(disabled_date_time <= reference_date_time)) {
            continue;
        }

        switch (entity_type) {
            case CatalogEntityType.PRODUCT: {
                catalogData?.products?.forEach(product => {
                    if (product.ref === ref) {
                        delete product.disable_up_to_time;
                        product.disable = false;
                    }
                });

                break;
            }

            case CatalogEntityType.SKU: {
                catalogData?.products?.forEach(product => {
                    product.skus.forEach(sku => {
                        if (sku.ref === ref) {
                            delete sku.disable_up_to_time;
                            sku.disable = false;
                        }
                    });
                });

                break;
            }

            case CatalogEntityType.CATEGORY: {
                catalogData?.categories?.forEach(category => {
                    if (category.ref === ref) {
                        delete category.disable_up_to_time;
                        category.disable = false;
                    }
                });

                break;
            }

            case CatalogEntityType.OPTION_LIST: {
                catalogData?.option_lists?.forEach(option => {
                    if (option.ref === ref) {
                        delete option.disable_up_to_time;
                        option.disable = false;
                    }
                });

                break;
            }

            case CatalogEntityType.OPTION: {

                // TODO: remove someday, legacy options behavior
                catalogData?.option_lists?.forEach(optionList => {
                    optionList.options?.forEach(option => {
                        if (option.ref === ref) {
                            delete option.disable_up_to_time;
                            option.disable = false;
                        }
                    });
                });

                catalogData?.options?.forEach(option => {
                    if (option.ref === ref) {
                        delete option.disable_up_to_time;
                        option.disable = false;
                    }
                });

                break;
            }

            case CatalogEntityType.DEAL: {
                catalogData?.deals?.forEach(deal => {
                    if (deal.ref === ref) {
                        delete deal.disable_up_to_time;
                        deal.disable = false;
                    }
                });

                break;
            }

            default: {
                break;
            }
        }
    }

    return catalogData;
}

/**
 * Convert an old format catalog (with options as array inside option lists) to new format
 * (with options in a dedicated CatalogData field)
 * @param catalog 
 */
export const convertOptionsFromOldFormat = (catalog: Pick<Catalog, "data" | "currency">, deleteOldOptions?: boolean) => {

    const catalogData = catalog.data;

    if (!catalogData.options) {
        catalogData.options = [];
    }

    catalogData.option_lists.forEach((optionList) => {

        if (!optionList.option_lines) {
            optionList.option_lines = [];
        }

        optionList.options?.forEach((oldOption) => {

            const newOptionLine: OptionLine = {
                option_ref: oldOption.ref,
                price: oldOption.price ?? numberToMoney(0, catalog.currency ?? DEFAULT_CURRENCY),
            }

            if (oldOption.default) {
                newOptionLine.default = oldOption.default;
            }
            if (oldOption.extra_price) {
                newOptionLine.extra_price = oldOption.extra_price;
            }

            const newOption = _.cloneDeep(oldOption);

            delete newOption.default;
            delete newOption.extra_price;
            delete newOption.price;
            delete newOption.option_list_ref;

            /**
             * Check if the option and option line already exist.
             * If old fiends are not deleted we can have duplicates
             */
            if (!optionList.option_lines.find(ol => ol.option_ref === newOptionLine.option_ref)) {
                optionList.option_lines.push(newOptionLine);
            }

            if (!catalogData.options.find(o => o.ref === newOption.ref)) {
                catalogData.options.push(newOption);
            }
        });

        /**
         * Ensure option lines are unique
         */
        optionList.option_lines = _.uniqBy(optionList.option_lines, (optionLine) => optionLine.option_ref);

        if (deleteOldOptions) {
            delete optionList.options;
        }
    });

    /**
     * Ensure options list is unique
     */
    catalogData.options = _.uniqBy(catalogData.options, (option) => option.ref);
}

/**
 * Convert an old format catalog (with options as array inside option lists) to new format
 * (with options in a dedicated CatalogData field)
 * @param catalog 
 */
export const deleteOldOptions = (catalog: Pick<Catalog, "data">) => {
    catalog.data.option_lists.forEach((optionList) => {
        delete optionList.options;
    });
}

/**
 * Given an option list, get all the associated options from the catalog.
 * @param optionList 
 * @param catalogOptions 
 * @returns 
 */
export const getRelatedOptions = (optionList: OptionList, catalogOptions: Option[]): Option[] => {

    const foundOptions: Option[] = [];
    optionList.option_lines.forEach((ol) => {
        const foundOption = catalogOptions.find(o => o.ref === ol.option_ref);
        if (foundOption) {
            foundOptions.push(foundOption);
        }
    });

    return foundOptions;
}


export const catalogHelper = {
    removeEmptyReferential,
    getMergedCatalog,
    getMergedSku,
    getMergedProduct,
    getMergedOptionList,
    filterCatalogWithTableArea,
    removeUnusedOptionlists,
    getOptionListWithOption,
    convertCatalogDates,
    mergeChildCatalog,
    deleteChildLocationAssociation,
    isAssociatedWithChildCatalog,
    deleteExtendedProperties,
    checkItemsFromOrderAndAddToCatalog,
    affectDefaultCategoryTemplate,
    getAllCatalogEntitiesByRef,
}
export default catalogHelper;