import { DateTime } from "luxon";
import { getIdToken } from "../../authentication/services/AuthenticationService";
import { getApiEndpoint } from "../../config/variables";
import { Catalog, getTimezoneName } from "../../my-lemonade-library/model/Catalog";
import { Location, SupportedServiceType, Table } from "../../my-lemonade-library/model/Location";
import { getCatalogTimeslotsRoute } from "../../my-lemonade-library/src/catalogs/configs/CatalogApiRoutes";
import { HttpErrorResponse } from "../../my-lemonade-library/src/common/models/HttpError";
import { getErrorStack } from "../../my-lemonade-library/src/common/services/LogService";
import GetTimeSlotsResponse from "../../my-lemonade-library/src/locations/models/GetTimeSlotsResponse";
import TimeslotsAvailable from "../models/TimeslotsAvailable";
import log from "../services/LogService";

/**
 * This function loads and stores to the local state the list of full timeslots.
 * It's a fetch to the API so it's asynchronous.
 * 
 * 2 modes are available:
 * 
 *   - Refresh full timeslots only: the local state will be refreshed based on the API result.
 *     The value returned by the function is useless as we simply refresh a local state.
 *     No parameter to give
 * 
 *   - Refresh full timeslots + check if a specific timeslot is full: the function takes the timeslot
 *     to check in parameters (string, "HH:mm" format) and sends calls the API. It returns true or false
 *     based on whether or not the timeslot is available. It also refreshes the local state, same as in
 *     the first mode. 
 *     It is useful at the end when the user wants to validate its order, in case the timeslot became full while
 *     they were typing the other information.
 * 
 * @param hourToCheck if set, we check if the timeslot is full.
 */
export const loadAndSelectAvailableTimeSlots = async (
    hourToCheck: DateTime | undefined = undefined,
    selectedLocation: Location,
    selectedCatalog: Catalog,
    selectedTable: Table,
    serviceType?: SupportedServiceType,
    deliveryZoneRef?: string,
    when?: DateTime,
): Promise<TimeslotsAvailable> => {

    let fixedWhen = when;
    const dateNow = DateTime.now()

    if (!fixedWhen || fixedWhen < dateNow) {
        fixedWhen = dateNow;
    }
    let timeslotsAvailable: TimeslotsAvailable = {
        account_id: selectedLocation.account_id,
        location_id: selectedLocation.id,
        catalog_id: selectedCatalog.id,
        table_id: selectedTable.id,
        timezone: getTimezoneName(selectedCatalog),
        when: fixedWhen.toISO(),
        timeslots: [],
        service_type: serviceType,
        delivery_zone_ref: deliveryZoneRef,
        checked_time: hourToCheck ? hourToCheck.toISO() : null,
        checked_timeslot_full: false,
        first_time_available: null
    }

    if (selectedLocation && selectedCatalog && (serviceType || selectedTable.service_type)) {

        try {
            const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
            const headers = new Headers();
            headers.append("Authorization", `Bearer ${tokenResult?.token}`);
            const serviceTypeParam = (serviceType ? serviceType : selectedTable.service_type) as SupportedServiceType;
            const apiUrl: string = `${getApiEndpoint()}${getCatalogTimeslotsRoute(selectedLocation.account_id, selectedLocation.id, selectedCatalog.id!, serviceTypeParam, selectedTable.id, deliveryZoneRef, fixedWhen)}`;
            const response = await fetch(apiUrl, {
                method: "GET",
                headers: headers
            })
            if (!response.ok) {
                const httpError = await response.json() as HttpErrorResponse
                log.error('Error while fetching timeslots', httpError)
                throw Error(httpError.message)
            }
            const responseJSON: GetTimeSlotsResponse = await response.json() as GetTimeSlotsResponse

            let checkedHourIsFull: boolean = false;
            if (responseJSON) {
                if (!responseJSON.delivery_zone_ref) {
                    responseJSON.delivery_zone_ref = deliveryZoneRef;
                }
                if (!responseJSON.service_type) {
                    responseJSON.service_type = serviceType;
                }
                // We iterate trough the array of timeslots
                for (const elem of responseJSON.timeslots) {
                    const timeSlotStart = DateTime.fromISO(elem.start_time as string, { setZone: true });
                    const timeSlotEnd = DateTime.fromISO(elem.end_time as string, { setZone: true });
                    if (hourToCheck &&
                        hourToCheck.toSeconds() >= timeSlotStart.toSeconds() &&
                        hourToCheck.toSeconds() <= timeSlotEnd.toSeconds() &&
                        elem.full
                    ) {
                        checkedHourIsFull = true;
                        // Reinit first available so that the next one not full will be the first available
                        responseJSON.first_time_available = null;
                    }

                    if (!elem.full && !responseJSON.first_time_available) {
                        responseJSON.first_time_available = elem.start_time as string;
                    }
                }
                timeslotsAvailable = {
                    ...responseJSON,
                    checked_time: hourToCheck ? hourToCheck.toISO() : null,
                    checked_timeslot_full: checkedHourIsFull
                }
            }
        }
        catch (error) {
            log.error(`Error while fetching the available time slots for location ${selectedLocation.id}: ${getErrorStack(error)}`)
            throw error
        }
    } else {
        log.error(`Impossible to load available timeslots for location ${selectedLocation.id}, catalog ${selectedCatalog.id}, service type ${serviceType || selectedTable.service_type}`)
    }

    return timeslotsAvailable
}