import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import log from 'loglevel';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { DeviceContext } from '../../App';
import { capitalizeFirstLetter } from '../../Common/helper/TextHelper';
import { getTimezoneName, Option, OptionLine, OptionList, Product, Sku, Tags } from '../../my-lemonade-library/model/Catalog';
import { CatalogExtended } from '../../my-lemonade-library/model/catalogExtended/CatalogExtended';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { Order, OrderOption } from '../../my-lemonade-library/model/Order';
import { SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { OptionLineExtBO } from '../../my-lemonade-library/src/catalogs/models/OptionLineExtBO';
import { OptionListExtBO } from '../../my-lemonade-library/src/catalogs/models/OptionListExtBO';
import { moneyToNumber, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import productService from '../../my-lemonade-library/src/products/services/ProductService';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import tagService from '../../tags/services/tagService';
import ProductOptionsCheckBox from './ProductOptionsCheckBox';
import ProductOptionsRadioList from './ProductOptionsRadioList';
import ProductSkuSelect from './ProductSkuSelect';

type Props = {

    selectedProduct: Product

    selectedSku: Sku
    setSkuSelected?: any

    selectedOptions: OrderOption[]
    onSelectedOptionsChange: (selectedOptions: OrderOption[], invalidOptionLists: OptionList[] | null) => void

    hideSkuSelection?: boolean

    reset?: boolean, // Boolean used to trigger option cleanup if customer whant to order the same product again
    setReset?: (bool: boolean) => void

    dealSku?: SkuExtended
    hideSkuPrice?: boolean;

    optionError?: OptionList[] | null
    forceDescriptionDisplay?: boolean
    displayProductName?: boolean // If true, the product name is displayed (sometimes we don't want the product name is already displayed on the page)
}
/**
 * Options product, in charge to propose sku & option list when a product is selected
 * @param param0 
 */
export default function ProductOptions(props: Props) {

    const { hideSkuSelection, selectedProduct, selectedSku, setSkuSelected, hideSkuPrice, selectedOptions, onSelectedOptionsChange, reset, setReset, dealSku, optionError, forceDescriptionDisplay, displayProductName } = props;

    const intl = useIntl();
    const { selectedCatalog, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { order } = useTypedSelector((state: RootState) => state.order);
    const [optionsToDisplay, setOptionsToDisplay] = useState<OptionListExtBO[]>([])
    const theme = useTheme();
    const { mobile_device } = useContext(DeviceContext)
    const refs = useRef<HTMLDivElement[]>([])

    // Automaticaly open or close accordions
    const [accordionsExpander, setAccordionsExpander] = useState<boolean[]>()
    const [hasBeenOpen, setHasBeenOpen] = useState<boolean[]>([])

    /**
     * Set the list of options to display based on optionsList Function
     */
    useEffect(() => {
        if (selectedCatalog && selectedProduct) {
            const optionLists: OptionListExtBO[] = getAvailableOptionsList(order, selectedProduct, selectedCatalog)
            setOptionsToDisplay(optionLists)
            setAccordionsExpander(optionLists.map((option, index) => index === 0 ? true : false))
            setHasBeenOpen(optionLists.map((option, index) => false))
        }
    }, [selectedCatalog, selectedProduct, order])

    useEffect(() => {
        if (selectedProduct && setSkuSelected) {
            setSkuSelected(selectedProduct.skus[0])
        }
    }, [selectedProduct, setSkuSelected])

    // Reset all choosen options and open all accordions
    useEffect(() => {
        if (reset && setReset && optionsToDisplay) {
            if (optionError && optionError.length > 0) {
                const firstOptionListWithError = optionsToDisplay.find((optionList) => optionError[0].ref === optionList.ref)
                if (firstOptionListWithError) {
                    const errorIndex = optionsToDisplay.findIndex(opt => opt.ref === firstOptionListWithError.ref)
                    setAccordionsExpander(optionsToDisplay.map((option, index) => index === errorIndex ? true : false))
                    refs.current[errorIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
                }
            } else {
                setHasBeenOpen(optionsToDisplay.map((option, index) => false))
                setAccordionsExpander(optionsToDisplay.map((option, index) => index === 0 ? true : false))
                refs.current[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
            }
            setReset(false)
        }
    }, [reset])

    // Record if accordion have been open
    useEffect(() => {
        if (accordionsExpander) {
            const tempArray: boolean[] = [...accordionsExpander]
            setHasBeenOpen(tempArray.map((bool, index) => bool || hasBeenOpen[index]))
        }
    }, [accordionsExpander])

    /**
     * Open/Close accordions
     * @param index 
     * @param bool Optionnal parameter to force only open or close
     */
    const handleAccordion = (indexes: number[], bool?: boolean[]): void => {
        if (accordionsExpander) {
            const tempArray: boolean[] = [...accordionsExpander]

            indexes.forEach((i, loopIndex) => {
                if (i < tempArray.length) {
                    tempArray[i] = (!_.isNil(bool))
                        ? bool[loopIndex]
                        : !tempArray[i]
                }
            })

            setAccordionsExpander(tempArray)
        }
    }

    /**
     * Handle auto scrolling to next option
     * @param index 
     */
    const handleScroll = (index: number) => {
        if (optionsToDisplay && index + 1 < optionsToDisplay.length) {
            refs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        }
    }

    /**
     * Retrieve OptionList object with the refs of options references on selectedProduct
     * @param selectedProduct 
     * @param selectedCatalog 
     */
    const getAvailableOptionsList = (order: Order, selectedProduct: Product, selectedCatalog: CatalogExtended): OptionListExtBO[] => {
        const timezone = getTimezoneName(selectedCatalog);

        const options_by_ref = _.keyBy(selectedCatalog?.data?.options, 'ref');
        const options_list_by_ref = _.keyBy(selectedCatalog?.data?.option_lists, 'ref');

        const option_list_refs = _.find(selectedProduct.skus, sku => !!(sku.option_list_refs && sku.option_list_refs.length))?.option_list_refs;

        const available_options = _.reduce<string, OptionListExtBO[]>(
            option_list_refs,
            (acc, ref) => {
                const option_list = options_list_by_ref[ref];

                if (!option_list) {
                    return acc
                }

                const available = productService.isOptionListAvailable(option_list, order, timezone);

                if (!available) {
                    return acc;
                }

                const option_lines = _.reduce<OptionLine, OptionLineExtBO[]>(
                    option_list.option_lines,
                    (acc, option_line) => {
                        const { option_ref } = option_line;

                        const option = options_by_ref[option_ref];

                        if (!option) {
                            return acc;
                        }

                        acc.push({
                            ...option_line,
                            option
                        })

                        return acc;
                    },
                    []
                )

                if (!option_lines.length) {
                    return acc;
                }

                acc.push({
                    ...option_list,
                    option_lines
                })

                return acc;
            },
            []
        )

        return available_options;
    }

    const displayOptionListError = (optionList: OptionList) => {

        if (!optionsToDisplay) {
            return null
        }

        const invalidOptionList = productService
            .checkOrderOptions(selectedOptions, optionsToDisplay, order, getTimezoneName(selectedCatalog))
            ?.find(opt => opt.ref === optionList.ref);

        if (invalidOptionList && !_.isNil(invalidOptionList.min)) {
            return (
                <Typography variant="body1" color="error">
                    {intl.formatMessage({ id: 'options.min_explanation' }, { min: invalidOptionList.min })}
                </Typography>
            )
        }
    }

    const updateSelectedOptions = useCallback((selectedOptions: OrderOption[]) => {

        let invalidOptionLists: OptionList[] | null = null;
        if (optionsToDisplay) {
            log.debug(`Checking order options`);
            invalidOptionLists = productService.checkOrderOptions(selectedOptions, optionsToDisplay, order, getTimezoneName(selectedCatalog));
        }
        if (onSelectedOptionsChange) {
            onSelectedOptionsChange(selectedOptions, invalidOptionLists)
        }
    }, [onSelectedOptionsChange, optionsToDisplay, order, selectedCatalog]);


    /**
     * Display all selected options as a string
     * @param selectedOptions 
     * @param optionsRef 
     * @returns Options as a string, separated by comas
     */
    const displaySelectedOptions = (selectedOptions: OrderOption[], optionsRef: string): string => {
        const optionsArr = selectedOptions.filter(op => op.option_list_ref === optionsRef)
        const namesArr = optionsArr.map(op => capitalizeFirstLetter(
            intl.formatMessage({
                id: translationService.getOptionNameTranslationKey(op),
                defaultMessage: op.name
            })
        ))
        return namesArr.join(', ')
    }

    /**
     * handle the simple option in order: add a new choice and delete the previous choice
     * @param option 
     */
    const onSelectOption = (input: { option: Option, option_list: OptionList, option_line: OptionLine }) => {
        const { option, option_line, option_list } = input;

        if (!selectedCatalog) {
            return;
        }

        let next_options: OrderOption[] = _.filter(selectedOptions, selectedOption => selectedOption.option_list_ref !== option_list.ref);

        const order_option: OrderOption = {
            option_list_ref: option_list.ref,
            option_list_name: option_list.name,
            ref: option.ref,
            name: option.name,
            price: option_line.price,
        }

        next_options = [...next_options, order_option];

        updateSelectedOptions(next_options)

        const accordionIndex = _.findIndex(optionsToDisplay, opt => opt.ref === option_list.ref);

        if (accordionIndex !== -1) {
            handleAccordion([accordionIndex, accordionIndex + 1], [false, true])
            setTimeout(() => handleScroll(accordionIndex + 1), 700)
        }
    }

    const onSelectOptionMultiple = (input: { option: Option, option_list: OptionList, option_line: OptionLine, quantity: number }) => {
        const { option, option_line, option_list, quantity } = input;

        /**
         * Split into tow groups. 
         * 
         * - First group has all options of the current options_list
         * - Second group has all other options
         */
        const [option_list_options, other] = _.partition(selectedOptions, (order_option) => order_option.option_list_ref === option_list.ref);

        /**
         * Filter out all the options besides the one we are current editing
         */
        const rest_of_options = _.filter(option_list_options, (order_option) => order_option.ref !== option.ref);

        let next_options: OrderOption[] = [...other];

        if (quantity) {
            const order_option: OrderOption = {
                option_list_ref: option_list.ref,
                option_list_name: option_list.name,
                ref: option.ref,
                name: option.name,
                price: option_line.price,
            }

            const order_options = new Array(quantity).fill(order_option);

            const new_option_list_options = [...rest_of_options, ...order_options];

            if (typeof option_list.max === "number" && new_option_list_options.length >= option_list.max) {
                const accordionIndex = _.findIndex(optionsToDisplay, opt => opt.ref === option_list.ref);

                if (accordionIndex !== -1) {
                    handleAccordion([accordionIndex, accordionIndex + 1], [false, true])
                    setTimeout(() => handleScroll(accordionIndex + 1), 700)
                }
            }

            next_options = next_options.concat(new_option_list_options);
        }

        updateSelectedOptions(next_options)
    }

    /**
     * return the default option of all simple optionList 
     * @param optionLists 
     */
    const getDefaultProductOptions = (optionLists: OptionListExtBO[]) => {

        const default_options: OrderOption[] = [];

        _.forEach(optionLists, (option_list) => {

            if (option_list.type !== 'single') {
                return;
            }

            let default_option_line = _.find(option_list.option_lines, option_line => !!(option_line.default && !option_line?.option?.disable));

            if (!default_option_line) {
                default_option_line = _.find(option_list.option_lines, option_line => !option_line?.option?.disable);

                log.info(`No default option found for optionList ${option_list.name} ; using first one (${default_option_line?.option?.name}) instead`);
            }

            if (!default_option_line) {
                log.error(`No option found for optionList ${option_list.name}`);
                return
            }

            const { option, option_ref, price } = default_option_line;

            const orderOption: OrderOption = {
                option_list_ref: option_list.ref,
                option_list_name: option_list.name,
                ref: option_ref,
                name: option?.name ?? "",
                price: price
            };

            default_options.push(orderOption);
        })

        return default_options
    }

    /**
     * Set orderOption the state with the defaults values
     */
    useEffect(() => {
        if (selectedProduct && selectedCatalog && optionsToDisplay) {
            log.debug(`options list with is :`, optionsToDisplay)
            const optionDefault = getDefaultProductOptions(optionsToDisplay);
            log.debug(`optionDefault is :`, optionDefault)
            updateSelectedOptions(optionDefault) // define the option selected
        }
    }, [selectedProduct, selectedCatalog, optionsToDisplay])

    /**
     * Display the message "pick between 2 and 3"
     * @param options 
     * @returns 
     */
    const getOptionMinMaxMessage = (options: OptionList): string | null => {

        if (options.max_before_extra !== undefined) {
            const areFree = options.option_lines.every(ol => moneyToNumber(ol.price) === 0);
            const freeOrReducedTranslation = intl.formatMessage({
                id: areFree ? "options.free" : "options.reduced_price"
            },
                { number: options.max_before_extra, }
            )
            const maxTranslation = options.max ? intl.formatMessage({ id: "options.max" }, { max: options.max }) : "";
            return intl.formatMessage(
                { id: "options.extra_details" },
                {
                    number: options.max_before_extra,
                    type: freeOrReducedTranslation,
                    max: maxTranslation,
                }
            );
        }

        if (options.min && options.max) {
            if (options.min === options.max) {
                return intl.formatMessage({ id: "options.minmax_equal_explanation" }, { max: options.max });
            }
            else {
                return intl.formatMessage({ id: "options.minmax_explanation" }, { min: options.min, max: options.max });
            }
        }
        else if (options.min) {
            return intl.formatMessage({ id: "options.min_explanation" }, { min: options.min });
        }
        else if (options.max) {
            return intl.formatMessage({ id: "options.max_explanation" }, { max: options.max })
        }
        return null;
    }

    if (selectedCatalog && accordionsExpander) {
        return (
            <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                px={2}
            >

                {!!(selectedProduct.name && displayProductName) &&
                    <Box
                        width={1}
                        mt={mobile_device ? 2 : 0}
                        style={mobile_device ? {} : { borderBottom: 'solid 1px #0000001F', }}
                    >
                        <Typography variant="h2" align={mobile_device ? 'left' : 'center'}>
                            {intl.formatMessage({
                                id: translationService.getProductNameTranslationKey(selectedProduct),
                                defaultMessage: selectedProduct.name
                            })}
                        </Typography>
                    </Box>
                }

                {// if selectedProduct.description

                    !!(selectedProduct.description && (mobile_device || forceDescriptionDisplay)) && (
                        <Box
                            width="100%"
                            alignSelf="center"
                            textAlign="left"
                            color={theme.palette.text.disabled}
                            mt={2}
                            bgcolor='background.paper'
                        >
                            <Typography variant="body1">
                                {intl.formatMessage({
                                    id: translationService.getProductDescriptionTranslationKey(selectedProduct),
                                    defaultMessage: selectedProduct.description
                                })}
                            </Typography>
                        </Box>
                    )
                }

                {selectedProduct.tags && selectedProduct.tags.length > 0 &&

                    <Box
                        display="flex"
                        flexWrap="wrap"
                        marginTop={2}
                    >
                        {selectedProduct.tags.map((tag: Tags, index: number) =>
                            <Box key={index} >
                                {tagService.renderTag(tag, theme.palette.secondary.main, true)}
                            </Box>)}
                    </Box>
                }

                {
                    !hideSkuPrice
                    && selectedSku.price
                    && mobile_device
                    &&
                    <Box mt={2}>
                        <Typography
                            color='primary'
                            sx={{
                                textDecoration: selectedProduct.disable ? 'line-through' : 'none',
                            }}
                        >
                            {MoneyToStringWithSymbol(
                                (selectedSku as SkuExtended).reduced_price
                                    ? (selectedSku as SkuExtended).reduced_price as string
                                    : selectedSku.price
                            )}
                        </Typography>
                    </Box>
                }

                {// if product have more than one skus
                    !hideSkuSelection &&
                        selectedSku &&
                        selectedProduct.skus &&
                        !dealSku &&
                        selectedProduct.skus.length > 1 ?
                        (
                            <Box
                                display="flex"
                                mb={2}
                                flexDirection="column"
                                alignItems="center"
                                width='100%'
                                bgcolor='background.paper'
                            >

                                <Accordion style={{ width: '100%' }} expanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        data-test={`accordion-skus-${selectedProduct.ref}`}
                                        style={mobile_device
                                            ? {}
                                            : { backgroundColor: theme.palette.background.default }
                                        }>
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            style={{
                                                alignSelf: "flex-start",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {intl.formatMessage({ id: "Sku" })}
                                        </Typography>
                                    </AccordionSummary>



                                    <AccordionDetails style={!mobile_device ? { width: '90%', margin: 'auto' } : {}}>
                                        <ProductSkuSelect
                                            items={selectedProduct.skus ?? []}
                                            selected={selectedSku}
                                            onChange={setSkuSelected}
                                            readonly={selectedTable.service_type === SupportedServiceType.VIEW}
                                        />
                                    </AccordionDetails>
                                </Accordion>

                            </Box>
                        )
                        :
                        selectedSku && hideSkuSelection ?
                            (
                                <Box display="flex" alignItems="baseline" ml={3} mt={2}>

                                    <Typography variant="h5" style={{ marginRight: theme.spacing(1) }}>

                                        {intl.formatMessage({
                                            id: translationService.getProductNameTranslationKey(selectedProduct),
                                            defaultMessage: selectedProduct.name
                                        })}

                                    </Typography>

                                    <Typography variant="caption">

                                        {intl.formatMessage({
                                            id: translationService.getSkuNameTranslationKey(selectedSku),
                                            defaultMessage: selectedSku.name
                                        })}

                                    </Typography>
                                </Box>
                            )
                            :
                            ""
                }
                <Box
                    display="flex"
                    flexDirection='column'
                    justifyContent='flex-start'
                    width={1}
                >
                    {// if product has options

                        optionsToDisplay && optionsToDisplay.length ?

                            optionsToDisplay.map((optionList: OptionListExtBO, index: number) => {

                                const options_list_selected_options = _.filter(selectedOptions, option => option.option_list_ref === optionList.ref);

                                if (optionList.type === 'single') {
                                    return (
                                        <Box
                                            key={`${optionList.ref}-${index}`}
                                            pb={index + 1 < optionsToDisplay.length
                                                && accordionsExpander
                                                && accordionsExpander[index]
                                                && mobile_device
                                                ? 4
                                                : 0}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            width={1}
                                            bgcolor='background.paper'
                                        >

                                            <Accordion
                                                ref={(element) => { refs.current[index] = element as HTMLDivElement }}
                                                style={{ width: '100%' }}
                                                expanded={accordionsExpander[index]}
                                                onChange={() => handleAccordion([index, index + 1], [!accordionsExpander[index], accordionsExpander[index]])}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    data-test={`accordion-option-list-${optionList.ref}`}
                                                    style={mobile_device
                                                        ? {}
                                                        : { backgroundColor: theme.palette.background.default }
                                                    }>
                                                    <Box display='flex' flexDirection='column' pl={mobile_device ? 0 : 2}>
                                                        <Typography
                                                            variant="h5"
                                                            color="textSecondary"
                                                            style={{
                                                                alignSelf: 'flex-start',
                                                                textTransform: "capitalize",
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: translationService.getOptionListNameTranslationKey(optionList),
                                                                defaultMessage: optionList.name
                                                            })}
                                                        </Typography>

                                                        {hasBeenOpen[index] && !accordionsExpander[index] &&
                                                            displayOptionListError(optionList)
                                                        }
                                                        {!!options_list_selected_options.length &&
                                                            <Typography variant='body1' style={{ color: theme.palette.text.disabled }}>
                                                                {displaySelectedOptions(options_list_selected_options, optionList.ref)}
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </AccordionSummary>

                                                <AccordionDetails style={!mobile_device ? { width: '90%', margin: 'auto' } : {}}>
                                                    <ProductOptionsRadioList
                                                        option_list={optionList}
                                                        selected={options_list_selected_options?.[0]}
                                                        onChange={onSelectOption}
                                                        readonly={selectedTable.service_type === SupportedServiceType.VIEW}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>

                                        </Box>
                                    )
                                }
                                else if (optionList.type === 'multiple') {
                                    return (
                                        <Box
                                            key={index}
                                            pb={index + 1 < optionsToDisplay.length
                                                && accordionsExpander
                                                && accordionsExpander[index]
                                                && mobile_device
                                                ? 4
                                                : 0}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            width={1}
                                            bgcolor='background.paper'
                                        >

                                            <Accordion
                                                ref={(element) => { refs.current[index] = element as HTMLDivElement }}
                                                style={{ width: '100%' }}
                                                expanded={accordionsExpander[index]}
                                                onChange={() => handleAccordion([index, index + 1], [!accordionsExpander[index], accordionsExpander[index]])}
                                            >

                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    data-test={`accordion-option-list-${optionList.ref}`}
                                                    style={mobile_device
                                                        ? {}
                                                        : { backgroundColor: theme.palette.background.default }
                                                    }>
                                                    <Box display='flex' flexDirection='column' pl={mobile_device ? 0 : 2}>
                                                        <Typography
                                                            variant="h5"
                                                            color="textSecondary"
                                                            style={{
                                                                alignSelf: 'flex-start',
                                                                textTransform: "capitalize",
                                                            }}
                                                        >
                                                            {intl.formatMessage({
                                                                id: translationService.getOptionListNameTranslationKey(optionList),
                                                                defaultMessage: optionList.name
                                                            })}
                                                        </Typography>

                                                        {hasBeenOpen[index] && !accordionsExpander[index] &&
                                                            displayOptionListError(optionList)
                                                        }

                                                        {!!options_list_selected_options.length &&
                                                            <Typography variant='body1' style={{ color: theme.palette.text.disabled }}>
                                                                {displaySelectedOptions(options_list_selected_options, optionList.ref)}
                                                            </Typography>
                                                        }

                                                        {(optionList.min || optionList.max) && accordionsExpander[index] &&
                                                            <Typography variant="body1" style={{ marginTop: theme.spacing(1) }}>
                                                                {getOptionMinMaxMessage(optionList)}
                                                            </Typography>
                                                        }

                                                    </Box>
                                                </AccordionSummary>


                                                <AccordionDetails style={!mobile_device ? { width: '90%', margin: 'auto' } : {}}>
                                                    <ProductOptionsCheckBox
                                                        key={index}
                                                        option_list={optionList}
                                                        readonly={selectedTable.service_type === SupportedServiceType.VIEW}
                                                        selected={options_list_selected_options}
                                                        onChange={onSelectOptionMultiple}
                                                    />
                                                </AccordionDetails>

                                            </Accordion>
                                        </Box>
                                    );
                                }
                                else {

                                    log.error(`Option ${optionList.name} can't be displayed because type = ${optionList.type}`)
                                    return null;
                                }
                            })

                            : ""
                    }
                </Box>
            </Box >
        )
    }
    return null
}