import { DateTime } from "luxon";

export const CORRELATION_ID_HEADER = 'x-correlation-id';
export const TRACE_CONTEXT_HEADER = 'X-Cloud-Trace-Context';

export const levels = ["debug", "info", "warn", "error"] as const;

export type LogLevel = typeof levels[number];

export interface Logger {
    /**
    * Output debug message to console including appropriate icons
    *
    * @param msg any data to log to the console
    */
    debug(...msg: any[]): void;

    /**
     * Output info message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    info(...msg: any[]): void;

    /**
     * Output warn message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    warn(...msg: any[]): void;

    /**
     * Output error message to console including appropriate icons
     *
     * @param msg any data to log to the console
     */
    error(...msg: any[]): void;
}

let loggerInstance: Logger;
export const setLoggerInstance = (logger: Logger) => {
    loggerInstance = logger;
}

export const getErrorMessage = (error: unknown) => {
    if (error instanceof Error) {
        return error.message;
    }
    return "Unknown error";
}

export const getErrorStack = (error: unknown) => {
    if (error instanceof Error) {
        return error.stack;
    }
    return "";
}

export interface LoggerOptions {
    /**
     * Disable logger complexly
     */
    disabled?: boolean

    /**
     * Only store logs above this level 
     */
    level?: LogLevel
}

export function createLogger(options: LoggerOptions = {}): Logger {
    const { disabled = false, level = "debug" } = options;

    const max_level_index = levels.indexOf(level);

    function isLevelEnabled(level: LogLevel) {
        const level_index = levels.indexOf(level);

        return level_index >= max_level_index;
    }

    const instance: Logger = {
        debug(...msg: any[]) {
            if (!loggerInstance) return;

            if (disabled) return;
            if (!isLevelEnabled("debug")) return;

            loggerInstance.debug(...msg);
        },

        info(...msg: any[]) {
            if (!loggerInstance) return;

            if (disabled) return;
            if (!isLevelEnabled("debug")) return;

            loggerInstance.info(...msg);
        },

        warn(...msg: any[]) {
            if (!loggerInstance) return;

            if (disabled) return;
            if (!isLevelEnabled("debug")) return;

            loggerInstance.warn(...msg);
        },

        error(...msg: any[]) {
            if (!loggerInstance) return;

            if (disabled) return;
            if (!isLevelEnabled("debug")) return;

            loggerInstance.error(...msg);
        }
    }

    return instance;
}

export const log: Logger = createLogger();

export const getLogConsoleUrl = (projectId: string, query: string, startDate?: Date, hoursDuration: number = 4) => {
    let logConsoleUrl = `https://console.cloud.google.com/logs/query;query=${encodeURI(query)}`
    if (startDate) {
        const startDateTime = DateTime.fromJSDate(startDate).minus({ minute: 10 });
        const endDateTime = startDateTime.plus({ hours: hoursDuration })
        const timeRange = `timeRange=${startDateTime.toJSDate().toISOString()}%2F${endDateTime.toJSDate().toISOString()}`
        logConsoleUrl = `${logConsoleUrl};${timeRange}`;
    }
    logConsoleUrl = `${logConsoleUrl}?project=${projectId}`;
    return logConsoleUrl;
}




