import { getMylemonadeContext } from '../../Common/services/LogService';
import { ThemeList } from '../../my-lemonade-library/src/theme/models/ThemesList';
import LocationState from '../models/LocationState';
import { AUTHENTICATION, GET_DISCOUNT_REF_FROM_URL, LOAD_LOCATION, LOAD_LOCATION_ERROR, LOAD_LOCATION_SUCCESS, LocationActionType, OPEN_MODAL_MULTI_USERS_ORDER, REDIRECT, RELOAD_CATALOG, RELOAD_CATALOG_SUCCESS, SET_CATALOG, SET_INVALID_SERVICE_TYPE, SET_SESSION_ID, SET_TABLE, SET_TABLE_SERVICE_TYPE } from './LocationActions';

const mainState: LocationState = {
    isLoading: false,
    isLoaded: false,
    errorMessages: undefined,
    fromApp: undefined,
    selectedLocation: undefined,
    selectedCatalog: undefined,
    tableLinkId: "",
    selectedTable: {
        id: "",
        name: "",
        ref: "",
        tablelink_id: "",
        location_id: "",
        account_id: ""
    },
    themeName: ThemeList.DEFAULT,
    token: undefined,
    openModalMultiUsersOrder: false,
    invalidServiceType: false,
};

export default function reducer(
    state = mainState,
    action: LocationActionType,
): LocationState {
    switch (action.type) {
        case RELOAD_CATALOG:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case RELOAD_CATALOG_SUCCESS:
            return {
                ...state,
                selectedCatalog: action.payload,
                isLoading: false,
                isLoaded: true
            }
        case LOAD_LOCATION:
            return {
                ...state,
                isLoading: true,
                errorMessages: undefined,
            };
        case LOAD_LOCATION_SUCCESS:
            const updatedState: LocationState = {
                ...state,
                isLoading: false,
                fromApp: action.payload.fromApp,
                token: action.payload.token,
                selectedLocation: action.payload.location,
                selectedCatalog: action.payload.catalog,
                tableLinkId: action.payload.tableLinkId,
                isLoaded: true,
                errorMessages: undefined,
            };
            // update the table only if has not been already set
            // Reason: can have been already set by loading the orders from a receipt and retrieving a different table
            if ((!updatedState.selectedTable || !updatedState.selectedTable.ref) && action.payload.selectedTable) {
                updatedState.selectedTable = action.payload.selectedTable ? action.payload.selectedTable : updatedState.selectedTable;
            }
            return updatedState;
        case LOAD_LOCATION_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessages: action.payload.error,
            };
        case REDIRECT:
            return {
                ...state,
                redirect_url: action.payload.url,
            };
        case AUTHENTICATION:
            return {
                ...state,
            };
        case SET_TABLE:

            // Set the context for remote logs
            getMylemonadeContext().service_type = action.payload.service_type;

            return {
                ...state,
                selectedTable: action.payload
            };
        case OPEN_MODAL_MULTI_USERS_ORDER:
            return {
                ...state,
                openModalMultiUsersOrder: action.payload.open,
            };

        case SET_TABLE_SERVICE_TYPE:

            // Set the context for remote logs
            getMylemonadeContext().service_type = action.payload.serviceType ?? undefined;

            return {
                ...state,
                selectedTable: {
                    ...state.selectedTable,
                    service_type: action.payload.serviceType ?? undefined,
                }
            };

        case SET_INVALID_SERVICE_TYPE:
            return {
                ...state,
                invalidServiceType: action.payload,
            }

        case SET_CATALOG:
            return {
                ...state,
                selectedCatalog: action.payload.catalog
            }

        case SET_SESSION_ID:
            return {
                ...state,
                sessionId: action.payload.session_id,
            }

        case GET_DISCOUNT_REF_FROM_URL: {
            return {
                ...state,
                discount_ref_from_url: action.payload.discountRef,
            };
        }

        default:
            return state;
    }
}

