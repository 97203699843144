import { Catalog, Categorie, Deal, DealSku, DEFAULT_LANGUAGE, Option, OptionList, Product, Sku } from "../../../model/Catalog";
import { appTitles } from "../models/AppTitleTranslations";
import CatalogTranslations, { CategoryTranslations, DealTranslations, OptionListTranslations, OptionTranslations, ProductTranslations, SkuTranslations } from "../models/CatalogTranslations";
import Translation, { TranslationSource } from "../models/Translation";
import TranslationsMap from "../models/TranslationsMap";

class TranslationService {
    /**
     * Get the key identifying the product name translation
     * @param product
     */
    getProductNameTranslationKey(product: Product): string | undefined {
        if (product) {
            return this.getProductNameTranslationKeyFromRef(product.ref);
        }
        return;
    }

    /**
     * Get the key identifying the product name translation
     * @param product
     */
    getProductNameTranslationKeyFromRef(ref: string): string | undefined {
        if (ref) {
            return `product_name_${ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the product description translation
     * @param product
     */
    getProductDescriptionTranslationKey(product: Product): string | undefined {
        if (product) {
            return `product_description_${product.ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the sku name translation
     * @param product
     */
    getSkuNameTranslationKey(sku: Sku | DealSku): string | undefined {
        if (sku) {
            return `sku_name_${sku.ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the category name translation
     * @param product
     */
    getCategorytNameTranslationKey(
        category: Categorie | undefined
    ): string | undefined {
        if (category) {
            return this.getCategoryNameTranslationKeyFromRef(category.ref);
        }
        return;
    }

    getCategoryNameTranslationKeyFromRef(ref: string): string | undefined {
        if (ref) {
            return `category_name_${ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the option name translation
     * @param product
     */
    getOptionNameTranslationKey(option: Option): string | undefined {
        if (option) {
            return `option_name_${option.ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the option list name translation
     * @param product
     */
    getOptionListNameTranslationKey(optionList: OptionList): string | undefined {
        if (optionList) {
            return `option_list_name_${optionList.ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the deal name translation
     * @param product
     */
    getDealNameTranslationKey(deal: Deal): string | undefined {
        if (deal) {
            return `deal_name_${deal.ref}`;
        }
        return;
    }

    /**
     * Get the key identifying the product description translation
     * @param product
     */
    getDealDescriptionTranslationKey(deal: Deal): string | undefined {
        if (deal) {
            return `deal_description_${deal.ref}`;
        }
        return;
    }

    /**
     * Get the default map of all names for the default catalog language
     * @param catalog
     */
    getDefaultMap(catalog: Catalog): { [key: string]: string } {
        const translations: { [key: string]: string } = {};
        if (catalog.data) {
            if (catalog.data.products) {
                catalog.data.products.forEach((product) => {
                    const productNameKey = this.getProductNameTranslationKey(product);
                    if (productNameKey) {
                        translations[productNameKey] = product.name;
                    }
                    const productDescKey = this.getProductDescriptionTranslationKey(
                        product
                    );
                    if (productDescKey && product.description) {
                        translations[productDescKey] = product.description;
                    }
                    if (product.skus) {
                        product.skus.forEach((sku) => {
                            const skuNameKey = this.getSkuNameTranslationKey(sku);
                            if (skuNameKey) {
                                translations[skuNameKey] = sku.name;
                            }
                        });
                    }
                });
            }
            if (catalog.data.deals) {
                catalog.data.deals.forEach((deal) => {
                    const dealNameKey = this.getDealNameTranslationKey(deal);
                    if (dealNameKey) {
                        translations[dealNameKey] = deal.name;
                    }
                });
            }
            if (catalog.data.categories) {
                catalog.data.categories.forEach((category) => {
                    const catNameKey = this.getCategorytNameTranslationKey(category);
                    if (catNameKey) {
                        translations[catNameKey] = category.name;
                    }
                });
            }

            if (catalog.data.option_lists) {
                catalog.data.option_lists.forEach((optionList) => {
                    const optionListNameKey = this.getOptionListNameTranslationKey(
                        optionList
                    );
                    if (optionListNameKey) {
                        translations[optionListNameKey] = optionList.name;
                    }
                });
            }

            catalog.data.options?.forEach((option) => {
                const optionNameKey = this.getOptionNameTranslationKey(option);
                if (optionNameKey) {
                    translations[optionNameKey] = option.name;
                }
            })
        }

        return translations;
    }

    /**
     * Create empty CatalogTranslations
     * @param catalog is catalog working on
     * @param localCatalogTranslations : local catalog translation from parent
     */
    initCatalogTranslations(
        catalog: Catalog
    ): CatalogTranslations {
        let catalogTranslations: CatalogTranslations = {
            ...catalog,
            data: {
                ...catalog.data,
                hierarchical_categories: [],
                translated_deals: [],
                option_lists: [],
                options: [],
            },
        };
        return catalogTranslations;
    }

    /**
     * function use to create translation map
     * @param keyName key used for translate
     * @param catalogLanguage Catalog default language
     * @param catalogValue Catalog default language value (i.e original product name)
     * @param source source from translation
     * @param translations map of translation
     */
    createTranslationsMap(
        keyName: string,
        catalogLanguage: string,
        catalogValue: string | undefined,
        translations: { [key: string]: TranslationsMap },
    ): { [key: string]: Translation } | undefined {
        let translationsMap: { [key: string]: Translation } = {};
        const languages: string[] = Object.keys(translations);

        if (catalogValue) {
            // Add translation for each language
            languages.forEach((lang) => {

                const translation = translations[lang][keyName];

                if (translation) {
                    // Force to set the id
                    translation.id = keyName;
                    // Add it to the map
                    translationsMap[lang] = translation;
                }
            });

            // Add original catalog value
            translationsMap[catalogLanguage] = {
                id: keyName,
                translation: catalogValue,
                source: TranslationSource.CATALOG_LANGUAGE,
            }

            return translationsMap;
        }
        return undefined;
    }

    /**
     * Get all the translations for the catalog
     * @param catalog
     */
    getCatalogTranslations(
        catalog: Catalog,
        translations: { [key: string]: TranslationsMap }
    ): CatalogTranslations {

        if (!catalog.language) {
            catalog.language = DEFAULT_LANGUAGE;
        }

        const catalogTranslations: CatalogTranslations = this.initCatalogTranslations(catalog);

        const categoriesWithTranslationByRef: { [key: string]: CategoryTranslations } = {}

        if (catalog.data) {
            if (catalog.data.deals) {
                // DEALS
                let dealList: Deal[] = [];
                if (catalog && catalog.data && catalog.data.deals && catalog.data.deals.length > 0) {
                    dealList = catalog.data.deals;
                }

                if (dealList) {
                    dealList.forEach((deal) => {
                        const dealNameRef: string | undefined = this.getDealNameTranslationKey(deal);
                        const dealDescriptionRef: string | undefined = this.getDealDescriptionTranslationKey(deal);

                        if (dealNameRef) {
                            let dealNameTranslations = this.createTranslationsMap(
                                dealNameRef,
                                catalog.language,
                                deal.name,
                                translations
                            );

                            let dealDescriptionTranslations = this.createTranslationsMap(
                                dealDescriptionRef!,
                                catalog.language,
                                deal.description,
                                translations
                            );

                            if (dealNameTranslations) {
                                const newDealTranslation: DealTranslations = {
                                    ...deal,
                                    name_translations: dealNameTranslations,
                                    description_translations: dealDescriptionTranslations!,
                                };

                                catalogTranslations.data.translated_deals.push(newDealTranslation);
                            }
                        }
                    });
                }
            }

            if (catalog.data.categories) {
                // CATEGORY

                const rootCategories = catalog.data.categories.filter(
                    (cat) => !cat.parent_ref
                );

                rootCategories.forEach((category, index) => {
                    const catTranslationRef:
                        | string
                        | undefined = this.getCategorytNameTranslationKey(category);

                    // Save as category
                    if (catTranslationRef) {
                        // creater translationName
                        const categoryTranslationsMap = this.createTranslationsMap(
                            catTranslationRef,
                            catalog.language,
                            category.name,
                            translations
                        );

                        if (categoryTranslationsMap) {

                            // Recreate it
                            const newCategoryTranslation: CategoryTranslations = {
                                ...category,
                                name_translations: categoryTranslationsMap,
                            };
                            delete newCategoryTranslation.products;
                            delete newCategoryTranslation.subcategories;

                            catalogTranslations.data.hierarchical_categories.push(
                                newCategoryTranslation
                            );

                            // Add it to a map of categoryTranslations
                            categoriesWithTranslationByRef[category.ref] = newCategoryTranslation;
                        }
                    }
                });

                // SUB CATEGORY
                const childCategories = catalog.data.categories.filter(
                    (cat) => cat.parent_ref
                );

                childCategories.forEach((category, index) => {
                    // Retrieve cat name from cat ref
                    const subCatTranslationRef:
                        | string
                        | undefined = this.getCategorytNameTranslationKey(category);

                    // Retrieve subcategory ref from parent ref
                    const catParentRef: string | undefined = category.parent_ref;

                    // Save as subcategory
                    if (subCatTranslationRef && catParentRef) {
                        const subCategoryTranslationsMap = this.createTranslationsMap(
                            subCatTranslationRef,
                            catalog.language,
                            category.name,
                            translations
                        );

                        const parentCategoryTranslation = categoriesWithTranslationByRef[catParentRef];

                        if (parentCategoryTranslation && subCategoryTranslationsMap) {
                            const newSubCategoryTranslation: CategoryTranslations = {
                                ...category,
                                name_translations: subCategoryTranslationsMap,
                            };
                            delete newSubCategoryTranslation.products;
                            delete newSubCategoryTranslation.subcategories;

                            if (!parentCategoryTranslation.subcategories) {
                                parentCategoryTranslation.subcategories = [];
                            }
                            parentCategoryTranslation.subcategories.push(newSubCategoryTranslation);

                            // Add it to a map of categoryTranslations
                            categoriesWithTranslationByRef[category.ref] = newSubCategoryTranslation;
                        }
                    }
                });

                // PRODUCTS
                let productList: Product[] = [];
                if (catalog && catalog.data && catalog.data.products && catalog.data.products.length > 0) {
                    productList = catalog.data.products;
                }

                if (productList) {
                    productList.forEach((product) => {
                        const productNameRef: string | undefined = this.getProductNameTranslationKey(product);
                        const productDescritpionRef: string | undefined = this.getProductDescriptionTranslationKey(product);

                        const productCategoryRef = product.category_ref;
                        const productCategory: CategoryTranslations | undefined = categoriesWithTranslationByRef[productCategoryRef];

                        if (productNameRef && productCategory && productCategoryRef) {
                            let productNameTranslations = this.createTranslationsMap(
                                productNameRef,
                                catalog.language,
                                product.name,
                                translations
                            );

                            let productDescritpionTranslation = this.createTranslationsMap(
                                productDescritpionRef!,
                                catalog.language,
                                product.description,
                                translations
                            );

                            if (productNameTranslations) {

                                const newProductTranslation: ProductTranslations = {
                                    ...product,
                                    name_translations: productNameTranslations,
                                    description_translations: productDescritpionTranslation!,
                                    skus: [],
                                };

                                // SKU
                                let skuTranslationList: SkuTranslations[] = [];

                                if (product.skus.length > 0) {
                                    product.skus.forEach((sku) => {
                                        let skuTranslation: SkuTranslations = {
                                            ...sku,
                                            name_translations: {},
                                        };
                                        const productSkuRef:
                                            | string
                                            | undefined = this.getSkuNameTranslationKey(sku);

                                        if (productSkuRef) {
                                            let skuNameTranslation = this.createTranslationsMap(
                                                productSkuRef,
                                                catalog.language,
                                                sku.name,
                                                translations
                                            );

                                            if (skuNameTranslation) {
                                                skuTranslation.name_translations = skuNameTranslation;
                                                skuTranslationList.push(skuTranslation);
                                            } else {
                                                console.error(`no skuNameTranslation for ${sku} `);
                                            }
                                        } else {
                                            console.error(`error, productSkuRef => ${productSkuRef}`);
                                        }
                                    });
                                }

                                newProductTranslation.skus = skuTranslationList;

                                // SKU END

                                // Add it to the list of products
                                if (!productCategory.products) {
                                    productCategory.products = []
                                }
                                productCategory.products?.push(newProductTranslation);
                            }
                        } else {
                            console.error(
                                `One of this are falsy => productNameRef = ${productNameRef} / productCategory = ${productCategory} / productCategoryRef = ${productCategoryRef} `
                            );
                        }
                    });
                }
            }

            // OPTIONS LIST
            if (catalog.data.option_lists) {

                let optionListTranslations: OptionListTranslations;
                let optionLists: OptionList[] = [];

                if (catalog.data.option_lists.length > 0)
                    optionLists = catalog.data.option_lists;

                if (optionLists) {
                    optionLists.forEach((optionList) => {
                        const optionListNameRef:
                            | string
                            | undefined = this.getOptionListNameTranslationKey(optionList);

                        // Name of option list

                        if (optionListNameRef) {
                            optionListTranslations = {
                                ...optionList,
                                name_translations: {},
                            };

                            let optionListNameTranslations = this.createTranslationsMap(
                                optionListNameRef,
                                catalog.language,
                                optionList.name,
                                translations
                            );

                            if (optionListNameTranslations) {
                                optionListTranslations.name_translations = optionListNameTranslations;

                                catalogTranslations.data.option_lists.push(
                                    optionListTranslations
                                );
                            } else {
                                console.error(
                                    ` optionListNameRef is falsy = ${optionListNameRef} `
                                );
                            }
                        }
                    });
                }
            }

            // OPTION
            let optionTranslation: OptionTranslations;
            catalog.data.options.forEach((option) => {
                const optionNameRef:
                    | string
                    | undefined = this.getOptionNameTranslationKey(option);

                if (optionNameRef) {
                    optionTranslation = {
                        ...option,
                        name_translations: {},
                    };
                    let optionNameTranslations = this.createTranslationsMap(
                        optionNameRef,
                        catalog.language,
                        option.name,
                        translations
                    );

                    if (optionNameTranslations) {
                        optionTranslation.name_translations = optionNameTranslations;
                        catalogTranslations.data.options.push(
                            optionTranslation
                        );
                    }
                }
            });
        }

        return catalogTranslations;
    }

    getAppTitleTranslation(deploymentName?: string): string {
        const title = appTitles[`${deploymentName}_title`];

        return title || 'Fyre App';
    }

    getAppDescriptionTranslation(deploymentName?: string): string {
        const title = appTitles[`powered_by_${deploymentName}`];

        return title || 'Powered by Fyre';
    }
}
const translationService = new TranslationService();
export default translationService;