export enum CategoryReferenceLevel2 {
    // Adult beverages
    BEER_CIDERS = "Beers/Ciders",
    CHAMPAGNE_SPARKLING_WINE = "Champagne & Sparkling Wine",
    SPIRITS = "Spirits",
    WINE = "Wine",

    // Non-alcoholic beverages
    COFFEE_BASED = "Coffee-Based",
    MILK_BASED = "Milk-Based",
    NON_ALCOHOLIC_READY_TO_DRINK = "NARTD (Non-Alcoholic Ready To Drink)",
    PLANT_AND_NUT_BASED = "Plant & Nut Based Milk Alternatives",
    SQUEEZED_JUICES = "Squeezed Juices",
    SYRUP_AND_FRUIT_CONCENTRATE = "Syrup & Fruit Concentrate",
    TEA_BASED = "Tea-Based",
    WATERS = "Waters",

    /**
     * Plant & Nut - based Milk Alternatives
     */

    // Hot beverages
    COFFEE = "Coffee",
    GROG = "Grog",
    TEA = "Tea",
}

// export const categoryReferenceLevel2Keywords: { [key in CategoryReferenceLevel2]: string[] } = {
//     [CategoryReferenceLevel2.BEER_CIDERS]: ["beer", "ciders"],
//     [CategoryReferenceLevel2.WINE]: ["wine"],
//     [CategoryReferenceLevel2.SPIRITS]: ["spirits"],
//     [CategoryReferenceLevel2.CHAMPAGNE_SPARKLING_WINE]: ["champagne", "sparkling wine"],
//     [CategoryReferenceLevel2.MILK_BASED]: ["milk"],
//     [CategoryReferenceLevel2.WATERS]: ["water"],
//     [CategoryReferenceLevel2.COFFEE_BASED]: ["coffee"],
//     [CategoryReferenceLevel2.SYRUP_AND_FRUIT_CONCENTRATE]: ["syrup", "fruit concentrate"],
//     [CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK]: ["non alcoholic ready to drink", "nartd"],
//     [CategoryReferenceLevel2.SQUEEZED_JUICES]: ["squeezed juices"],
//     [CategoryReferenceLevel2.PLANT_AND_NUT_BASED]: ["plant", "nut"],
//     [CategoryReferenceLevel2.COFFEE]: ["coffee"],
//     [CategoryReferenceLevel2.TEA]: ["tea"],
//     [CategoryReferenceLevel2.HOT_MILK_BASED]: ["hot milk"],
// }

export const category2_options = Object.values(CategoryReferenceLevel2);
