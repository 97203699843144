export enum DiscountInvalidity {
    INVALID_COUPON_CODE = "invalid_coupon_code",
    DISCOUNT_RESTRICTION_NOT_MET = "discount_restriction_not_met",
    DISCOUNT_NOT_FOUND = "discount_not_found",
    DISCOUNT_MAX_PER_CUSTOMER_USAGE_LIMIT = "discount_max_per_customer_usage_limit",
    DISCOUNT_MAX_PER_ORDER_USAGE_LIMIT = "discount_max_per_order_usage_limit",
    CONTRIBUTOR_NOT_FOUND = "CONTRIBUTOR_NOT_FOUND",
    DISCOUNT_MIN_ORDER_AMOUNT_NOT_REACHED = "discount_min_order_amount_not_reached",
    DISCOUNT_START_DATE_NOT_RESPECTED = "discount_start_date_not_respected",
    DISCOUNT_END_DATE_NOT_RESPECTED = "discount_end_date_not_respected",
    DISCOUNT_END_TIME_NOT_RESPECTED = "discount_end_time_not_respected",
    DISCOUNT_START_TIME_NOT_RESPECTED = "discount_start_time_not_respected",
    DISCOUNT_DOW_NOT_RESPECTED = "discount_dow_not_respected",
    DISCOUNT_SERVICE_TYPE_NOT_RESPECTED = "discount_service_type_not_respected",
    DISCOUNT_RESTRICTIONS_NOT_RESPECTED = "discount_restrictions_not_respected",
    DISCOUNT_INVALID_EMAIL_DOMAIN_OR_AUTHENTICATION_PROVIDER = "discount_invalid_email_domain_or_authentication_provider",
    DISCOUNT_TABLE_AREA_NOT_RESPECTED = "discount_zone_area_not_respected",
}