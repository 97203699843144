import { MyLemonadeApplications } from "../../../model/Common/MyLemonadeApplications";
import { DeploymentName } from "../models/DeploymentInfo";
import MylemonadeApplicationUrl from "../models/MylemonadeApplicationUrl";
import { EnvNames } from "./EnvNames";

// TODO: build a microservice catalog / microservice discovery stored on firestore


/////////////////
// WEBAPP
/////////////////

export const WEBAPP_URLS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://order.fyre.app"
    },
    {
        deployment: DeploymentName.LYF,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://lyf.fyre.app"
    },
    {
        deployment: DeploymentName.MY_BAGUETTE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://mybaguette.fyre.app"
    },
    {
        deployment: DeploymentName.GET_RESTO,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://orderapp.get-resto.com"
    },
    {
        deployment: DeploymentName.EASYCOM,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://mae.fyre.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://app.mylemonade.fr"
    },
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://alleatone-preprod.web.app"
    },
    {
        deployment: DeploymentName.LYF,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://lyf-preprod.web.app"
    },
    {
        deployment: DeploymentName.MY_BAGUETTE,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://mybaguette-preprod.web.app"
    },
    {
        deployment: DeploymentName.GET_RESTO,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://getresto-preprod.web.app"
    },
    {
        deployment: DeploymentName.EASYCOM,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://easycom-preprod.web.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://my-lemonade-preprod.web.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.WEBAPP,
        url: "https://my-lemonade-dev.web.app"
    }
]

export function getWebAppUrl(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = WEBAPP_URLS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = WEBAPP_URLS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}


/////////////////
// BACKOFFICE
/////////////////

export const BACKOFFICE_URLS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://bo.fyre.app"
    },
    {
        deployment: DeploymentName.LYF,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://bolyf.fyre.app"
    },
    {
        deployment: DeploymentName.MY_BAGUETTE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://mybo.fyre.app"
    },
    {
        deployment: DeploymentName.GET_RESTO,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://orderbo.get-resto.com"
    },
    {
        deployment: DeploymentName.EASYCOM,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://bomae.fyre.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://bo.mylemonade.fr"
    },
    {
        deployment: DeploymentName.EASYCOM,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://easycom-backoffice-preprod.web.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://my-lemonade-backoffice-preprod.web.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.BACKOFFICE,
        url: "https://my-lemonade-backoffice-dev.web.app"
    }
]

export function getBoUrl(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = BACKOFFICE_URLS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = BACKOFFICE_URLS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}


/////////////////
// ORDERING API
/////////////////

export const API_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://api.fyre.app"
    },
    {
        deployment: DeploymentName.LYF,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://lyfpay.fyre.app"
    },
    {
        deployment: DeploymentName.MY_BAGUETTE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://bakery.fyre.app"
    },
    {
        deployment: DeploymentName.GET_RESTO,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://api.fyre.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://api.fyre.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.API,
        url: "https://my-lemonade-api-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.API,
        url: "https://my-lemonade-api-jdgyqpjica-ew.a.run.app"
    }
]

export function getApiHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = API_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = API_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }
    return foundApplicationUrl?.url;
}

export function getApiUrl(env: EnvNames, deployment: DeploymentName | null) {
    return `${getApiHost(env, deployment)}/api/v1`;
}


/////////////////
// PRINTER RECEIPT API
/////////////////

export const PRINTER_RECEIPT_URLS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.PRINTER_RECEIPT_API,
        url: "https://printer-receipt-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.PRINTER_RECEIPT_API,
        url: "https://printer-receipt-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.PRINTER_RECEIPT_API,
        url: "https://printer-receipt-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.PRINTER_RECEIPT_API,
        url: "https://printer-receipt-jdgyqpjica-ew.a.run.app"
    }
]

export function getPrinterReceiptHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = PRINTER_RECEIPT_URLS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = PRINTER_RECEIPT_URLS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }
    return foundApplicationUrl?.url;
}


/////////////////
// PRINTERS SERVICE (LINKED to ORDERING-API)
/////////////////

export const PRINTERS_SERVICE_URLS: MylemonadeApplicationUrl[] = [
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.PRINTERS_SERVICE,
        url: "https://printers-service-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.PRINTERS_SERVICE,
        url: "https://printers-service-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.PRINTERS_SERVICE,
        url: "https://printers-service-jdgyqpjica-ew.a.run.app"
    }
]

export function getPrintersServiceHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = PRINTERS_SERVICE_URLS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = PRINTERS_SERVICE_URLS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }
    return foundApplicationUrl?.url;
}


/////////////////
// SHORTLINKS
/////////////////

export const SHORTLINK_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.API,
        url: "https://mylemonade.page.link"
    },

    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: ""
    },

    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.API,
        url: ""
    },
]

export function getShortlinkDomainUriPrefix(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = SHORTLINK_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = SHORTLINK_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}


/////////////////
// QR CODE REDIRECT
/////////////////

export const QR_CODE_REDIRECT_URLS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://pay.fyre.app"
    },
    {
        deployment: DeploymentName.LYF,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://lyfpay.fyre.app"
    },
    {
        deployment: DeploymentName.MY_BAGUETTE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://bakery.fyre.app"
    },
    {
        deployment: DeploymentName.GET_RESTO,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://link.order.get-resto.com"
    },
    {
        deployment: DeploymentName.EASYCOM,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://mae-link.fyre.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.API,
        url: "https://api.mylemonade.fr"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.API,
        url: getApiHost(EnvNames.PREPROD, null) ?? "",
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.API,
        url: getApiHost(EnvNames.DEV, null) ?? "",
    }
];

export function getQrCodeRedirectApiUrl(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = QR_CODE_REDIRECT_URLS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = QR_CODE_REDIRECT_URLS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}



/////////////////
// BRANDS API
/////////////////

export const BRANDS_API_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BRANDS_API,
        url: "https://my-lemonade-brands-api-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BRANDS_API,
        url: "https://my-lemonade-brands-api-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.BRANDS_API,
        url: "https://my-lemonade-brands-api-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.BRANDS_API,
        url: "https://my-lemonade-brands-api-jdgyqpjica-ew.a.run.app"
    }
]

export function getBrandsApiHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = BRANDS_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = BRANDS_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}

export function getBrandsApiUrl(env: EnvNames, deployment: DeploymentName | null) {

    return `${getBrandsApiHost(env, deployment)}/api/v1`;
}


/////////////////
// ORDERS IMPORTER (linked to ORDERING-API)
/////////////////

export const ORDERS_IMPORTER_API_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.ORDERS_IMPORTER,
        url: "https://orders-importer-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.ORDERS_IMPORTER,
        url: "https://orders-importer-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.ORDERS_IMPORTER,
        url: "https://orders-importer-jdgyqpjica-ew.a.run.app"
    }
]

export function getOrdersImporterApiHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = ORDERS_IMPORTER_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = ORDERS_IMPORTER_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}

export function getOrdersImporterApiUrl(env: EnvNames, deployment: DeploymentName | null) {
    return `${getOrdersImporterApiHost(env, deployment)}/api/v1`;
}

/////////////////
// BRANDS BACKOFFICE
/////////////////

export const BRANDS_BACKOFFICE_URLS: MylemonadeApplicationUrl[] = [
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BRANDS_BACKOFFICE,
        url: "https://my-lemonade-brands-backoffice.web.app/"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.BRANDS_BACKOFFICE,
        url: "https://my-lemonade-brands-backoffice-preprod.web.app/"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.BRANDS_BACKOFFICE,
        url: "https://my-lemonade-brands-backoffice-dev.web.app/"
    }
]

export function getBrandsBoUrl(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = BRANDS_BACKOFFICE_URLS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = BRANDS_BACKOFFICE_URLS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}


/////////////////
// SCRAPING API
/////////////////
export const SCRAPING_API_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.SCRAPING_API,
        url: "https://my-lemonade-scraping-api-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.SCRAPING_API,
        url: "https://my-lemonade-scraping-api-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.SCRAPING_API,
        url: "https://my-lemonade-scraping-api-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.SCRAPING_API,
        url: "https://my-lemonade-scraping-api-jdgyqpjica-ew.a.run.app"
    }
]

export function getScrapingApiHost(env: EnvNames, deployment: DeploymentName | null) {
    let foundApplicationUrl = SCRAPING_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = SCRAPING_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}

export function getScrapingApiUrl(env: EnvNames, deployment: DeploymentName | null) {
    return `${getScrapingApiHost(env, deployment)}/api/v1`;
}


/////////////////
// BIGQUERY ELT
/////////////////

export const BIGQUERY_ELT_API_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BIGQUERY_ELT_API,
        url: "https://my-lemonade-bigquery-elt-api-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.BIGQUERY_ELT_API,
        url: "https://my-lemonade-bigquery-elt-api-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.BIGQUERY_ELT_API,
        url: "https://my-lemonade-bigquery-elt-api-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.BIGQUERY_ELT_API,
        url: "https://my-lemonade-bigquery-elt-api-jdgyqpjica-ew.a.run.app"
    }
]

export function getBigQueryEltApiHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = BIGQUERY_ELT_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = BIGQUERY_ELT_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}

export function getBigQueryEltApiUrl(env: EnvNames, deployment: DeploymentName | null) {

    return `${getBigQueryEltApiHost(env, deployment)}/api/v1`;
}


/////////////////
// CHALLENGE UPDATER
/////////////////

export const CHALLENGE_UPDATER_API_HOSTS: MylemonadeApplicationUrl[] = [
    {
        deployment: DeploymentName.ALL_EAT_ONE,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.CHALLENGE_UPDATER,
        url: "https://challenge-updater-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PROD,
        application: MyLemonadeApplications.CHALLENGE_UPDATER,
        url: "https://challenge-updater-6anzqcoq6q-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.PREPROD,
        application: MyLemonadeApplications.CHALLENGE_UPDATER,
        url: "https://challenge-updater-oysoenfzxq-ew.a.run.app"
    },
    {
        deployment: null,
        environment: EnvNames.DEV,
        application: MyLemonadeApplications.CHALLENGE_UPDATER,
        url: "https://challenge-updater-jdgyqpjica-ew.a.run.app"
    }
]

export function getChallengeUpdaterApiHost(env: EnvNames, deployment: DeploymentName | null) {

    let foundApplicationUrl = CHALLENGE_UPDATER_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === deployment && applicationUrl.environment === env);
    if (!foundApplicationUrl) {
        foundApplicationUrl = CHALLENGE_UPDATER_API_HOSTS.find((applicationUrl) => applicationUrl.deployment === null && applicationUrl.environment === env);
    }

    return `${foundApplicationUrl?.url}`;
}

export function getChallengeUpdaterApiUrl(env: EnvNames, deployment: DeploymentName | null) {
    return `${getChallengeUpdaterApiHost(env, deployment)}/api/v1`;
}