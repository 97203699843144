import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch } from "react-redux";
import { cursor, mobilePaperPropsSM } from "../../Common/StyledComponents";
import { desktopDisplayBreakpoint } from "../../config/theme";
import { DeliveryOrderExpectation } from "../../customers/models/OrderExpectation";
import DeliveryAddressTextField from "../../delivery/components/DeliveryAddressTextField";
import { setCustomerAndChargesFromAddress } from "../../delivery/services/DeliveryServices";
import { Order } from "../../my-lemonade-library/model/Order";
import { getAddressFromCustomer } from "../../my-lemonade-library/src/customers/services/CustomerHelper";
import { ThemeList } from "../../my-lemonade-library/src/theme/models/ThemesList";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import orderActions from "../redux/OrderActions";

interface DeliveryAddressChangeModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    desktop: boolean | undefined,
    order: Order,
}

/**
 * This modal is called in the SummaryPage if the user wants to change the
 * delivery address. It works similarly to ServiceTypeOrTakeoutForm, with only the 
 * DeliveryFullForm displayed.
 * @param props 
 * @returns 
 */
const DeliveryAddressChangeModal: React.FC<DeliveryAddressChangeModalProps> = (props) => {

    const { open, setOpen, desktop, order } = props;

    const { selectedTable, selectedCatalog, selectedLocation } = useTypedSelector((state: RootState) => state.locations)
    const { themeName } = useTypedSelector((state: RootState) => state.theme)

    // When undefined, it means the user didn't change the address (could have changed only the delivery notes for example)
    const [addressExpectation, setAddressExpectation] = useState<DeliveryOrderExpectation>();

    // Here we use fullScreen also as "showAddressTextField" (see ServiceTypeOrTakeoutForm)
    const [fullScreen, setFullScreen] = useState<boolean>();

    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint))

    /**
     * When the user clicks on "confirm"
     */
    const handleClick = () => {

        if (!selectedLocation || !selectedCatalog) {
            log.error("Missing catalog or location");
            return;
        }

        if (addressExpectation?.delivery_zone && addressExpectation.address) {
            setCustomerAndChargesFromAddress(
                addressExpectation.address,
                addressExpectation.delivery_zone,
                intl,
                dispatch,
                order,
                selectedCatalog,
                selectedLocation,
                selectedTable,
                addressExpectation.delivery_note,
                addressExpectation.addressType,
            );
        }
        else {
            const newCustomer = _.cloneDeep(order.customer) ?? {};
            newCustomer.delivery_notes = addressExpectation?.delivery_note ?? undefined;
            dispatch(orderActions.setCustomerInfo(newCustomer, false));
        }

        setOpen(false);
    }

    /**
     * Set the current address in the state to prefill the textField. We don't set the delivery
     * zone on purpose: it will be computed in the component DeliveryAddressTextField if the address is
     * changed.
     */
    useEffect(() => {

        const addressExpected: DeliveryOrderExpectation = {};

        if (order?.customer) {
            const address = getAddressFromCustomer(order.customer)
            if (address) {
                addressExpected.address = address;
            }
            if (order.customer.delivery_notes) {
                addressExpected.delivery_note = order.customer.delivery_notes;
            }
            if (order.customer.delivery_address_type_choice) {
                addressExpected.addressType = order.customer.delivery_address_type_choice;
            }
        }

        // if (order.delivery_zone_ref && selectedLocation) {
        //     const zone = deliveryHelper.getDeliveryZoneFromRef(order.delivery_zone_ref, selectedLocation.delivery)
        //     if (zone) {
        //         if (order.delivery) {
        //             const foundCharge = order.charges?.find((orderCharge) => orderCharge.type === OrderChargeType.DELIVERY);
        //             const checkedDeliveryZone: CheckedDeliveryZone = {
        //                 ...zone,
        //                 price: (foundCharge && foundCharge.price) ? foundCharge.price : zone.default_delivery_fee,
        //                 provider_type: order.delivery.delivery_type
        //             };
        //             addressExpected.delivery_zone = checkedDeliveryZone
        //         } else {
        //             const checkedDeliveryZone: CheckedDeliveryZone = {
        //                 ...zone,
        //                 price: zone.default_delivery_fee,
        //                 provider_type: DeliveryProviderType.OWN_FLEET
        //             };
        //             addressExpected.delivery_zone = checkedDeliveryZone
        //         }
        //     }
        // }
        setAddressExpectation(addressExpected);

    }, [order, selectedLocation])

    return (

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={"paper"}
            fullScreen={fullScreen}
            // If on mobile, this makes the dialog sticky to the bottom of the screen
            PaperProps={{
                style: isDesktopRender ? { width: '400px' } : { ...mobilePaperPropsSM }
            }}
        >

            {!fullScreen &&

                <DialogTitle
                    style={{
                        fontWeight: "bold",
                        flexGrow: 1
                    }}>

                    <IconButton
                        size={desktop ? 'small' : 'medium'}
                        style={{
                            ...cursor,
                            position: 'absolute',
                            top: 10, right: 10,
                            color: theme.palette.text.primary,
                        }}
                        onClick={() => setOpen(false)}
                    >
                        <Close />

                    </IconButton>

                    <Typography
                        variant="h2"
                        color="textSecondary"
                        style={{
                            flexGrow: 1,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                        }}
                    >

                        {
                            intl.formatMessage(
                                {
                                    id: "delivery"
                                }
                            )
                        }

                    </Typography>

                </DialogTitle>
            }

            <DialogContent style={{ padding: 0 }}>

                <Box height={1} px={4} py={2}>

                    <DeliveryAddressTextField
                        onPreviousClick={() => {
                            setFullScreen(false)
                        }}
                        addressToEdit={addressExpectation ?? {}}
                        onAddressToEditChange={setAddressExpectation}
                        isGetRestoTheme={themeName === ThemeList.GET_RESTO}
                        hideHint
                        hideButton
                    />

                </Box>

            </DialogContent>

            {!fullScreen && <DialogActions
                disableSpacing
                style={{
                    padding: theme.spacing(2),
                    backgroundColor: 'transparent',
                    justifyContent: "center"
                }}
            >

                <Button
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    style={{ padding: theme.spacing(1, 5), }}
                >
                    <Typography>
                        {intl.formatMessage({ id: "Order.cart.customer_notes.dialog.save" })}
                    </Typography>

                </Button>

            </DialogActions>}

        </Dialog>
    );
}

export default DeliveryAddressChangeModal;