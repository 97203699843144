import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import DefaultBackground from "../../assets/bg_home.png";
import { capitalizeFirstLetter } from '../../Common/helper/TextHelper';
import { Product, Sku } from '../../my-lemonade-library/model/Catalog';
import { moneyToNumber, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import FullDealSku from '../../my-lemonade-library/src/deals/models/FullDealSku';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { RootState, useTypedSelector } from '../../redux/root-reducer';

interface DealLinesDisplayProps {
    dealSkus: FullDealSku[]
    index: number
    onChange: (product: Product, skuSelected: Sku, lineIndex: number) => void
    selected: string | null,
}

const DealLinesDisplay = (props: DealLinesDisplayProps) => {

    const {
        dealSkus,
        index,
        onChange,
        selected
    } = props

    const theme = useTheme()
    const intl = useIntl()

    const { selectedCatalog } = useTypedSelector((state: RootState) => state.locations)

    const handleChange = (ref: string, lineIndex: number) => {
        const newSelection: FullDealSku | undefined = dealSkus.find(dealSku => dealSku.sku.ref === ref)
        if (newSelection) {
            onChange(newSelection.product, newSelection.sku, lineIndex)
        }
    }

    const getImage = (product: Product) => {
        if (product.image) {
            return product.image
        } else if (selectedCatalog) {
            const image = selectedCatalog.data.categories.find(cat => cat.ref === product.category_ref)?.icon
            return image ? image : DefaultBackground
        } else {
            return DefaultBackground
        }
    }

    return (
        <RadioGroup
            style={{ width: '100%' }}
            value={selected}
            onChange={(e) => handleChange(e.target.value, index)}
        >
            {
                dealSkus.map((dealSku, index: number) => {
                    return (

                        <Box key={index}>
                            <Divider />
                            <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='space-between'
                                alignItems='center'
                                my={1}
                                data-test={`deal_line_${dealSku.ref}`}
                            >
                                <FormControlLabel
                                    data-test="form_control_label"
                                    value={dealSku.ref}
                                    control={
                                        <Radio
                                            icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                            checkedIcon={<CheckCircleIcon />}
                                            color='primary'
                                        />
                                    }
                                    label={
                                        <Box display='flex' flexDirection='column' px={1}>
                                            <Typography variant='body1'>
                                                {capitalizeFirstLetter(
                                                    intl.formatMessage({
                                                        id: translationService.getProductNameTranslationKey(dealSku.product),
                                                        defaultMessage: dealSku.sku.name
                                                    }))}
                                            </Typography>

                                            {dealSku.product.description &&
                                                <Typography
                                                    variant="caption"
                                                    style={{
                                                        marginBottom: theme.spacing(2),
                                                        color: theme.palette.text.disabled,
                                                        display: '-webkit-box',
                                                        overflow: 'hidden',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 2,
                                                    }}
                                                >
                                                    {dealSku.product.description}
                                                </Typography>
                                            }

                                            {dealSku.full_extra_charge &&
                                                moneyToNumber(dealSku.full_extra_charge, false, dealSku) !== 0 &&

                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        color: theme.palette.text.disabled,
                                                    }}
                                                >
                                                    {'+ ' + MoneyToStringWithSymbol(dealSku.full_extra_charge)}
                                                </Typography>
                                            }
                                        </Box>
                                    }
                                />

                                <Box width='90px' height='90px' my={1}>
                                    <img
                                        src={getImage(dealSku.product)}
                                        alt={dealSku.product.name}
                                        style={{
                                            height: '90px',
                                            width: '90px',
                                            objectFit: 'cover',
                                            borderRadius: '8px'
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )
                })
            }
        </RadioGroup >
    )
}

export default DealLinesDisplay