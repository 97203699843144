import { OrderInBase, PaymentType } from "../../../model/Order";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { getLocationFirestoreDocPath } from "../../locations/services/LocationService";
import ordersApiRoutes, { ORDER_ID_PARAM } from "../../orders/configs/OrdersApiRoutes";
import { getOrdersFirestoreCollectionPath } from "../../orders/services/OrderService";
import { INVOICE_ID_PARAM, MARKETPLACE_ID_PARAM, MARKETPLACE_INVOICE, MARKETPLACE_PAYMENT_TYPE_PARAM } from "../../payments/configs/MarketplaceApiRoutes";
import { PAYMENT_ID_PARAM_KEY } from "../../payments/configs/PaymentsApiRoutes";
import { EMAIL_KEY, FULL_NAME_KEY, MEALS_KEY, OVERRIDE_KEY, PAYMENT_NOTE_KEY } from "../configs/InvoicesApiRoutes";

const invoiceHelper = {

    getInvoiceStorageFilePath(order: OrderInBase, paymentIntentId?: string, userId?: string): string {
        const ordersPath = getOrdersFirestoreCollectionPath(order.account_id, order.location_id);
        if (paymentIntentId) {
            return `${ordersPath}/${order.id}_invoice_payment_${paymentIntentId}.pdf`;
        } else if (userId) {
            return `${ordersPath}/${order.id}_invoice_user_${userId}.pdf`;
        }
        else {
            return `${ordersPath}/${order.id}_invoice.pdf`;
        }
    },

    getInvoiceBucket(projectId: string): string {
        return `${projectId}-orders`;
    },

    getMarketplaceInvoiceStorageFilePath(paymentType: PaymentType, marketplaceId: string, invoiceId: string): string {
        return `${paymentType}/${marketplaceId}/${invoiceId}_fee_amount_invoice.pdf`;
    },

    getMarketplaceInvoiceBucket(projectId: string): string {
        return `${projectId}-marketplace-invoices`;
    },

    getMarketplaceInvoiceUrl(apiUrl: string, paymentType: PaymentType, marketplaceId: string, invoiceId: string): string {
        return `${apiUrl}${MARKETPLACE_INVOICE}`
            .replace(MARKETPLACE_PAYMENT_TYPE_PARAM, paymentType)
            .replace(MARKETPLACE_ID_PARAM, marketplaceId)
            .replace(INVOICE_ID_PARAM, invoiceId);
    },

    getInvoicesFirestoreCollectionPath(
        accountId: string,
        locationId: string,
    ): string {
        return `${getLocationFirestoreDocPath(accountId, locationId)}/${MylemonadeScopes.INVOICES}`;
    },

    getInvoicesFirestoreDocPath(
        accountId: string,
        locationId: string,
        invoiceId: string,
    ): string {
        return `${this.getInvoicesFirestoreCollectionPath(accountId, locationId)}/${invoiceId}`;
    },

    /**
     * Get the API URL to fetch the invoice.
     * @param apiUrl 
     * @param orderId 
     * @param paymentNote true = we want a note for one or several payments ; false = we want an order invoice
     * @param paymentIntentId 
     * @param override true = we want to generate a new invoice even if one already exists
     * @param email 
     * @param meals 
     * @param fullName 
     * @returns 
     */
    getInvoiceUrl(
        apiUrl: string,
        orderId: string,
        paymentNote?: boolean,
        paymentIntentId?: string,
        override?: boolean,
        email?: string,
        meals?: string,
        fullName?: string,
    ): string {

        const params = new URLSearchParams();
        if (paymentNote) {
            params.set(PAYMENT_NOTE_KEY, "true");
        }
        if (paymentIntentId) {
            params.set(PAYMENT_ID_PARAM_KEY, paymentIntentId);
        }
        if (override) {
            params.set(OVERRIDE_KEY, 'true');
        }
        if (email) {
            params.set(EMAIL_KEY, email);
        }
        if (meals) {
            params.set(MEALS_KEY, meals);
        }
        if (fullName) {
            params.set(FULL_NAME_KEY, fullName);
        }

        let invoiceUrl = `${apiUrl}${ordersApiRoutes.ORDER_INVOICE}`.replace(ORDER_ID_PARAM, orderId);
        if (params.toString()) {
            invoiceUrl += `?${params.toString()}`;
        }
        return invoiceUrl;
    },

    /**
     * Get the final filename for the invoice.
     * NOTE: this filename is simple and is different from the invoice reference!
     * @param invoiceTranslatedWord result of intl or i18n for the word "invoice"
     * @param orderIdentifier display_id or collection_code
     */
    getInvoiceFileName(
        invoiceTranslatedWord: string,
        orderIdentifier: string
    ): string {
        return `${invoiceTranslatedWord}_${orderIdentifier}`
    },
}

export default invoiceHelper;