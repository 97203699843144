import { LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

/**
 * Key used in route or query params
 */
export const TABLE_KEY = 'tableId';

export const TABLE_ID_PARAM_KEY = "tableId";
export const TABLE_ID_PARAM = `:${TABLE_ID_PARAM_KEY}`;
export const TABLE_REF_PARAM_KEY = "tableRef";
export const TABLE_REF_PARAM = `:${TABLE_REF_PARAM_KEY}`;

export const LOCATION_TABLES_ROOT = `${LOCATION_ROUTE}/tables`;
export const TABLE_BY_ID = `${LOCATION_TABLES_ROOT}/${TABLE_ID_PARAM}`;
export const TABLE_CLOSE = `${TABLE_BY_ID}/close`;

const tablesApiRoutes = {
    LOCATION_TABLES_ROOT,
    TABLE_BY_ID,
    TABLE_CLOSE,
};
export default tablesApiRoutes;