import AddCardIcon from '@mui/icons-material/AddCard';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import * as ROUTES from '../../config/routes';
import { CONNECTORS_COMPATIBLE_CONNECTOR_WALLET } from '../../my-lemonade-library/src/connectors/models/ConnectorWallet';
import { useTypedSelector } from '../../redux/root-reducer';
import paymentsActions from '../redux/PaymentsActions';

interface PaymentWalletBottomButtonsProps {

}

const PaymentWalletBottomButtons: React.FC<PaymentWalletBottomButtonsProps> = (props) => {

    const intl = useIntl();
    const theme = useTheme();

    const dispatch = useDispatch();
    const history = useHistory();
    const { order } = useTypedSelector((state) => state.order);
    const { selectedLocation, tableLinkId } = useTypedSelector((state) => state.locations);
    const { connector_wallet } = useTypedSelector((state) => state.payment);

    const handleRefreshWallet = () => {
        const connectorType = selectedLocation?.connector?.type;
        if (
            (order.customer?.email || order.customer?.phone)
            && connectorType
            && CONNECTORS_COMPATIBLE_CONNECTOR_WALLET.includes(connectorType)
            && connector_wallet[connectorType] !== undefined
        ) {
            dispatch(paymentsActions.getCustomerConnectorWalletBalance(order.customer?.email, order.customer?.phone));
        } else {
            log.error(`Error refreshing wallet, missing info from customer or connector is not compatible`, {
                customer: order.customer,
                connectorType: connectorType,
                connector_wallet: connector_wallet
            })
        }
    }
    const getCardStyle = (): React.CSSProperties => {

        const style: React.CSSProperties = {
            backgroundColor: theme.palette.background.paper,
            boxShadow: "rgb(0 0 0 / 10%) 0px 1px 5px 0px",
            border: `1px solid ${theme.palette.grey[100]}`,
        }


        return style;
    }

    return (
        <Box
            width="100%"
            display="flex"
            alignSelf="center"
            justifyContent="space-evenly"
            style={getCardStyle()}
        >

            <Box
                borderRight={`1px solid ${theme.palette.grey[500]}`}
                width="50%"
                display="flex"
                justifyContent="center"
            >

                <IconButton
                    disableRipple
                    color='primary'
                    onClick={() => handleRefreshWallet()}
                >
                    <ReplayIcon
                        sx={{
                            marginRight: theme.spacing(1)
                        }}
                    />
                    <Typography
                        color='primary'
                    >
                        {intl.formatMessage({ id: "payment.paymentChoice.wallet.refresh" })}
                    </Typography>
                </IconButton>
            </Box>

            <Box
                width="50%"
                display="flex"
                justifyContent="center"
            >

                <IconButton
                    disableRipple
                    color='primary'
                    onClick={() => history.push(generatePath(ROUTES.LocationHome + ROUTES.CreditWallet, { tableLinkKey: tableLinkId }))}
                >
                    <AddCardIcon
                        sx={{
                            marginRight: theme.spacing(1)
                        }}
                    />
                    <Typography
                        color="primary"
                    >
                        {intl.formatMessage({ id: "payment.paymentChoice.wallet.credit" })}
                    </Typography>
                </IconButton>

            </Box>

        </Box>

    )

}

export default PaymentWalletBottomButtons;