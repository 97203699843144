import { DateTime } from "luxon";
import { SupportedServiceType } from "../../../model/Location";
import { ACCOUNT_PARAM } from "../../accounts/configs/AccountsApiRoutes";
import { DELIVERY_ZONE_KEY, FOR_DATE_KEY, LOCATION_PARAM, LOCATION_ROUTE, LOCATION_TIMESLOTS_ROUTE, SERVICE_TYPE_KEY } from "../../locations/configs/LocationsApiRoutes";
import { TABLE_KEY } from "../../tables/configs/TableApiRoutes";

/**
 * Key used in route or query params
 */
export const CATALOG_KEY = 'catalogId';
export const CATALOG_ID_PARAM = `:${CATALOG_KEY}?`

export const DO_NOT_MERGE_KEY = "doNotMerge";
export const DEFAULT_TO_FIRST_CATALOG_KEY = "defaultToFirstCatalog";
export const IGNORE_AUTH_IF_FIREBASE_USER_KEY = "ignoreAuthIfFirebaseUser";

export const CATALOG_UPDATE_ROUTE = `${LOCATION_ROUTE}/catalogs/${CATALOG_ID_PARAM}`;
export const CATALOG_UPDATE_MENU_ANALYTICS_ROUTE = `${CATALOG_UPDATE_ROUTE}/menu-analytics`;
export const CATALOG_UPDATE_TOGGLE_ROUTE = `${CATALOG_UPDATE_ROUTE}/toggle-entity`;
export const CATALOG_UPDATE_TOGGLE_MULTIPLE_ROUTE = `${CATALOG_UPDATE_ROUTE}/toggle-entities`;
export const CATALOG_BACKUPS_DIFFS_ROUTE = `${CATALOG_UPDATE_ROUTE}/backups-diffs`;
export const CATALOG_PUSH_TO_CHILDREN = `${CATALOG_UPDATE_ROUTE}/push-to-children`;
export const CATALOG_DELETE = `${LOCATION_ROUTE}/catalogs/${CATALOG_ID_PARAM}`

export const getCatalogTimeslotsRoute = (
    accountId: string,
    locationId: string,
    catalogId: string,
    serviceType: SupportedServiceType,
    tableId: string,
    deliveryZoneRef?: string,
    when?: DateTime
): string => {

    let routeTemplate = `${LOCATION_TIMESLOTS_ROUTE}?${CATALOG_KEY}=${catalogId}&${SERVICE_TYPE_KEY}=${serviceType}&${TABLE_KEY}=${tableId}`;
    routeTemplate = routeTemplate.replace(ACCOUNT_PARAM, accountId);
    routeTemplate = routeTemplate.replace(LOCATION_PARAM, locationId);
    if (deliveryZoneRef) {
        routeTemplate = `${routeTemplate}&${DELIVERY_ZONE_KEY}=${deliveryZoneRef}`;
    }
    if (when) {
        routeTemplate = `${routeTemplate}&${FOR_DATE_KEY}=${when.toJSDate().toISOString()}`;
    }
    return routeTemplate;
}

export const getCatalogToggleEntityRoute = (accountId: string, locationId: string, catalogId: string, multiple?: boolean) => {
    const baseRoute = multiple ? CATALOG_UPDATE_TOGGLE_MULTIPLE_ROUTE : CATALOG_UPDATE_TOGGLE_ROUTE;
    return baseRoute
        .replace(ACCOUNT_PARAM, accountId)
        .replace(LOCATION_PARAM, locationId)
        .replace(CATALOG_ID_PARAM, catalogId);
}

export const getCatalogUpdateRoute = (accountId: string, locationId: string, catalogId: string) => {
    let route = CATALOG_UPDATE_ROUTE
    route = route.replace(ACCOUNT_PARAM, accountId)
    route = route.replace(LOCATION_PARAM, locationId)
    route = route.replace(CATALOG_ID_PARAM, catalogId)

    return route
}

export const getCatalogUpdateRouteCosts = (accountId: string, locationId: string, catalogId: string) => {
    let route = CATALOG_UPDATE_MENU_ANALYTICS_ROUTE
    route = route.replace(ACCOUNT_PARAM, accountId)
    route = route.replace(LOCATION_PARAM, locationId)
    route = route.replace(CATALOG_ID_PARAM, catalogId)


    return route;
}

const catalogsApiRoutes = {
    CATALOG_UPDATE_ROUTE,
    CATALOG_UPDATE_MENU_ANALYTICS_ROUTE,
    CATALOG_UPDATE_TOGGLE_ROUTE,
    CATALOG_UPDATE_TOGGLE_MULTIPLE_ROUTE,
    CATALOG_BACKUPS_DIFFS_ROUTE,
    CATALOG_PUSH_TO_CHILDREN,
    CATALOG_DELETE,
};
export default catalogsApiRoutes;