import { generatePath } from "react-router";
import { ERROR_PARAM_NAME } from "../Common/configs/ErrorPageConfig";
import { ErrorPageType } from "../Common/models/ErrorPageType";
import { PAYMENT_ID_PARAM_KEY } from "../my-lemonade-library/src/payments/configs/PaymentsApiRoutes";
import { ORDER_ID_QUERY_PARAM_NAME } from "../orders/configs/OrdersRouterConfig";
import { SESSION_ID_QUERY_PARAM_NAME } from "../sessions/configs/SessionsRouterConfig";


export interface RouteParams {
    tableLinkKey: string;
    categoryRef: string;
    productRef: string;
    orderId: string;
}

//Root Link
export const LocationHome = '/:tableLinkKey'
export const getHomeFullRoute = function (tableLinkKey: string, orderId?: string): string {
    const homePath = generatePath(LocationHome, { tableLinkKey });
    if (!orderId) {
        return homePath;
    } else {
        return `${homePath}?${ORDER_ID_QUERY_PARAM_NAME}=${orderId}`;
    }
}

/**
 * Tell whether we're on the home page.
 * @param pathname the result of history.location.pathname or window.location.pathname
 * @param tableLinkId 
 * @returns 
 */
export const isHomePage = (pathname: string, tableLinkId: string) => {

    return (
        pathname === `/${tableLinkId}`
        || pathname === `/${tableLinkId}/`
    );
}

export const Page404 = '/404'

//Link from LocationHome
export const CategoriesPage = `/categories`
export const getCategoriesFullRoute = function (tableLinkKey: string, sessionId?: string): string {

    let route = generatePath(`${LocationHome}${CategoriesPage}`, { tableLinkKey });

    // TODO: find a solution, because URlSearhParams is not working (we're sometimes using ":orderId" as orderId and the colon is encoded)
    // Idea: use "_" instead of ":"
    if (sessionId) {
        route += `?${SESSION_ID_QUERY_PARAM_NAME}=${sessionId}`;
    }

    return route;
}

export const SubcategoriesPage = `${CategoriesPage}/:categoryRef`
export const getSubcategoriesFullRoute = function (tableLinkKey: string, categoryRef: string): string {
    // TODO: why is there an error here?
    //@ts-ignore
    return generatePath(`${LocationHome}${SubcategoriesPage}`, { tableLinkKey, categoryRef });
}

export const ProductsPage = `${SubcategoriesPage}/products`
export const getProductsFullRoute = function (tableLinkKey: string, categoryRef: string): string {
    // TODO: why is there an error here?
    //@ts-ignore
    return generatePath(`${LocationHome}${ProductsPage}`, { tableLinkKey, categoryRef });
}

export const ProductDetail = `${ProductsPage}/:productRef`
export const getProductsDetailFullRoute = function (tableLinkKey: string, categoryRef: string, productRef: string) {
    // TODO: why is there an error here?
    //@ts-ignore
    return generatePath(`${LocationHome}${ProductDetail}`, { tableLinkKey, categoryRef, productRef })
}

export const ProductOptions = `${ProductDetail}/options`
export const getProductOptionsFullRoute = function (tableLinkKey: string, categoryRef: string, productRef: string) {
    // TODO: why is there an error here?
    //@ts-ignore
    return generatePath(`${LocationHome}${ProductOptions}`, { tableLinkKey, categoryRef, productRef })
}

export const OrderSummaryPage = `/summary`
export const getOrderSummaryFullRoute = function (tableLinkKey: string): string {
    return generatePath(`${LocationHome}${OrderSummaryPage}`, { tableLinkKey });
}

export const ConfirmationWord = 'confirmation'
export const OrderConfirmationPage = `/${ConfirmationWord}`
export const ConfirmPayment = 'confirm-payment'
export const OrderConfirmPaymentPage = `/${ConfirmPayment}`

export const getOrderConfirmationFullRoute = (tableLinkKey: string, orderId: string, paymentIntentId?: string, sessionId?: string): string => {

    let route = generatePath(
        `${LocationHome}${paymentIntentId ? OrderConfirmPaymentPage : OrderConfirmationPage}`,
        { tableLinkKey }
    );

    // TODO: find a solution, because URlSearhParams is not working (we're sometimes using ":orderId" as orderId and the colon is encoded)
    // Idea: use "_" instead of ":"
    route += `?${ORDER_ID_QUERY_PARAM_NAME}=${orderId}`;

    if (paymentIntentId) {
        route += `&${PAYMENT_ID_PARAM_KEY}=${paymentIntentId}`;
    }

    if (sessionId) {
        route += `&${SESSION_ID_QUERY_PARAM_NAME}=${sessionId}`;
    }

    return route;
}

export const isConfirmation = (pathname: string) => {
    return pathname.includes(ConfirmationWord) || pathname.includes(ConfirmPayment);
}

export const SharePayment = '/sharepayment'
export const getSharePaymentFullRoute = function (tableLinkKey: string): string {
    return generatePath(`${LocationHome}${SharePayment}`, { tableLinkKey });
}

export const QrCode = 'qrcode'
export const QrCodePage = `/${QrCode}/:${ORDER_ID_QUERY_PARAM_NAME}?`
export const getQrCodeFullRoute = function (tableLinkKey: string, orderId: string): string {
    // TODO: why is there an error here?
    //@ts-ignore
    return generatePath(`${LocationHome}${QrCodePage}`, { tableLinkKey, orderId });
}

export const ErrorRoutePage = `/error`
export const getErrorFullRoute = function (tableLinkKey: string, orderId?: string, errorPageType?: ErrorPageType, sessionId?: string): string {

    // the a=a is a trick to make sure that the first param is preceded by a "?"
    let route = generatePath(`${LocationHome}${ErrorRoutePage}?a=a`, { tableLinkKey });

    if (orderId) {
        route += `&${ORDER_ID_QUERY_PARAM_NAME}=${orderId}`;
    }
    else if (errorPageType) {
        route += `&${ERROR_PARAM_NAME}=${errorPageType}`;
    }

    if (sessionId) {
        route += `&${SESSION_ID_QUERY_PARAM_NAME}=${sessionId}`;
    }

    // TODO: find a solution, because URlSearhParams is not working (we're sometimes using ":orderId" as orderId and the colon is encoded)
    // Idea: use "_" instead of ":"
    route.replace('a=a&', '');
    route.replace('?a=a', '');
    return route;
}

export const PaymentCollectionInfo = '/collection'
export const getPaymentCollectionInfoFullRoute = function (tableLinkKey: string): string {
    return generatePath(`${LocationHome}${PaymentCollectionInfo}`, { tableLinkKey });
}

export const PaymentShareItems = '/payments/share'
export const getPaymentShareItemsFullRoute = function (tableLinkKey: string): string {
    return generatePath(`${LocationHome}${PaymentShareItems}`, { tableLinkKey });
}

export const PaymentChoice = '/payments/choice'
export const getPaymentChoiceFullRoute = function (tableLinkKey: string): string {
    return generatePath(`${LocationHome}${PaymentChoice}`, { tableLinkKey });
}

export const LoyaltyPage = `/loyalty`
export const getLoyaltyFullRoute = function (tableLinkKey: string): string {
    return generatePath(`${LocationHome}${LoyaltyPage}`, { tableLinkKey });
}

export const HappyHour = '/happyhour'
export const Menu = '/menu'

export const PaymentStripe = '/stripe'
export const PaymentWorldline = '/worldline'
export const PaymentLyra = '/payments/lyra'
export const PaymentLyf = '/payments/lyf'
export const PaymentAdyen = '/payments/adyen'
export const PaymentEdenred = '/payments/edenred'
export const PaymentSwile = '/payments/swile'
export const PaymentTable = '/table'
export const PaymentBedroomInfo = '/bedroom'
export const LocationContact = '/location/contact'
export const MyInformations = '/myinformations'
export const OrderHistory = `${MyInformations}/orders`
export const OrderSummary = '/summary'
export const SharedOrderLanding = '/sharedOrderLanding'
export const PaymentInProgress = '/paymentInProgress'
export const CreditWallet = '/creditWallet'

export const PAYMENT_DO_NOT_USE_SAVED_CARD = 'do_not_use_saved_card';