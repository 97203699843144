
export const getBaseCloudFunctionsUrl = (isProdEnvironment: boolean) => {
    return `https://europe-west1-my-lemonade${isProdEnvironment ? "" : "-dev"}.cloudfunctions.net`
}

const CF_RECOVERY_PASSWORD_ENDPOINT = "/recoveryPassword";
const CF_SEND_INVOICE_BY_EMAIL_ENDPOINT = "/sendInvoiceByEmail";

export const cloudFunctionsEndpoints = {
    recovery_password: CF_RECOVERY_PASSWORD_ENDPOINT,
    send_invoice_by_email: CF_SEND_INVOICE_BY_EMAIL_ENDPOINT,
}