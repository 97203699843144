import HttpError from '../../common/models/HttpError';
import MylemonadeScopes from '../../common/models/MyLemonadeScopes';

export default class PaymentErrors {

    static PAYMENT_MISSING_PAYMENT_AMOUNT: HttpError = new HttpError("PAYMENT_MISSING_PAYMENT_AMOUNT", "The payment amount is missing or not well formatted", 400, MylemonadeScopes.PAYMENTS);

    static PAYMENT_TYPE_NOT_SUPPORTED: HttpError = new HttpError("PAYMENT_TYPE_NOT_SUPPORTED", "Not supported payment type", 500, MylemonadeScopes.PAYMENTS);
    static MISSING_PAYMENT_TYPE: HttpError = new HttpError("MISSING_PAYMENT_TYPE", "The request is missing a payment type", 400, MylemonadeScopes.PAYMENTS);

    static MISSING_INFORMATION: HttpError = new HttpError("MISSING_INFORMATION", "The request is missing information", 400, MylemonadeScopes.PAYMENTS);

    static PAYMENT_INVALID_TRANSACTION_ID: HttpError = new HttpError("PAYMENT_INVALID_TRANSACTION_ID", "Invalid transaction id", 500, MylemonadeScopes.PAYMENTS);
    static PAYMENT_MISSING_TRANSACTION_ID: HttpError = new HttpError("PAYMENT_MISSING_TRANSACTION_ID", "Missing transaction id", 500, MylemonadeScopes.PAYMENTS);

    static PAYMENT_CANNOT_SAVE_SECRET: HttpError = new HttpError("PAYMENT_CANNOT_SAVE_SECRET", 'cannot save payment secret', 402, MylemonadeScopes.PAYMENTS);

    static PAYMENT_POTENTIAL_OVER_PAYMENT_AMOUNT: HttpError = new HttpError("PAYMENT_POTENTIAL_OVER_PAYMENT_AMOUNT", "The payment amount is superior to the remaining due amount", 400, MylemonadeScopes.PAYMENTS);

    static PAYMENTS_ERROR_CREATING_PAYMENT: HttpError = new HttpError("PAYMENTS_ERROR_CREATING_PAYMENT", "Error creating the payment request in the PSP", 500, MylemonadeScopes.PAYMENTS);
    static PAYMENT_FAILURE: HttpError = new HttpError("PAYMENT_FAILURE", "The payment has failed", 500, MylemonadeScopes.PAYMENTS);
    static PAYMENT_ORDER_NOT_FOUND: HttpError = new HttpError("PAYMENT_ORDER_NOT_FOUND", "The order is not found", 500, MylemonadeScopes.PAYMENTS);
    static PAYMENT_PENDING_ORDER_PAYMENT_NOT_FOUND: HttpError = new HttpError("PAYMENT_PENDING_ORDER_PAYMENT_NOT_FOUND", "The order payment is not found", 500, MylemonadeScopes.PAYMENTS);
    static PAYMENT_ALREADY_PAID: HttpError = new HttpError("PAYMENT_ALREADY_PAID", "The order payment has been found but has not the status pending", 500, MylemonadeScopes.PAYMENTS);
    static PAYMENT_ONLY_FOR_AUTHENTICATED_USER: HttpError = new HttpError("PAYMENT_ONLY_FOR_AUTHENTICATED_USER", "This payment method is only allowed for authenticated users", 400, MylemonadeScopes.PAYMENTS);

    static REFUND_NOT_SUPPORTED: HttpError = new HttpError("REFUND_NOT_SUPPORTED", "The refund is not supported for this order, check the payment lines and the status of the order.", 400, MylemonadeScopes.PAYMENTS);
    static PAYMENTS_REFUND_AMOUNT_TOO_LARGE: HttpError = new HttpError("PAYMENTS_REFUND_AMOUNT_TOO_LARGE", "The refund amount can't be larger than the paid amount", 400, MylemonadeScopes.PAYMENTS);
    static REFUND_INVALID_SELLER_ID: HttpError = new HttpError("REFUND_INVALID_SELLER_ID", "The seller id is invalid", 400, MylemonadeScopes.PAYMENTS);
    static ERROR_TRANSACTION_REFUND: HttpError = new HttpError("ERROR_TRANSACTION_REFUND", "Error refunding transaction", 500, MylemonadeScopes.PAYMENTS);

    static ADD_PAYMENT_NOT_ALLOWED: HttpError = new HttpError("ADD_PAYMENT_NOT_ALLOWED", "The user is not allowed to add a payment to the order", 500, MylemonadeScopes.PAYMENTS);

    static INVALID_MARKETPLACE_PERCENTAGE_FEE: HttpError = new HttpError("INVALID_MARKETPLACE_PERCENTAGE_FEE", "Invalid marketplace percentage fee rate", 500, MylemonadeScopes.PAYMENTS);
    static INVALID_MARKETPLACE_FEE_VAT: HttpError = new HttpError("INVALID_MARKETPLACE_FEE_VAT", "Invalid marketplace fee vat rate", 500, MylemonadeScopes.PAYMENTS);
    static INVALID_SERVICE_PERCENTAGE_FEE: HttpError = new HttpError("INVALID_SERVICE_PERCENTAGE_FEE", "Invalid service percentage fee rate", 500, MylemonadeScopes.PAYMENTS);

    static PAYMENT_FEE_CONFIG_NOT_FOUND: HttpError = new HttpError("PAYMENT_FEE_CONFIG_NOT_FOUND", "The marketplace fee config is not found for this service type", 500);
    static FEE_CONFIG_UPDATE_PAYMENT_TYPE_NOT_ALLOWED: HttpError = new HttpError("FEE_CONFIG_UPDATE_PAYMENT_TYPE_NOT_ALLOWED", "This payment type is not allowed for a fee config update", 500);

    static BALANCE_TOO_LOW: HttpError = new HttpError("BALANCE_TOO_LOW", "The balance is too low to pay the order", 500, MylemonadeScopes.PAYMENTS);
}