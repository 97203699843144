import moment from 'moment';
import { applyPricingEffect } from '../../../functions/Helpers';
import { Deal, DealLine, DealSku, getTimezoneName, PricingEffect, Product, Sku } from '../../../model/Catalog';
import { CatalogExtended } from '../../../model/catalogExtended/CatalogExtended';
import { SupportedServiceType } from '../../../model/Location';
import { OrderItem, OrderOption } from '../../../model/Order';
import { SkuExtended } from '../../../model/ProductExtended/ProductExtended';
import { SignInProviders } from '../../authentications/models/BaseUser';
import { Customer } from '../../authentications/models/Customer';
import { getCurrency, Money, moneyToNumber, MoneyToStringWithSymbol, numberToMoney } from '../../common/models/Money';
import { log } from '../../common/services/LogService';
import OrderContributor from '../../orders/models/OrderContributor';
import { changeSkuPriceIfPriceOverridesApplies, getDealLinePrice, isDealMatchingRestrictions } from '../../orders/services/OrderPricing';
import { DealsForProvider } from '../models/DealsForProvider';

export function getDealLineRef(deal: Deal, lineIndex: number): string {
    const dealLine = deal.lines[lineIndex];
    return dealLine.ref ? dealLine.ref : `line_${lineIndex}`;
}

export function computeSkuDealPriceNumber(sku: Sku, line: DealLine): number | null {
    if (line && line.skus) {
        const dealSku = line.skus.find(dealSku => dealSku.ref === sku.ref);
        if (dealSku) {
            const skuPrice = moneyToNumber(sku.price, false, sku);
            let dealPrice = applyPricingEffect(skuPrice, line.pricing_effect, line.pricing_value);
            if (!dealPrice) {
                dealPrice = 0;
            }
            if (dealSku.extra_charge) {
                dealPrice += moneyToNumber(dealSku.extra_charge, false, dealSku);
            }
            return dealPrice;
        }
    }
    return null;
}

export function computeSkuDealPriceMoney(sku: Sku, line: DealLine): string | null {
    const currency = getCurrency(sku.price)
    const dealPrice = computeSkuDealPriceNumber(sku, line);
    if (dealPrice) {
        return numberToMoney(dealPrice, currency);
    }
    return null
}

export interface PriceAndSkus {
    minPrice: Money,
    maxPrice: Money,
    minPriceOrderItems: OrderItem[],
}

/**
 * Compute the minimum price for a deal
 * TODO: test it
 * @param deal 
 * @param catalog 
 */
export function computeStartingPriceAndMaxPrice(deal: Deal, catalog: CatalogExtended): PriceAndSkus | undefined {

    log.debug(`computeStartingPriceAndMaxPrice ${deal.ref}`, { deal });

    if (deal && deal.lines && deal.lines.length) {

        let dealMinPrice: number = 0;
        let dealMaxPrice: number = 0;

        let currency = catalog.currency;

        const minPriceOrderItems: OrderItem[] = [];
        deal.lines.forEach(line => {

            // See: https://www.hubrise.com/developers/api/catalog-management/#deals
            // For fixed value and price off, it's a money
            const pricingValueNumber: number = (line.pricing_value && line.pricing_effect !== PricingEffect.EFFECT_PERCENTAGE_OFF)
                ? moneyToNumber(line.pricing_value as Money, false, line)
                : line.pricing_value
                    ? parseFloat(line.pricing_value as string) // Parsing just in case the percentage is entered as a string
                    : 0;


            if (line.pricing_effect === PricingEffect.EFFECT_FIXED_PRICE) {

                /*log.info(`Deal line ${line.ref} with fixed price ${pricingValue} for deal ${deal.ref}`, {
                    account_id: catalog.account_id,
                    location_id: catalog.location_id,
                    catalog_id: catalog.id,
                });*/

                dealMinPrice += pricingValueNumber;
                dealMaxPrice += pricingValueNumber;

                let catalogSku: Sku | null = null;
                let minExtraCharge: number = Infinity;
                let maxExtraCharge: number = 0;

                line.skus.forEach(dealSku => {

                    const skuRef: string = dealSku.ref;
                    if (!skuRef) {
                        log.warn(`Deal sku ${skuRef} not found in deal ${deal.ref}`);
                    }

                    if (!dealSku.extra_charge) {
                        minExtraCharge = 0;
                        catalogSku = catalog.data.skusMap ? catalog.data.skusMap[skuRef] : null;
                    } else if (moneyToNumber(dealSku.extra_charge, false, dealSku) < minExtraCharge) {
                        minExtraCharge = moneyToNumber(dealSku.extra_charge, false, dealSku);
                        catalogSku = catalog.data.skusMap ? catalog.data.skusMap[skuRef] : null;
                    }

                    if (dealSku.extra_charge && moneyToNumber(dealSku.extra_charge, false, dealSku) > maxExtraCharge) {
                        maxExtraCharge = moneyToNumber(dealSku.extra_charge, false, dealSku);
                    }

                })

                dealMinPrice = (minExtraCharge ?? 0) + dealMinPrice;
                dealMaxPrice = (maxExtraCharge ?? 0) + dealMaxPrice;

                // For TS
                catalogSku = catalogSku as Sku | null;
                if (catalogSku) {
                    const productRef: string = catalogSku.product_ref!
                    const currentOrderItem: OrderItem = {
                        sku_ref: catalogSku.ref,
                        product_ref: productRef,
                        product_name: "",
                        quantity: 1,
                        price: "",
                        options: getMinimalPriceOptionsForProduct(catalogSku.ref, catalog),
                    }
                    minPriceOrderItems.push(currentOrderItem)
                    if (!currency) {
                        currency = getCurrency(line.pricing_value as Money);
                    }
                } else {
                    // TODO
                }
            } else {
                // Loop on line products to get min price
                let lineMinPrice: number = Infinity;
                let minSkuRef: string = '';
                let lineMaxPrice: number = 0;

                if (line.skus && line.skus.length > 0) {

                    line.skus.forEach((dealSku) => {
                        const productSku = catalog.data.skusMap ? catalog.data.skusMap[dealSku.ref] : null;
                        if (productSku) {
                            if (productSku.price) {
                                if (!currency) {
                                    currency = getCurrency(productSku.price);
                                }
                                const skuPrice = moneyToNumber(productSku.price, false, productSku);

                                let skuExtraCharge = 0;
                                if (dealSku.extra_charge) {
                                    skuExtraCharge = moneyToNumber(dealSku.extra_charge, false, dealSku);
                                }
                                const skuTotalPrice = skuPrice + skuExtraCharge;

                                if (skuTotalPrice < lineMinPrice) {
                                    lineMinPrice = skuTotalPrice;
                                    minSkuRef = dealSku.ref;
                                }

                                if (skuTotalPrice > lineMaxPrice) {
                                    lineMaxPrice = skuTotalPrice;
                                }

                            } else {
                                log.error(`Product sku ${productSku.ref} for product ${productSku.product_ref} without a price while pricing the deal ${deal.ref}`, {
                                    account_id: catalog.account_id,
                                    location_id: catalog.location_id,
                                    catalog_id: catalog.id,
                                })
                            }
                        } else {
                            log.error(`Product sku ${dealSku.ref} not found while pricing the deal ${deal.ref}`, {
                                account_id: catalog.account_id,
                                location_id: catalog.location_id,
                                catalog_id: catalog.id,
                            })
                        }
                    })
                }
                //log.info(`Line ${line.ref} with price ${price} and pricing effect ${line.pricing_effect}`);
                const finalLineMinPrice: number | null = applyPricingEffect(lineMinPrice, line.pricing_effect, line.pricing_value);
                const finalLineMaxPrice: number | null = applyPricingEffect(lineMaxPrice, line.pricing_effect, line.pricing_value);
                dealMinPrice += finalLineMinPrice ?? 0;
                dealMaxPrice += finalLineMaxPrice ?? 0;

                const skuRef: string = minSkuRef
                const productRef: string = catalog.data.skusMap ? catalog.data.skusMap[skuRef].product_ref! : "";

                const currentOrderItem: OrderItem = {
                    sku_ref: skuRef,
                    product_ref: productRef,
                    product_name: "",
                    quantity: 1,
                    price: "",
                    options: getMinimalPriceOptionsForProduct(skuRef, catalog),
                }
                minPriceOrderItems.push(currentOrderItem)
                //log.info(`Line ${line.ref} with price after ${price}`);

            }
        })
        const priceAndSkus: PriceAndSkus = {
            minPrice: numberToMoney(dealMinPrice, currency),
            maxPrice: numberToMoney(dealMaxPrice, currency),
            minPriceOrderItems
        }
        return priceAndSkus
    }
    return;
}

export const getMinimalPriceOptionsForProduct = (skuRef: string, catalog: CatalogExtended): OrderOption[] => {

    const foundSku = catalog.data.skusMap ? catalog.data.skusMap[skuRef] : null;
    if (!foundSku) {
        log.error(`Sku ${skuRef} not found in catalog`);
        return [];
    }

    const finalOptions: OrderOption[] = [];
    foundSku.option_list_refs?.forEach((optionlistRef) => {

        const foundOptionlist = catalog.data.option_lists.find(opl => opl.ref === optionlistRef);
        if (foundOptionlist && foundOptionlist.option_lines.length > 0) {

            let minimalPriceOptionLine = foundOptionlist.option_lines[0];
            foundOptionlist.option_lines.forEach((optionLine) => {
                if (optionLine.price && moneyToNumber(optionLine.price) < moneyToNumber(minimalPriceOptionLine.price)) {
                    minimalPriceOptionLine = optionLine;
                }
            });

            const minimalPriceOption = catalog.data.options.find(o => o.ref === minimalPriceOptionLine.option_ref);
            for (let i = 0; i < (foundOptionlist.min ?? 0); i++) {

                finalOptions.push({
                    name: minimalPriceOption?.name ?? "",
                    option_list_name: foundOptionlist.name,
                    option_list_ref: foundOptionlist.ref,
                    price: minimalPriceOptionLine.price,
                    ref: minimalPriceOptionLine.option_ref,
                });
            }
        }
    });

    return finalOptions;
}

// // Updating the used_count for the user
// if (!updateDealUsage(orderDeal, order.id, item.deal_line.deal_key, order.contributors, catalog.id)) {
//     log.error("OrderPrice: could not update deal usage", { item, orderDeal, usedDeal, orderContributors: order.contributors })
// }

/**
 * Check if the user can use this deal, checking the max_per_customer and
 * the count in the customer used_deals object.
 * @param deal 
 * @param orderDeal 
 * @param contributors 
 * @param catalogId 
 * @returns The number of usages left (can be infinity), or -1 if error or missing params
 */
export const checkRestrictionAndGetDealNumberOfUsagesLeft = (
    deal: Deal,
    contributor: OrderContributor | Customer | undefined,
    catalogId: string | undefined,
): number => {

    log.debug("OrderPrice > checkRestrictionAndGetDealNumberOfUsagesLeft", { deal, contributor, catalogId });

    // Missing parameters: cannot check
    if (!catalogId) {
        return -1;
    }

    const authorizedSigninProviders = deal.usage_restriction?.authentication_providers;
    const maxPerCustomer = deal.usage_restriction?.max_per_customer;

    // If there's a signinProvider restriction but the contributor is not allowed
    if (
        authorizedSigninProviders
        && (
            !contributor
            || !authorizedSigninProviders.find(provider => provider === contributor.sign_in_provider)
        )
    ) {
        return -1;
    }

    // no max or -1 => infinity
    if (!maxPerCustomer || maxPerCustomer === -1) {
        return Infinity;
    }

    // Missing contributor AND max exists
    if (!contributor) {
        return -1;
    }

    const contributorUsedDeals = contributor.used_deals?.[catalogId];
    const contributorUsedDeal = contributorUsedDeals?.[deal.ref];

    // Checking the count
    const usedCount = contributorUsedDeal?.used_count ?? 0;
    if (usedCount >= maxPerCustomer) {
        return 0;
    }
    else {
        return maxPerCustomer - usedCount;
    }
}

// TODO: Write test
export const getDealsAvailableProvider = (
    catalogDiscounts: Deal[],
    authProviders: SignInProviders[]
): DealsForProvider[] => {

    const deals: DealsForProvider[] = []

    authProviders.forEach(provider => {
        const dealsFiltered = catalogDiscounts.filter(deal =>
            deal.usage_restriction?.authentication_providers?.includes(provider)
            && deal.lines.length === 1
        );

        if (dealsFiltered && dealsFiltered.length > 0) {
            deals.push({
                auth_provider: provider,
                deals: dealsFiltered
            })
        }
    })

    return deals
}

export const getDealLinePricingValueString = (dealLine: DealLine): string => {
    if (
        dealLine.pricing_effect === PricingEffect.EFFECT_PRICE_OFF
        || dealLine.pricing_effect === PricingEffect.EFFECT_FIXED_PRICE
    ) {
        if (dealLine.pricing_value) {
            const pricingValue = dealLine.pricing_value as string;
            return MoneyToStringWithSymbol(pricingValue);
        } else {
            log.error(`DealLine doesn't have pricing value`);
            return "";
        }
    } else if (dealLine.pricing_effect === PricingEffect.EFFECT_PERCENTAGE_OFF) {
        if (dealLine.pricing_value) {
            return `${dealLine.pricing_value} %`
        } else {
            log.error(`DealLine doesn't have pricing value`);
            return "";
        }
    } else {
        log.error(`Price unchanged`);
        return "";
    }
}

//Add reduced_price field to all sku included in an available deals
export const updateServiceTypePricesInCatalog = (
    catalog: CatalogExtended,
    serviceType: SupportedServiceType | null,
    signinProvider: SignInProviders | null,
    customer: Customer | OrderContributor | null,
    tableArea: string | undefined
) => {
    const now = moment();

    if (catalog.data.deals) {
        catalog.data.deals.forEach((deal) => {
            const dealStartingPriceAndSkus = computeStartingPriceAndMaxPrice(deal, catalog)
            if (dealStartingPriceAndSkus) {
                deal.starting_price = dealStartingPriceAndSkus.minPrice
                deal.max_price = dealStartingPriceAndSkus.maxPrice
                deal.min_price_skus = dealStartingPriceAndSkus.minPriceOrderItems
            }
            log.debug(`Deal ${deal.ref} starting price: ${deal.starting_price}`);
        });
    }

    let products: any[] = catalog.data.products;

    // Applies price overrides if they match the restrictions
    if (serviceType) {
        products.forEach((product) => {
            product.skus.forEach((sku: Sku) => {
                changeSkuPriceIfPriceOverridesApplies(sku, serviceType, now, getTimezoneName(catalog), tableArea);
            });
        });
    }

    const singleDeals: Deal[] = catalog.data.deals.filter((deal: Deal) => deal.lines.length === 1)

    log.debug(`Deals found: ${singleDeals.length}`)

    //Every sku in singleDeals
    singleDeals.forEach((deal: Deal) => {

        const canApply = isDealMatchingRestrictions(deal, catalog, now, serviceType, signinProvider, customer)

        deal.lines.forEach((line: DealLine, lineIndex: number) => {
            line.skus.forEach((sku: DealSku) => {
                //Go through porducts sku until match
                products.some((product: Product) => {
                    const ok = product.skus.some((skuProd: SkuExtended) => {
                        if (skuProd.ref === sku.ref) {
                            if (canApply) {
                                skuProd.reduced_price = getDealLinePrice(skuProd, deal.lines[lineIndex], sku.extra_charge, catalog.currency);
                                skuProd.deal_ref = deal.ref;
                            } else {
                                if (skuProd.reduced_price) {
                                    delete skuProd.reduced_price;
                                }
                                if (skuProd.deal_ref) {
                                    delete skuProd.deal_ref;
                                }
                            }
                            return true;
                        }
                        return false;
                    });
                    if (ok) {
                        return true;
                    }
                    return false;
                });
            });
        });
    });
}