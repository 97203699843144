import { CloudDownload } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Link, Rating, Typography, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getDefaultOrderErrorSubtitle, getDefaultOrderErrorTitle } from '../../Common/components/CommonOrderErrorPage';
import LoaderComponent from '../../Common/components/LoaderComponent';
import ThemeSvg from '../../Common/components/ThemeSvg';
import { CommonActions } from '../../Common/redux/CommonActions';
import { downloadFile } from '../../Common/services/CommonServices';
import log from '../../Common/services/LogService';
import * as ROUTES from '../../config/routes';
import { desktopDisplayBreakpoint } from '../../config/theme';
import { getTimezoneName, Location, SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderInBase, OrderStatus, PaymentType } from '../../my-lemonade-library/model/Order';
import { getCurrency, MoneyToStringWithSymbol, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import ConnectorStatus from '../../my-lemonade-library/src/connectors/models/ConnectorStatus';
import { CONNECTORS_COMPATIBLE_CONNECTOR_WALLET } from '../../my-lemonade-library/src/connectors/models/ConnectorWallet';
import LocationLinkRelationType from '../../my-lemonade-library/src/locations/models/LocationLinkRelationType';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { PAYMENT_ID_PARAM_KEY } from '../../my-lemonade-library/src/payments/configs/PaymentsApiRoutes';
import { PaymentStatus } from '../../my-lemonade-library/src/payments/models/PaymentStatus';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { qrcodeLinkKey } from '../../my-lemonade-library/src/qrcodes/models/OrderQrCodes';
import { Review } from '../../my-lemonade-library/src/reviews/models/Review';
import { ImageMode } from '../../my-lemonade-library/src/theme/models/LocationTheme';
import paymentsActions from '../../Payment/redux/PaymentsActions';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import ReviewRatingDialog from '../../reviews/components/ReviewRatingDialog';
import { reviewActions } from '../../reviews/redux/ReviewActions';
import OrderInvoiceDialog from '../components/OrderInvoiceDialog';
import { LOADING_ORDER_TIMEOUT } from '../configs/OrderConfig';
import { ORDER_ID_QUERY_PARAM_NAME } from '../configs/OrdersRouterConfig';
import { loadOrderLinkData } from '../helpers/OrderHelpers';
import OrderAction from '../redux/OrderActions';
import { shouldRedirectToSharePayment } from '../services/OrderService';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * Final screen after send an Order
 */
interface OrderPreparationPageProps {
    isPayment?: boolean
}

const OrderPreparationPage: React.FC<OrderPreparationPageProps> = (props) => {

    const { isPayment } = props;

    const history = useHistory()
    const dispatch = useDispatch()

    const params = useParams<ROUTES.RouteParams>();
    const intl = useIntl();

    const theme = useTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up(desktopDisplayBreakpoint));

    const query = useQuery();
    const orderIdURL = query.get(ORDER_ID_QUERY_PARAM_NAME);
    const paymentIdURL = query.get(PAYMENT_ID_PARAM_KEY);

    const { currentLang } = useTypedSelector((state: RootState) => state.lang);
    const { selectedLocation, selectedTable, tableLinkId } = useTypedSelector((state: RootState) => state.locations);
    const { order: orderFromState, initOrders: initOrdersState } = useTypedSelector((state: RootState) => state.order);
    const { user_authentication_state } = useTypedSelector((state: RootState) => state.authentication.data);
    const { connector_wallet } = useTypedSelector((state: RootState) => state.payment);


    const orderToCreditWallet = selectedLocation ? paymentHelper.isOrderToCreditWallet(selectedLocation, orderFromState) : false;

    const isLyramarketplaceEnabled = selectedLocation?.supported_payment_types?.some(p => p.type === PaymentType.LYRA_MARKETPLACE);

    const [orderToDisplay, setOrderToDisplay] = useState<OrderInBase>();

    const [qrCodeData, setQrCodeData] = useState<string>();
    const [openInvoiceModal, setOpenInvoiceModal] = useState<boolean>(false);
    const [openRatingModal, setOpenRatingModal] = useState<boolean>(false);
    const [ratingValue, setRatingValue] = useState<number>(0);
    const [copiedMessage, setCopiedMessage] = useState<boolean>(false);

    const connectorType = selectedLocation?.connector?.type;

    /**
     * This state tells us if we have to wait before displaying the order. While waiting,
     * we show a loader. There is a timeout which ensures that there is no case to have this
     * loader indefinitely.
     * True by default, the logic or the end of timeout will change it to false.
     */
    const [waitingForValidation, setWaitingForValidation] = useState<boolean>(true);

    /**
     * Set the order to display on this page. Can be the order in state,
     * or an order loaded from latestOrders.
     * The best case is to take the order from state, because it's
     * synced with firestore.
     */
    useEffect(() => {

        // The order that will be displayed (set at the end of this function)
        let finalOrderToDisplay: OrderInBase | undefined = undefined;

        // Should not happen, but just in case
        if (!orderIdURL) {
            finalOrderToDisplay = orderFromState as OrderInBase;
        }
        else {

            // Best case: we want to display the order which's already in state.
            if (orderFromState.id === orderIdURL) {
                finalOrderToDisplay = orderFromState as OrderInBase;
            }

            // Not the one in state? Let's find it in the latestOrders
            else {

                let orderFoundInLatestOrders: OrderInBase | undefined = undefined;
                for (const elem of initOrdersState.latestUserOrders) {
                    if (elem.id === orderIdURL) {
                        orderFoundInLatestOrders = elem;
                        break;
                    }
                }
                if (!orderFoundInLatestOrders) {
                    for (const elem of initOrdersState.latestTableOrders) {
                        if (elem.id === orderIdURL) {
                            orderFoundInLatestOrders = elem;
                            break;
                        }
                    }
                }

                if (orderFoundInLatestOrders) {
                    finalOrderToDisplay = orderFoundInLatestOrders;
                }
                else {
                    log.error(`Order ${orderIdURL} not in state, and not found in latestOrders`);
                }
            }
        }

        if (finalOrderToDisplay) {
            log.info("setting orderToDisplay", finalOrderToDisplay);
            setOrderToDisplay(finalOrderToDisplay);
        }

    }, [
        orderFromState,
        initOrdersState.latestTableOrders,
        initOrdersState.latestUserOrders,
        orderIdURL,
    ]);

    const isWaitingForValidation = useCallback(() => {
        return waitingForValidation;
    }, [waitingForValidation])

    const getPayment = useCallback(() => {
        return orderToDisplay?.payments?.find((orderPayment) => orderPayment.payment_intent_id === paymentIdURL);
    }, [orderToDisplay])

    /**
     * This useEffect is triggered when any modification is made to the order status or payments.
     * We then set a timeout (20s). When it's done, we set waitingForValidation to false.
     * To sum up, if the order stays the same for 20sec and it's not displayable, the timeout will be triggered and
     * an error page will be displayed.
     */
    useEffect(() => {
        if (selectedLocation && orderToDisplay) {
            if (!canDisplayOrderOrPayment(orderToDisplay, isPayment, paymentIdURL, selectedLocation)) {
                log.debug(`Setting timeout for order ${orderIdURL} (${orderToDisplay?.id}) and payment ${paymentIdURL}`)
                const timeoutId = setTimeout(() => {
                    // If still waiting for validation
                    if (isWaitingForValidation()) {
                        log.error(`Order preparation page timout reached for order ${orderIdURL} (${orderToDisplay?.id}) and payment ${paymentIdURL} (isPayment: ${isPayment}) (URL: ${window.location.href})`);
                        setWaitingForValidation(false)
                    } else {
                        log.debug(`Timout ended but order has been validated meanwhile`);
                    }
                }, LOADING_ORDER_TIMEOUT);

                return () => {
                    clearTimeout(timeoutId);
                };
            } else {
                log.debug(`Order ${orderIdURL} is already ready to display, no need to set timeout`)
                setWaitingForValidation(false);
            }
        }
    }, [
        selectedLocation,
        orderIdURL,
        paymentIdURL,
        orderToDisplay?.id,
        orderToDisplay?.status,
        JSON.stringify(orderToDisplay?.payments),  // Stringify because it's an array
        isWaitingForValidation,
    ]);


    /**
     * Load the QR code
     */
    useEffect(() => {

        if (orderToDisplay?.items && orderToDisplay.items.length) {

            if (orderToDisplay.links && !qrCodeData) {
                const qrCodeLink = orderToDisplay.links[qrcodeLinkKey];
                if (qrCodeLink && qrCodeLink.href) {
                    log.debug(`Loading qr code: ${qrCodeLink.href}`);
                    loadOrderLinkData(qrCodeLink.href, setQrCodeData);
                }
            }
        }

    }, [orderToDisplay]);

    const handleQrCodeClick = () => {
        if (qrCodeData && orderToDisplay) {
            downloadFile(qrCodeData, "QrCode", orderToDisplay.collection_code ?? orderToDisplay.display_id);
        }
    }

    /**
     * We're waiting for this function to return true to cut the loading and
     * display the order or payment.
     * It is useful for a payment which takes time to be validated for example.
     */
    const canDisplayOrderOrPayment = (
        order: Pick<OrderInBase, "status" | "payments"> | undefined,
        isPayment: boolean | undefined,
        paymentId: string | null,
        location: Location
    ): boolean => {

        if (order) {

            // -- PAYMENT --
            if (isPayment && paymentId) {

                const payment = order.payments?.find(payment => payment.payment_intent_id === paymentId);
                if (payment) {

                    if (payment.status !== PaymentStatus.PENDING) {
                        // If has a connector, wait for the payment to have been sent to the pos
                        if (payment.status !== PaymentStatus.REFUND && location.connector && location.connector.type) {
                            return Boolean(payment.connector_status);
                        } else {
                            return true;
                        }
                    }
                }
                else {
                    log.info("canDisplayOrderOrpayment: payment not found");
                }
            }

            // -- ORDER --
            else if (!orderService.isFullyEditable(order)) {
                return true;
            }
        }

        log.info("canDisplayOrderOrpayment: returning false", {
            isPayment,
            orderToDisplay,
            paymentIdURL,
        });

        return false;
    }

    const finalImage = (): JSX.Element | null => {

        const imageMode: ImageMode | undefined = selectedLocation?.theme?.images?.order_confirmation_image_mode
        const imageUrl: string | undefined = selectedLocation?.theme?.images?.order_confirmation_image;
        const locationLogo: string | undefined = selectedLocation?.logo_header;

        const payment = orderToDisplay?.payments?.find(payment => payment.payment_intent_id === paymentIdURL);
        if (
            orderToDisplay?.status === OrderStatus.REJECTED
            || orderToDisplay?.status === OrderStatus.CANCELLED
            || (
                isPayment
                && paymentIdURL
                && payment
                && (
                    payment.connector_status === ConnectorStatus.REJECTED
                    || payment.status === PaymentStatus.REFUND
                    || payment.status === PaymentStatus.UNPAID
                    || payment.status === PaymentStatus.PENDING
                )
            )
        ) {
            return <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/error.svg`} maxWidth={"80%"} />
        }
        else if (qrCodeData) {
            return <img src={qrCodeData} alt="" style={{ maxWidth: "100%" }} />
        }
        else if (imageMode === ImageMode.CUSTOM && imageUrl) {
            return <img src={imageUrl} alt="" style={{ maxWidth: "60%" }} />
        }
        else if (imageMode === ImageMode.LOGO && locationLogo) {
            return <img src={locationLogo} alt="" style={{ maxWidth: "30%" }} />
        }
        else if (imageMode === ImageMode.NONE) {
            return null
        }
        else {
            return <ThemeSvg svg={`${process.env.PUBLIC_URL}/assets/valid_order.svg`} maxWidth={"60%"} />
        }
    }

    /**
     * Returns true if there is a remaining amount to pay (and if it's not a table payment).
     */
    const isSharePayment = (): boolean => {

        if (
            orderToDisplay
            && orderToDisplay?.payment_type !== PaymentType.TABLE
            && paymentHelper.getOrderRemainingAmount(orderToDisplay) !== 0
        ) {
            return true;
        }
        return false;
    }

    /**
     * Get the 1st text, for example "thank you for you order"
     */
    const getFirstMessageId = (): string => {

        const payment = orderToDisplay?.payments?.find(payment => payment.payment_intent_id === paymentIdURL);
        if (isPayment && paymentIdURL && payment) {

            if (payment.status === PaymentStatus.UNPAID) {
                log.error(`Payment ${paymentIdURL} is unpaid`);
                return "orders.preparation.payment_failed";
            } else if (payment.status === PaymentStatus.REFUND) {
                log.error(`Payment ${paymentIdURL} has been refunded`);
                return "orders.preparation.payment_refunded";
            } else if (payment.status === PaymentStatus.PENDING) {
                log.error(`Payment ${paymentIdURL} is pending`);
                return "orders.preparation.payment_pending";
            }

            if (payment.connector_status === ConnectorStatus.REJECTED) {
                log.error(`Payment ${paymentIdURL} is rejected`);
                return "orders.preparation.payment_error_with_connector";
            }
        }

        if (orderToDisplay?.status === OrderStatus.REJECTED) {
            log.error(`Order ${orderToDisplay.id} is rejected`);
            return getDefaultOrderErrorTitle(selectedTable.service_type).value;
        }

        if (orderToDisplay?.status === OrderStatus.CANCELLED) {
            return "orders.preparation.cancelled";
        }

        if (orderToDisplay?.status === OrderStatus.NEW && !orderToCreditWallet) {
            return "orders.preparation.thanks";
        }

        if (isPayment) {
            return "orders.payment.thanks";
        }

        if (isSharePayment()) {
            return "orders.share.thanks"
        }

        return "orders.preparation.thanks";
    }

    /**
     * Get the id for intl corresponding to the 2nd text, the one below the "thank you for you order"
     */
    const getSecondMessage = (): string | undefined => {

        const payment = orderToDisplay?.payments?.find(payment => payment.payment_intent_id === paymentIdURL);
        // Error cases
        if (orderToDisplay &&
            (
                orderToDisplay.status === OrderStatus.REJECTED || orderToDisplay.status === OrderStatus.CANCELLED
                || (isPayment && paymentIdURL && payment && (payment.status === PaymentStatus.REFUND || payment.connector_status === ConnectorStatus.REJECTED)))
        ) {
            const errorMessage = getDefaultOrderErrorSubtitle(selectedLocation, selectedTable.service_type, intl).value;
            if (payment?.status === PaymentStatus.REFUND) {
                if (payment.connector_status === ConnectorStatus.REJECTED) {
                    return `${intl.formatMessage({ id: "orders.preparation.payment_refunded_detail_connector_failure" })} ${errorMessage}`;
                } else {
                    return `${intl.formatMessage({ id: "orders.preparation.payment_refunded_detail_generic_failure" })} ${errorMessage}`;
                }
            } else {
                return errorMessage;
            }
        }

        if (selectedLocation?.theme?.messages?.order_confirmation_message) {
            return selectedLocation.theme.messages.order_confirmation_message
        }
        else {

            if (orderToDisplay) {

                const amountStr = MoneyToStringWithSymbol(numberToMoney(
                    paymentHelper.getOrderRemainingAmount(orderToDisplay),
                    getCurrency(orderToDisplay.total)
                ));

                if (isSharePayment()) {
                    return intl.formatMessage({
                        id: "orders.share.subtitle"
                    }, {
                        amount: `<span style="font-weight: bold">${amountStr}</span>`
                    });
                }
            }

            if (!isPayment) {

                if (orderToDisplay?.service_type === SupportedServiceType.EAT_IN) {
                    return intl.formatMessage({
                        id: "orders.preparation.subtitle_eatin"
                    });
                }
                else {
                    if (qrCodeData) {
                        return intl.formatMessage({
                            id: "orders.preparation.subtitle_collection_qr_code"
                        });
                    } else {
                        return intl.formatMessage({
                            id: "orders.preparation.subtitle_collection"
                        },
                            {
                                spamWarning: `<span style="color: ${theme.palette.primary.main}">${intl.formatMessage({ id: "orders.preparation.subtitle_collection_spam_warning" })}</span>`
                            }

                        )
                    }

                }
            }
        }
    }

    /**
     * Sometimes orderToDisplay return date in string or to format Date
     * This function return date for all case
     * TODO: investigate and choose one format always true to avoid this kind of function !
     * @param date 
     * @returns 
     */
    const getDate = (date: any): Date => {
        const isDate = DateTime.fromJSDate(date).isValid;
        if (isDate) {
            return date
        } else {
            return DateTime.fromISO(date).toJSDate();
        }
    }

    const service = orderToDisplay?.service_type;
    let expectedTime = orderToDisplay?.expected_time ? getDate(orderToDisplay.expected_time) : null;
    if (orderToDisplay?.confirmed_time) {
        expectedTime = getDate(orderToDisplay.confirmed_time);
    }

    if (!orderToDisplay) {
        return null;
    }

    if (waitingForValidation) {
        return (
            <LoaderComponent />
        );
    }

    const handleRatingChange = (event: React.ChangeEvent<{}>, newValue: number | null) => {
        if (newValue === null || !selectedLocation) {
            return;
        }
        setRatingValue(newValue);

        // If the rating is less than 4, we open the modal to ask for a review
        if (newValue < 4) {
            setOpenRatingModal(true);
            return;
        }
        const googleReviewLink = selectedLocation.links?.find(
            (link) => link.rel === LocationLinkRelationType.GOOGLE_REVIEW
        );

        if (
            !user_authentication_state ||
            !user_authentication_state.user ||
            !user_authentication_state.user.uid
        ) {
            return;
        }

        const review: Review = {
            user_id: user_authentication_state.user.uid,
            location_id: selectedLocation.id,
            account_id: selectedLocation.account_id,
            created_at: new Date(),
            last: {
                rating: newValue,
                text_review: "",
                created_at: new Date(),
            },
        };
        dispatch(reviewActions.createLocationReview(review));

        // If the rating is 4 or 5 and the google review link is filled, we open the google review link
        if (googleReviewLink) {
            window.open(googleReviewLink.href, "_blank");
        }
    };

    const displayReviewEatIn = selectedLocation?.require_customer_info?.eat_in?.review;
    const displayReviewCheckout = selectedLocation?.require_customer_info?.checkout?.review;

    const renderBackToCatalogButton = () => {

        const onClickFcn = () => {

            // Info: dialog backdrop click and escape keydown has been disabled

            // In the case of a credit wallet payment, we don't want to go back to catalog
            if (orderToCreditWallet) {

                // We need to send a message to the parent window when the child window is closing
                // to be able to refresh the parent window and get the new wallet balance
                window.addEventListener('beforeunload', function (event) {
                    window.opener.postMessage('childWindowClosing', '*');
                });
                window.close();

            }

            if (
                (orderFromState.customer?.email || orderFromState.customer?.phone)
                && connectorType
                && CONNECTORS_COMPATIBLE_CONNECTOR_WALLET.includes(connectorType)
                && connector_wallet[connectorType] !== undefined
            ) {
                dispatch(paymentsActions.getCustomerConnectorWalletBalance(orderFromState.customer.email, orderFromState.customer.phone));
            }

            dispatch(OrderAction.closeModal());

            // Refresh the Lyramarketplace user saved data
            if (
                selectedLocation
                && user_authentication_state.user?.uid
                && isLyramarketplaceEnabled
            ) {
                dispatch(paymentsActions.isTherePaymentData(selectedLocation.account_id, selectedLocation.id, [PaymentType.LYRA_MARKETPLACE], user_authentication_state.user?.uid));
            }

            // Reset order neither can pay, nor can pay
            let keptOrderId: string | undefined = undefined
            if (
                !selectedLocation
                || (
                    !orderService.canAddItems(selectedLocation, orderToDisplay, false)
                    && !paymentHelper.canPay(selectedLocation, orderToDisplay)
                )
            ) {
                dispatch(OrderAction.resetOrder());
                dispatch(OrderAction.loadOrders(true));
            }
            else {
                keptOrderId = orderToDisplay.id
            }

            history.push(ROUTES.getHomeFullRoute(params.tableLinkKey, keptOrderId));
        }

        const buttonText = orderToCreditWallet ? intl.formatMessage({ id: "orders.preparation.backToParentPage" }) : intl.formatMessage({ id: "orders.preparation.backToCatalog" });
        const dataTest = orderToCreditWallet ? "preparation_backToParentPage" : "preparation_backToCatalog";

        if (isSharePayment()) {
            return (
                <Link
                    onClick={onClickFcn}
                    color="primary"
                    data-test={dataTest}
                    underline='always'
                >
                    {buttonText}
                </Link>
            );
        }

        return (
            <Button
                variant="contained"
                color="primary"
                style={{
                    padding: theme.spacing(1.45, 0),
                    width: "65%",
                    textTransform: "none",
                }}
                data-test={dataTest}
                onClick={onClickFcn}
            >
                <Typography>
                    {buttonText}
                </Typography>
            </Button>
        );
    }

    // We can't set this paddingX to the 1st box because we need to have a full width white background div
    // in the middle in case of share payment
    const globalPadding = 4;

    return (

        <>

            {openInvoiceModal &&
                <OrderInvoiceDialog
                    closeModal={() => setOpenInvoiceModal(false)}
                    order={orderToDisplay}
                    paymentIntentId={paymentIdURL ?? undefined}
                />
            }

            <Box
                height="100%"
                paddingY={globalPadding}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                data-test="orderPreparation_root"
                bgcolor={isSharePayment() ? theme.palette.background.default : undefined}  // Grey background to make the central part appear (white bg)
            >

                {
                    // CENTER CONTENT (GLASSES, MAIN TEXT, SECONDARY TEXT)
                }
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    flex={1}
                >

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        paddingX={globalPadding}
                    >

                        <Box
                            order={(service === SupportedServiceType.COLLECTION || service === SupportedServiceType.DELIVERY) ? 1 : 3}
                        >
                            <Box mt={5} mx={3}>
                                <Typography
                                    variant="h2"
                                    style={{ textAlign: "center" }}
                                    data-test={`orderPreparation.firstMessage.id_${getFirstMessageId()}`}
                                >
                                    {intl.formatMessage({ id: getFirstMessageId() })}
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            order={2}
                        >
                            {finalImage()}
                        </Box>
                    </Box>

                    <Box
                        width={1}
                        mt={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={2}
                        style={{
                            backgroundColor: isSharePayment() ? theme.palette.background.paper : undefined,
                        }}
                        paddingX={globalPadding}
                        paddingY={isSharePayment() ? 4 : undefined}
                    >

                        <Typography
                            data-test="orderPreparation_secondMessage"
                            style={{
                                whiteSpace: "pre-wrap",
                                textAlign: "center",
                            }}
                            dangerouslySetInnerHTML={{ __html: getSecondMessage() ?? "" }}  // Only way to have a bold span in the text
                        />

                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                        >
                            {isSharePayment() &&
                                <Button
                                    variant="contained"
                                    style={{
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        dispatch(CommonActions.setRedirectURL(ROUTES.getPaymentChoiceFullRoute(tableLinkId)));
                                    }}
                                    data-test="orderPreparation_sharePayment_rest_Button"
                                >
                                    {intl.formatMessage({ id: "orders.share.button.pay_the_rest" })}
                                </Button>
                            }

                            {isSharePayment()
                                && shouldRedirectToSharePayment(
                                    orderToDisplay,
                                    getPayment()?.payment_type ?? PaymentType.OTHER_CB,
                                    selectedLocation?.country,
                                    selectedLocation?.enable_share_payment
                                ) &&

                                <>
                                    <Typography>{intl.formatMessage({ id: "common.or.label" })}</Typography>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            padding: .75,
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (orderToDisplay) {
                                                const linkToCopy: string = window.location.origin
                                                    + `/${tableLinkId}/summary?${ORDER_ID_QUERY_PARAM_NAME}=${orderToDisplay.id}`;
                                                navigator.clipboard.writeText(linkToCopy)
                                                    .then(() => {
                                                        setCopiedMessage(true);
                                                        setTimeout(() => { setCopiedMessage(false) }, 5000);
                                                    }, (err) => {
                                                        console.error('Async: Could not copy text: ', err);
                                                    });
                                            }
                                        }}

                                    >
                                        <ShareIcon />
                                    </Box>
                                </>
                            }

                        </Box>

                        {copiedMessage &&
                            <Typography
                                color="primary"
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {intl.formatMessage({ id: "orders.preparation.copied" })}
                            </Typography>
                        }

                    </Box>

                    {
                        expectedTime
                        && (service === SupportedServiceType.DELIVERY || service === SupportedServiceType.COLLECTION)
                        && orderToDisplay?.status !== OrderStatus.REJECTED
                        && orderToDisplay?.status !== OrderStatus.CANCELLED
                        &&

                        <Box
                            width={1}
                            mt={4}
                            paddingY={2}
                            paddingX={globalPadding + 2}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            style={{
                                border: `1px solid ${theme.palette.grey[400]}`,
                                borderRadius: "10px"
                            }}
                        >
                            <Typography style={{ margin: theme.spacing(0.5) }}>
                                <FormattedMessage id={`orders.preparation.expected_time.${service}`} />
                            </Typography>

                            <Typography>
                                {DateTime.fromJSDate(expectedTime)
                                    .setZone(getTimezoneName(selectedLocation))
                                    .setLocale(currentLang)
                                    .toLocaleString(DateTime.DATE_HUGE)
                                }
                            </Typography>

                            <Typography
                                data-test='expected-time'
                                variant='h1'
                                style={{
                                    margin: theme.spacing(1, 1, 0.5, 1)
                                }}
                            >
                                {DateTime.fromJSDate(expectedTime)
                                    .setZone(getTimezoneName(selectedLocation))
                                    .setLocale(currentLang)
                                    .toLocaleString(DateTime.TIME_SIMPLE)
                                }
                            </Typography>
                        </Box>
                    }

                    {  // RATING
                        (
                            (service === SupportedServiceType.EAT_IN && displayReviewEatIn)
                            || (service === SupportedServiceType.CHECKOUT && displayReviewCheckout)
                        )
                        && !isSharePayment()
                        &&

                        <Box
                            data-test="order_rating"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mt={2}
                            paddingX={globalPadding}
                            gap={2}
                        >
                            <Typography
                                variant="subtitle1"
                            >
                                {intl.formatMessage({ id: "order.rating.title" })}
                            </Typography>
                            <Rating
                                data-test="order_rating_stars"
                                size="large"
                                value={ratingValue}
                                onChange={handleRatingChange}
                                style={{
                                    color: theme.palette.primary.main,
                                }}
                            />
                        </Box>
                    }

                    {
                        openRatingModal &&
                        <ReviewRatingDialog
                            open={openRatingModal}
                            onClose={() => setOpenRatingModal(false)}
                            ratingValue={ratingValue}

                        />
                    }
                </Box>


                {
                    // BOTTOM CONTENT: BUTTONS, QR CODE
                }
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    px={globalPadding + 2}
                    mt={isDesktopRender ? 6 : 0}
                >

                    {qrCodeData &&

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleQrCodeClick}
                            style={{
                                padding: theme.spacing(1.45, 0),
                                width: "65%",
                                textTransform: "none",
                                marginBottom: theme.spacing(2)
                            }}
                        >
                            <CloudDownload style={{ marginRight: theme.spacing(1) }} />

                            <Typography>
                                {intl.formatMessage({ id: "orders.qrcode" })}
                            </Typography>

                        </Button>
                    }

                    {(isPayment && getPayment()?.status === PaymentStatus.PAID) &&

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                if (orderToDisplay) {
                                    let foundPaymentInvoiceUrl: string | null | undefined = null;
                                    if (paymentIdURL) {
                                        foundPaymentInvoiceUrl = getPayment()?.invoice_url;
                                    }
                                    if (foundPaymentInvoiceUrl) {
                                        window.open(foundPaymentInvoiceUrl);
                                    } else {
                                        // Open modal to generate it
                                        setOpenInvoiceModal(true)
                                    }
                                }
                            }}
                            style={{
                                padding: theme.spacing(1.45, 0),
                                width: "65%",
                                textTransform: "none",
                                marginBottom: theme.spacing(2)
                            }}
                        >

                            <CloudDownload style={{ marginRight: theme.spacing(1) }} />

                            <Typography>
                                {intl.formatMessage({ id: "orders.preparation.invoice.button" })}
                            </Typography>

                        </Button>
                    }

                    {renderBackToCatalogButton()}
                </Box>
            </Box>
        </>
    );
}

export default OrderPreparationPage
