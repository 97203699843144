/**
 * Payment status for the order itself
 * Different than a Payment status because can be partially paid
 */
export enum OrderPaymentStatus {
    PAID = "paid",
    UNPAID = "unpaid",
    PENDING = "pending",
    FAILED = "failed",
    PARTIALLY_PAID = "partially_paid",
    REFUNDED = "refunded"
}