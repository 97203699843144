import { Delete, Edit } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Icon, IconButton, Typography, useTheme } from '@mui/material';
import { DateTime } from "luxon";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DeviceContext } from '../../App';
import UserHeaderButton from '../../authentication/components/UserHeaderButton';
import ConfirmationActionModal from '../../Common/components/ConfirmationActionModal';
import DeliveryAndCheckoutDelay from '../../Common/components/toolbar/DeliveryAndCheckoutDelay';
import { getDateStr } from '../../Common/helper/DateHelper';
import { CommonActions } from '../../Common/redux/CommonActions';
import { cursor, List } from '../../Common/StyledComponents';
import * as ROUTES from '../../config/routes';
import { DeviceContextModel } from '../../config/useDeviceContext';
import { getLocationFullAddress } from '../../Locations/helpers/LocationHelpers';
import { Catalog } from '../../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderInBase } from '../../my-lemonade-library/model/Order';
import { getFullAddressFromCustomer } from '../../my-lemonade-library/src/customers/services/CustomerHelper';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { useTypedSelector } from '../../redux/root-reducer';
import DeliveryAddressChangeModal from '../pages/DeliveryAddressChangeModal';
import OrderAction from '../redux/OrderActions';

/**
 * Global Header page
 * 
 * @param props 
 */
// TODO: extract back() and pass a props onClose instead of desktop
interface OrderHeaderProps {
    titleId: any;
    onClearIconClick?: () => void;
    closeIcon?: boolean;
    backgroundColor?: string;
    catalog?: Catalog;
    readOnly?: boolean;
    fromSummaryPage?: boolean;
    hideTable?: boolean;
    resetOrderOnBack?: boolean;
    onlinePaymentMessage?: boolean;
    backToCatalog?: boolean;
    accountButton?: boolean;
    backTwice?: boolean;
    orderToDisplay?: OrderInBase | null | undefined;
}

const OrderHeader: React.FC<OrderHeaderProps> = (props) => {

    const {
        titleId,
        closeIcon,
        backgroundColor,
        fromSummaryPage,
        hideTable,
        resetOrderOnBack,
        onlinePaymentMessage,
        backToCatalog,
        backTwice,
        onClearIconClick,
        orderToDisplay,
    } = props;


    const [openAddress, setOpenAddress] = useState<boolean>(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
    const { mobile_device, tablet_device, desktop_device } = React.useContext<DeviceContextModel>(DeviceContext)

    const intl = useIntl();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { selectedCatalog, selectedLocation, selectedTable, tableLinkId } = useTypedSelector(state => state.locations);
    const { order } = useTypedSelector(state => state.order);
    const isDeliveryOrCollection: boolean = order?.service_type === SupportedServiceType.DELIVERY || order?.service_type === SupportedServiceType.COLLECTION

    // Override the readOnly if checkout or view service_type
    let readOnly = props.readOnly;
    if (selectedTable.service_type === SupportedServiceType.CHECKOUT || selectedTable.service_type === SupportedServiceType.VIEW) {
        readOnly = true;
    }

    const onConfirmationModalAction = () => {
        if (selectedLocation && orderService.canAddItems(selectedLocation, order, false)) {
            dispatch(OrderAction.resetItems());
            // TODO: go to home?
        } else {
            dispatch(OrderAction.resetOrder());
        }
        setOpenConfirmationModal(false);
        dispatch(CommonActions.setRedirectURL(ROUTES.getCategoriesFullRoute(tableLinkId)));
    }
    const onCloseConfirmationModal = () => {
        setOpenConfirmationModal(false)
    }

    /**
     * Open the modal for the address modification
     */
    const openModalAddress = () => {
        setOpenAddress(true);
    }

    const orderToCreditWallet = selectedLocation ? paymentHelper.isOrderToCreditWallet(selectedLocation, order) : false;

    /**
     * When the arrow back icon is pressed
     */
    const back = () => {
        if (orderToCreditWallet) {
            window.close();
        }

        if (resetOrderOnBack) {
            dispatch(OrderAction.resetOrder());
            dispatch(OrderAction.loadOrders());
        }
        else {
            dispatch(OrderAction.resetOrderPayments());
        }

        if (backToCatalog) {
            history.push(ROUTES.getCategoriesFullRoute(tableLinkId))
            dispatch(OrderAction.closeModal());
        }
        else if (backTwice) {
            history.go(-2);
        }
        else {
            history.goBack();
        }
    }


    return (
        <List
            pt={2}
            pb={2}
            style={{
                position: fromSummaryPage ? 'unset' : 'sticky',
                top: fromSummaryPage ? 'auto' : 0,
                backgroundColor: backgroundColor ? backgroundColor : theme.palette.background.default,
                zIndex: 10
            }}
        >
            <ConfirmationActionModal
                open={openConfirmationModal}
                onClose={onCloseConfirmationModal}
                onConfirmAction={onConfirmationModalAction}
                titleMessageId="order.confirmation_modal.deletion.title"
                textMessageId="order.confirmation_modal.deletion.text"
            />

            {
                // HEADER: TITLE & BACK/DELETE BUTTONS
            }
            <Box
                display="flex"
                justifyContent={(!readOnly || closeIcon) ? "space-between" : "center"}
                alignItems="center"
                width="100%"
                position="relative"
                px={3}
            >
                {mobile_device &&
                    <IconButton
                        onClick={() => {
                            if (closeIcon) {
                                back();
                            }
                        }}
                        style={{
                            padding: 0,
                            opacity: closeIcon ? 1 : 0,
                            color: theme.palette.grey[500]
                        }}
                        data-test="order_header.back_button"
                        size="large">
                        <ArrowBackIosIcon sx={{ color: theme.palette.secondary.main }} />
                    </IconButton>}

                <Box
                    display='flex'
                    flex={1}
                    flexDirection='column'
                    alignItems='center'
                >

                    <Typography
                        data-test="order_header_title"
                        variant={isDeliveryOrCollection && mobile_device ? 'h5' : 'h2'}
                        color="textSecondary"
                        style={{ textAlign: "center" }}
                    >
                        {titleId}
                    </Typography>
                    {isDeliveryOrCollection &&
                        <DeliveryAndCheckoutDelay
                            parentName='order_header'
                        />
                    }
                </Box>

                {(tablet_device || onClearIconClick)
                    ? <IconButton
                        onClick={() => {
                            if (onClearIconClick) {
                                onClearIconClick()
                            } else if (closeIcon && !orderToCreditWallet) {
                                back();
                            }
                        }}
                        style={{
                            padding: 0,
                            opacity: closeIcon || onClearIconClick ? 1 : 0,
                            color: theme.palette.grey[500],
                            right: 0,
                            top: 0
                        }}
                        size="large"
                    >
                        <ClearIcon />
                    </IconButton>
                    : (mobile_device && !onClearIconClick)
                        ? <IconButton
                            onClick={() => {
                                if (!readOnly) {
                                    setOpenConfirmationModal(true)
                                }
                            }}
                            style={{
                                padding: 0,
                                opacity: readOnly ? 0 : 1,
                                color: theme.palette.grey[500]
                            }}
                            size="large"
                            data-test="order_header_delete_button"
                        >
                            <Delete />
                        </IconButton>
                        : tablet_device
                            ? <IconButton
                                onClick={() => {
                                    if (closeIcon && !orderToCreditWallet) {
                                        back();
                                    }
                                }}
                                style={{
                                    padding: 0,
                                    opacity: closeIcon ? 1 : 0,
                                    color: theme.palette.grey[500],
                                    position: "absolute",
                                    right: 0,
                                    top: 0
                                }}
                                size="large">
                                <ClearIcon />
                            </IconButton>
                            : ""
                }

                {props.accountButton && !selectedLocation?.theme?.extended_theme_options?.hide_account_button &&
                    <UserHeaderButton />
                }
            </Box>

            {
                // Customer notes, table name, delivery info, ...
                <Box
                    width={1}
                >
                    {
                        /**
                         * Displaying the table number
                         */
                        !(desktop_device || (order && order.service_type !== SupportedServiceType.EAT_IN)
                            || history.location.pathname.includes('/location/contact'))
                        && !hideTable && order.items.length > 0
                        && !readOnly
                        &&

                        <Box
                            width={1}
                            mt={2}
                            px={3}
                            py={2}
                            display='flex'
                            alignItems='center'
                            style={{ backgroundColor: theme.palette.background.paper }}
                        >
                            <RestaurantOutlinedIcon color='primary' style={{ marginRight: theme.spacing(1) }} />
                            <Typography variant="body1" color="textPrimary">
                                {order.table_name ?? selectedTable.name ?? selectedTable.ref.replace('_', ' ')}
                            </Typography>
                        </Box>
                    }

                    {
                        /**
                         * Displaying the order info and table number
                         * NOTE: only happens in readOnly
                         */
                        (
                            !desktop_device
                            && !history.location.pathname.includes('/location/contact')
                        )
                        && !hideTable
                        && orderToDisplay
                        && orderToDisplay.items.length > 0
                        && readOnly
                        &&

                        <Box
                            width={1}
                            mt={2}
                            px={3}
                            py={2}
                            display='flex'
                            alignItems='center'
                            style={{ backgroundColor: theme.palette.background.paper }}
                        >
                            <RestaurantOutlinedIcon color='primary' style={{ marginRight: theme.spacing(1.5) }} />
                            <Box display='flex' flexDirection='column'>
                                <Box display="flex">
                                    <Typography variant="body1">
                                        {getDateStr(orderToDisplay.created_at, intl)[0]}
                                    </Typography>
                                    <Typography style={{ margin: theme.spacing(0, 1) }}> {"·"}</Typography>
                                    <Typography>
                                        {getDateStr(orderToDisplay.created_at, intl)[1]}
                                    </Typography>
                                </Box>
                                <Box display="flex">
                                    {
                                        (
                                            !orderToDisplay
                                            || orderToDisplay.service_type === SupportedServiceType.EAT_IN
                                            || orderToDisplay.service_type === SupportedServiceType.CHECKOUT
                                        )
                                        &&
                                        <Box display="flex">
                                            <Typography variant="body1">
                                                {orderToDisplay.table_name ?? (selectedTable.name ?? selectedTable.ref.replace('_', ' '))}
                                            </Typography>
                                            <Typography style={{ margin: theme.spacing(0, 1) }}> {"·"}</Typography>
                                        </Box>
                                    }
                                    <Typography>
                                        {`${intl.formatMessage({ id: "Summary.orderLabel" })}: ${(orderToDisplay.collection_code && orderToDisplay.collection_code !== "") ? orderToDisplay.collection_code : orderToDisplay.display_id}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    }

                    {
                        /**
                         * Displaying the delivery address
                         */
                        (selectedTable &&
                            selectedTable.service_type === SupportedServiceType.DELIVERY &&
                            fromSummaryPage &&
                            !readOnly) &&

                        <Box
                            width={1}
                            px={3}
                            py={2}
                            mt={2}
                            display='flex'
                            alignItems='center'
                            style={{ backgroundColor: theme.palette.background.paper }}
                        >
                            <Box display="flex" alignItems={"center"} flex={1}>
                                <DirectionsBikeOutlinedIcon color='primary' style={{ marginRight: theme.spacing(1) }} />
                                <Box display='flex' flexDirection='column'>
                                    {order.expected_time &&
                                        <Typography variant='body1'>
                                            {`${intl.formatDate(order.expected_time, { weekday: 'short', day: 'numeric', month: 'short' })}
                                            , ${DateTime.fromJSDate(order.expected_time).toLocaleString(DateTime.TIME_24_SIMPLE)}`}
                                        </Typography>
                                    }
                                    <Typography variant='body1'>
                                        {getFullAddressFromCustomer(order.customer)}
                                    </Typography>
                                </Box>
                            </Box>
                            {!selectedTable?.delivery_restrictions?.address && <IconButton
                                size={'small'}
                                style={{
                                    ...cursor,
                                    color: theme.palette.text.disabled,
                                }}
                                onClick={() => { openModalAddress() }}
                            >
                                <Edit />
                            </IconButton>}
                        </Box>
                    }

                    {
                        /**
                         * Displaying the collection address
                         */
                        (selectedTable &&
                            selectedTable.service_type === SupportedServiceType.COLLECTION &&
                            fromSummaryPage &&
                            !readOnly) &&
                        selectedLocation &&

                        <Box
                            width={1}
                            px={3}
                            py={2}
                            mt={2}
                            display='flex'
                            alignItems='center'
                            style={{ backgroundColor: theme.palette.background.paper }}
                        >
                            <LocalGroceryStoreOutlinedIcon color='primary' style={{ marginRight: theme.spacing(1) }} />
                            <Box display='flex' flexDirection='column'>
                                {order.expected_time &&
                                    <Typography variant='body1'>
                                        {DateTime.fromJSDate(order.expected_time).toLocaleString(DateTime.DATETIME_MED)}
                                    </Typography>
                                }
                                <Typography variant='body1'>
                                    {selectedLocation.name}
                                </Typography>
                                <Typography variant='body1'>
                                    {getLocationFullAddress(selectedLocation)}
                                </Typography>
                            </Box>
                        </Box>
                    }

                    {
                        // online payment information
                        onlinePaymentMessage &&

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            mt={2}
                        >

                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <Icon
                                    component={'span'}
                                    style={{
                                        color: theme.palette.primary.main,
                                        padding: 0,
                                        width: 'fit-content',
                                        margin: theme.spacing(0, 2, 0, 0)
                                    }}
                                >
                                    <WarningIcon />
                                </Icon>
                            </Box>


                            <Typography
                                variant="caption"
                                style={{
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {intl.formatMessage({ id: "payment.onlinePayment.3DSWarning" })}

                            </Typography>

                        </Box>
                    }
                    {selectedLocation && selectedCatalog &&
                        <DeliveryAddressChangeModal
                            open={openAddress}
                            setOpen={setOpenAddress}
                            desktop={desktop_device}
                            order={order}
                        />
                    }
                </Box>
            }

        </List >
    );

}

export default OrderHeader
