import { Close } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LaunchIcon from '@mui/icons-material/Launch';
import RedeemIcon from '@mui/icons-material/Redeem';
import { Alert, Box, Button, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import getSymbolFromCurrency from "currency-symbol-map";
import log from "loglevel";
import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router";
import TermsAndConditionsTextWithModal from "../../authentication/components/TermsAndConditionsTextWithModal";
import * as ROUTES from '../../config/routes';
import { WalletConfigurationType, WalletPercentageConfiguration, WalletStepConfiguration } from "../../my-lemonade-library/model/Location";
import { OrderInBase, PaymentType } from "../../my-lemonade-library/model/Order";
import { DEFAULT_CURRENCY, Money, MoneyToStringWithSymbol, numberToMoney, substractMoney } from "../../my-lemonade-library/src/common/models/Money";
import { fixPhone } from "../../my-lemonade-library/src/common/services/AddressService";
import { paymentHelper } from "../../my-lemonade-library/src/payments/services/PaymentHelper";
import { useTypedSelector } from "../../redux/root-reducer";
import paymentsActions from "../redux/PaymentsActions";
import paymentService from "../services/PaymentService";

export interface CreditWalletProps {
}

const CreditWallet: React.FC<CreditWalletProps> = () => {
    const theme = useTheme();
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const { tableLinkId } = useTypedSelector((state) => state.locations);
    const { order } = useTypedSelector((state) => state.order);
    const { selectedLocation } = useTypedSelector((state) => state.locations);
    const { connector_wallet } = useTypedSelector((state) => state.payment);
    const thisConnectorWallet = selectedLocation?.connector?.type ? connector_wallet[selectedLocation.connector.type] : undefined;
    const walletBalance = thisConnectorWallet?.wallet_balance ?? undefined;
    const currency = selectedLocation?.currency ?? DEFAULT_CURRENCY;
    const [creditAmount, setCreditAmount] = useState<string>("");
    const [customerEmail, setCustomerEmail] = useState<string>(order.customer?.email ?? "");
    const [baseWalletBalance] = useState<Money>(walletBalance !== undefined ? walletBalance : numberToMoney(0, currency));
    const [customerPhone, setCustomerPhone] = useState<string>(order.customer?.phone ?? "");

    const locationWalletPaymentType = selectedLocation?.supported_payment_types.find((paymentType) => paymentType.type === PaymentType.CONNECTOR_WALLET);
    const finalCreditAmount: Money = creditAmount !== "" ? numberToMoney(parseFloat(creditAmount), currency) : numberToMoney(0, currency);
    const walletAmountComputed = paymentHelper.computeCreditWalletAmountByConfigType(finalCreditAmount, locationWalletPaymentType?.connector_wallet_configuration);
    const earnedAmount: Money = substractMoney(walletAmountComputed, finalCreditAmount);

    const isPositiveAmount = (amountString: string): boolean => {
        const amountValue = parseInt(amountString.replace(',', '.'));
        return amountValue !== 0 && !isNaN(amountValue) && amountValue > 0;
    };

    const isValidAmount = isPositiveAmount(finalCreditAmount.toString());

    const openNewWindowToSendPayment = (order: OrderInBase) => {
        const orderIdQueryParam = `?orderId=${order.id}`;
        const url = generatePath(ROUTES.getPaymentChoiceFullRoute(tableLinkId) + orderIdQueryParam);
        const childWindow = window.open(url, "_blank", "location=0");
        if (childWindow) {
            // Add an event listener for the beforeunload event in the child window to send a message to the parent window before closing
            childWindow.addEventListener('beforeunload', function (event) {
                // Send a message to the parent window
                window.opener.postMessage('childWindowClosing', '*');
            });

            // Add an event listener for the message event in the parent window
            window.addEventListener('message', function (event) {
                if (event.data === 'childWindowClosing') {
                    // The child window is closing
                    log.info(`Child window is closing`);
                    // Then we can get the new wallet balance
                    if (order.customer && (order.customer.email || order.customer.phone)) {
                        dispatch(paymentsActions.getCustomerConnectorWalletBalance(order.customer.email, order.customer.phone));
                    }
                }
            });
        }
    }

    useEffect(() => {
        const newWalletAmount = thisConnectorWallet?.wallet_balance ?? undefined;
        if (newWalletAmount !== undefined && newWalletAmount !== baseWalletBalance) {
            history.goBack();
        }

    }, [thisConnectorWallet?.wallet_balance])

    const sendCreditInfosAndGetOrder = async () => {
        if (!selectedLocation || !customerPhone || customerPhone === "" || !finalCreditAmount || !isValidAmount) {
            return;
        }

        const order = await paymentService.sendInfosToCreditWallet(selectedLocation.account_id, selectedLocation.id, customerEmail, customerPhone, finalCreditAmount);
        openNewWindowToSendPayment(order);
        return order;
    }

    return (
        <Box
            bgcolor={theme.palette.background.default}
            height={1}
            width={1}
        >
            <Box
                px={4}
                py={3}
                width={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor={theme.palette.action.disabledBackground}
            >
                <IconButton
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h2' color="textSecondary">
                    {intl.formatMessage({ id: "wallet.page.credit.title" })}
                </Typography>

                {
                    // Invisible but usefull to center the title
                }
                <Close style={{ opacity: 0 }} />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                bgcolor={theme.palette.background.paper}
                alignItems="center"
                p={3}
                gap={2}
            >
                <Box
                    width="80%"
                    display="flex"
                    flexDirection="column"
                >
                    <TextField
                        variant="outlined"
                        id="wallet.page.customer_email"
                        label={intl.formatMessage({ id: "wallet.page.credit.customer_email" })}
                        required
                        error={!customerEmail || customerEmail === ""}
                        placeholder={intl.formatMessage({ id: "wallet.page.credit.customer_email" })}
                        value={customerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                    />
                    {
                        (!customerEmail || customerEmail === "") &&
                        <Typography color="error" >{intl.formatMessage({ id: "wallet.page.credit.email.error" })}</Typography>
                    }
                </Box>

                <Box
                    width="80%"
                    display="flex"
                    flexDirection="column"
                >
                    <PhoneInput
                        country={selectedLocation?.country ?? "fr"}
                        value={customerPhone}
                        onChange={(phone) => {
                            const phoneObject = { phone: phone };
                            fixPhone(phoneObject, selectedLocation?.country);
                            setCustomerPhone(phoneObject.phone);
                        }}
                        inputStyle={{ width: "100%", borderColor: customerPhone && customerPhone !== "" ? "rgba(0, 0, 0, 0.23)" : "#f44336" }}
                    />
                    {
                        (!customerPhone || customerPhone === "") &&
                        <Typography color="error" >{intl.formatMessage({ id: "wallet.page.credit.phone.error" })}</Typography>
                    }
                </Box>

                <TextField
                    variant="outlined"
                    label={intl.formatMessage({ id: "wallet.page.credit.amount" })}
                    sx={{ width: "80%" }}
                    placeholder={"0.00"}
                    value={creditAmount}
                    onChange={(e) => setCreditAmount(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{ padding: 0 }}>
                                {getSymbolFromCurrency(currency)}
                            </InputAdornment>
                        )
                    }}
                />
                {
                    (finalCreditAmount !== walletAmountComputed) &&
                    <Alert severity="warning"
                        icon={<RedeemIcon />}
                        style={{ width: "80%" }}
                    >
                        {intl.formatMessage({ id: "wallet.page.credit.amount.benefit.message" },
                            {
                                credited_amount: MoneyToStringWithSymbol(walletAmountComputed),
                                amount: MoneyToStringWithSymbol(earnedAmount),
                                bold: (str: string) => <b>{str}</b>
                            })}
                    </Alert>
                }
            </Box>

            <Box
                bgcolor={theme.palette.background.paper}
                display="flex"
                width="100%"
                justifyContent="center"
                mt={3}
                p={2}
            >
                <Box
                    bgcolor={theme.palette.background.paper}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="80%"
                >
                    <TermsAndConditionsTextWithModal
                        customIntlId="wallet.page.credit.explanation.1"
                    />
                    <br />
                    {
                        locationWalletPaymentType?.connector_wallet_configuration && (
                            <Box
                                width={1}
                            >
                                <Typography fontWeight="bold">
                                    {intl.formatMessage({ id: "wallet.page.credit.explanation.2" })}
                                </Typography>
                                {
                                    locationWalletPaymentType.connector_wallet_configuration.type === WalletConfigurationType.STEPS ? (
                                        (locationWalletPaymentType.connector_wallet_configuration.configuration as WalletStepConfiguration[]).map((step, index) => (
                                            <Typography key={index}>
                                                {intl.formatMessage({ id: "wallet.page.credit.benefit.steps" },
                                                    { credited_amount: MoneyToStringWithSymbol(step.credited_amount), amount: MoneyToStringWithSymbol(step.amount_to_reach) })}
                                            </Typography>
                                        ))
                                    ) : (
                                        locationWalletPaymentType.connector_wallet_configuration.type === WalletConfigurationType.PERCENTAGE && (
                                            <Typography>
                                                {intl.formatMessage({ id: "wallet.page.credit.benefit.percentage" },
                                                    { percentage: (locationWalletPaymentType.connector_wallet_configuration.configuration as WalletPercentageConfiguration).percentage * 100 })}
                                            </Typography>
                                        )
                                    )
                                }
                                <br />
                            </Box>
                        )
                    }

                    <Typography>
                        {intl.formatMessage({ id: "wallet.page.credit.explanation.3" })}
                        <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "wallet.page.credit.explanation.4" })}</span>
                        {intl.formatMessage({ id: "wallet.page.credit.explanation.5" })}
                    </Typography>
                    <Typography style={{ textDecoration: "underline" }} >
                        {intl.formatMessage({ id: "wallet.page.credit.explanation.6" })}
                    </Typography>
                    <br />
                    <Typography>
                        {intl.formatMessage({ id: "wallet.page.credit.explanation.7" })}
                    </Typography>
                    <br />
                    <Typography>
                        {intl.formatMessage({ id: "wallet.page.credit.explanation.8" })}
                    </Typography>
                </Box>
            </Box>

            <Box
                width={1}
                mt={3}
                display="flex"
                flexDirection="column"
                alignSelf="flex-end"
                bgcolor={theme.palette.background.paper}
                boxShadow="rgb(0 0 0 / 10%) 0px 1px 5px 0px"
                border={`1px solid ${theme.palette.grey[100]}`}
                p={2}
                px={6}
                gap={2}
                alignItems="center"
            >
                <Box
                    width={1}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width={1}
                    >
                        <Typography>{intl.formatMessage({ id: "wallet.page.credit.total" })}</Typography>

                        <Typography>
                            {
                                !isValidAmount
                                    ? intl.formatMessage({ id: "wallet.page.credit.amount.negative.message" })
                                    : MoneyToStringWithSymbol(finalCreditAmount)

                            }
                        </Typography>

                    </Box>

                    {
                        locationWalletPaymentType?.connector_wallet_configuration &&
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width={1}
                        >
                            <Typography fontWeight="bold">
                                {intl.formatMessage({ id: "wallet.page.credited.amount" })}
                            </Typography>

                            <Typography fontWeight="bold">
                                {MoneyToStringWithSymbol(walletAmountComputed)}
                            </Typography>
                        </Box>
                    }
                </Box>

                <Box
                    width={1}
                    display="flex"
                    justifyContent="center"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!finalCreditAmount || !isValidAmount}
                        style={{ width: "100%", textTransform: "none", padding: theme.spacing(1, 0) }}
                        onClick={sendCreditInfosAndGetOrder}
                    >
                        <Typography variant="subtitle1" sx={{ marginRight: theme.spacing(1) }}>{intl.formatMessage({ id: "wallet.page.credit.button" })}</Typography>
                        <LaunchIcon
                            fontSize="small"
                        />
                    </Button>
                </Box>
            </Box>

        </Box >
    )
}

export default CreditWallet;