import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { PaymentMethodType, SupportedPayementType, SupportedServiceType, WalletConfigurationType, WalletPercentageConfiguration, WalletStepConfiguration } from '../../my-lemonade-library/model/Location';
import { PaymentType } from '../../my-lemonade-library/model/Order';
import { DEFAULT_CURRENCY, Money, MoneyToStringWithSymbol, numberToMoney, substractMoney } from '../../my-lemonade-library/src/common/models/Money';
import { RESTAURANT_TICKET_PAYMENT_TYPES } from '../../my-lemonade-library/src/payments/configs/RestaurantTicketPaymentConfig';
import { SpecificPaymentType, SupportedPaymentTypeExtended } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { PaymentTypeUnavailabilityReason } from '../../my-lemonade-library/src/payments/models/PaymentTypeUnavailabilityReason';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import BIMPLY_APETIZ from '../assets/BIMPLY_APETIZ.svg';
import CB from '../assets/CB.svg';
import EDENRED from '../assets/EDENRED.svg';
import MASTERCARD from '../assets/MASTERCARD.svg';
import SODEXO from '../assets/SODEXO.svg';
import SWILE from '../assets/SWILE.svg';
import UP from '../assets/UP.svg';
import VISA from '../assets/VISA.svg';
import { ReactComponent as UserSavedCardIcon } from '../assets/user_saved_card_icon.svg';
import LyfIcon from './LyfIcon';


interface PaymentCardProps {
    paymentType: SupportedPaymentTypeExtended;
    allPaymentTypes: SupportedPaymentTypeExtended[];
    serviceType: SupportedServiceType | undefined;
    disabled?: PaymentTypeUnavailabilityReason | boolean | null;
    onClick: () => void;
    leftToPay: Money;
}

const PaymentCard: React.FC<PaymentCardProps> = (props) => {

    const { paymentType, allPaymentTypes, serviceType, disabled, onClick, leftToPay } = props;

    const intl = useIntl();
    const theme = useTheme();

    const { data, login } = useTypedSelector((state: RootState) => state.authentication);
    const { connector_wallet } = useTypedSelector((state: RootState) => state.payment);
    const { selectedLocation } = useTypedSelector((state: RootState) => state.locations);
    const thisConnectorWallet = selectedLocation?.connector?.type ? connector_wallet[selectedLocation.connector.type] : undefined;
    const walletBalance = thisConnectorWallet?.wallet_balance ?? undefined;

    const locationWalletPaymentType = selectedLocation?.supported_payment_types.find((paymentType) => paymentType.type === PaymentType.CONNECTOR_WALLET);

    const renderWalletBalance = (): JSX.Element | null => {
        if (thisConnectorWallet === undefined) {
            return <CircularProgress color="primary" />;
        }
        return (
            <Box
                display="flex"
                flexDirection="column"
            >
                <Box
                    display="flex"
                    alignItems="center"
                >
                    <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                        {intl.formatMessage({ id: "payment.paymentCard.wallet_balance.title" }) + " " + MoneyToStringWithSymbol(walletBalance ?? numberToMoney(0, selectedLocation?.currency ?? DEFAULT_CURRENCY))}
                    </Typography>

                    {disabled &&
                        <Box>
                            <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>{" - "}</Typography>
                            <Typography variant="caption" style={{ color: theme.palette.error.main }}>
                                {intl.formatMessage({ id: "payment.paymentCard.wallet_balance.insufficient" })}
                            </Typography>
                        </Box>
                    }
                </Box>

                {
                    disabled &&
                    locationWalletPaymentType?.connector_wallet_configuration &&
                    (
                        locationWalletPaymentType.connector_wallet_configuration.type === WalletConfigurationType.STEPS ?
                            (
                                <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                                    {intl.formatMessage({ id: "wallet.page.credit.amount.credit.to.earned.message" },
                                        {
                                            amount: MoneyToStringWithSymbol((locationWalletPaymentType.connector_wallet_configuration.configuration as WalletStepConfiguration[])[0].amount_to_reach),
                                            earnedAmount: MoneyToStringWithSymbol((locationWalletPaymentType.connector_wallet_configuration.configuration as WalletStepConfiguration[])[0].credited_amount)
                                        }
                                    )}
                                </Typography>
                            ) : (
                                locationWalletPaymentType.connector_wallet_configuration.type === WalletConfigurationType.PERCENTAGE &&
                                (
                                    <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                                        {intl.formatMessage({ id: "wallet.page.credit.amount.percentage.benefit.message" },
                                            {
                                                percentage: (locationWalletPaymentType.connector_wallet_configuration.configuration as WalletPercentageConfiguration).percentage * 100
                                            }
                                        )}
                                    </Typography>
                                )
                            )
                    )

                }
            </Box>

        )
    }

    // Don't grey the card out if the location allows multi-payment_type and max amount is reached
    const greyOutCard = (
        Boolean(disabled)
        && (
            disabled !== PaymentTypeUnavailabilityReason.MAX_AMOUNT
            || !paymentHelper.allowSplittingPaymentAboveMaxLimit(paymentType.type)
        )
    );

    const isLyraMarketplaceSavedCard = Boolean(
        paymentType.type === PaymentType.LYRA_MARKETPLACE
        && allPaymentTypes.map(pt => pt.type).includes(SpecificPaymentType.LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA)
    );

    /**
     * Get the icon associated with the payment type
     */
    const getIcon = () => {

        if (isLyraMarketplaceSavedCard) {
            return <UserSavedCardIcon />
        }

        switch (paymentType.type) {
            case PaymentType.TABLE:
                return <LocalAtmIcon />;
            case PaymentType.LYF_WEB:
                return <LyfIcon fontSize='large' />;
            case PaymentType.EDENRED:
            case PaymentType.SWILE:
                return <LocalActivityIcon />;
            case PaymentType.CONNECTOR_WALLET:
                return <AccountBalanceWalletIcon />;
            default:
                return <CreditCardIcon />;
        }
    }

    /**
     * Get the title associated with the payment type
     */
    const getTitle = (): string => {

        if (isLyraMarketplaceSavedCard) {
            return intl.formatMessage({ id: "payment.paymentChoice.type.saved_data.title" })
        }

        switch (paymentType.type) {

            case PaymentType.TABLE: {
                if (serviceType) {
                    if (serviceType === SupportedServiceType.EAT_IN) {
                        return intl.formatMessage({ id: "payment.paymentChoice.type.table.title" });
                    }
                    return intl.formatMessage({ id: "payment.paymentChoice.type.table_collection.title" });
                }
                return intl.formatMessage({ id: "payment.paymentChoice.type.table_default.title" })
            }

            case PaymentType.EDENRED:
                return intl.formatMessage(
                    { id: "payment.paymentChoice.type.restaurant_ticket.title" },
                    { type: "(Edenred)" }
                );

            case PaymentType.SWILE:
                return intl.formatMessage(
                    { id: "payment.paymentChoice.type.restaurant_ticket.title" },
                    { type: "(Swile)" }
                );

            case PaymentType.LYF_WEB:
                return intl.formatMessage({ id: "payment.paymentChoice.type.lyf_web.title" });

            case SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS: {
                const foundOtherRestaurantTicketPaymentType = allPaymentTypes.find(pt =>
                    pt.type !== SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS && RESTAURANT_TICKET_PAYMENT_TYPES.includes(pt.type)
                );
                if (foundOtherRestaurantTicketPaymentType) {
                    return intl.formatMessage({ id: "payment.paymentChoice.type.restaurant_ticket.other.title" });
                }
                return intl.formatMessage({ id: "payment.paymentChoice.type.restaurant_ticket.plural.title" });
            }
            case PaymentType.CONNECTOR_WALLET:
                return intl.formatMessage({ id: "payment.paymentChoice.type.connector_wallet.title" })

            /**
             * WARNING: the online payment title is the default case. If you want to add
             * a new pay solution (Apple Pay for example), add a case just above.
             */
            default:
                return intl.formatMessage({ id: "payment.paymentChoice.type.online.title" });
        }
    }

    const renderMinMaxAmounts = (): JSX.Element | null => {

        const paymentTypeToCheck = _.cloneDeep(paymentType);

        let originalPaymentType: SupportedPayementType | undefined;
        switch (paymentType.type) {

            case SpecificPaymentType.LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA:
            case SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS:
                originalPaymentType = allPaymentTypes.find(pt => pt.type === PaymentType.LYRA_MARKETPLACE) as SupportedPayementType | undefined;
                break;
        }

        if (originalPaymentType?.min_amount) {
            paymentTypeToCheck.min_amount = originalPaymentType.min_amount;
        }
        if (originalPaymentType?.max_amount) {
            paymentTypeToCheck.max_amount = originalPaymentType.max_amount;
        }

        let finalStr: string = "";
        let textColor = theme.palette.text.disabled;

        // Happens when Restaurant Ticket max_amount is reached -> will trigger a partial payment
        if (
            paymentTypeToCheck.max_amount
            && disabled === PaymentTypeUnavailabilityReason.MAX_AMOUNT
            && !greyOutCard
        ) {
            const leftToPayAfter = substractMoney(leftToPay, paymentTypeToCheck.max_amount);
            finalStr = intl.formatMessage(
                { id: "payment.paymentChoice.multiple_types.card_info" },
                {
                    maxAmount: MoneyToStringWithSymbol(paymentTypeToCheck.max_amount),
                    leftToPay: MoneyToStringWithSymbol(leftToPayAfter),
                },
            );
            // textColor = theme.palette.text.primary;
        }
        else if (paymentTypeToCheck.min_amount || paymentTypeToCheck.max_amount) {

            let separationNeeded: boolean = false;

            if (paymentTypeToCheck.min_amount) {
                finalStr += intl.formatMessage({ id: "minimum" })
                finalStr += " " + MoneyToStringWithSymbol(paymentTypeToCheck.min_amount);
                separationNeeded = true;
            }

            if (paymentTypeToCheck.max_amount) {

                if (separationNeeded) {
                    finalStr += ", ";
                }

                const maxAmountStr = intl.formatMessage({ id: "maximum" }) + " " + MoneyToStringWithSymbol(paymentTypeToCheck.max_amount);
                finalStr += maxAmountStr;
            }
        }

        return (
            <Box
                display="flex"
                alignItems="center"
                gap={.75}
            >
                {Boolean(finalStr) &&
                    <Typography variant="caption" style={{ color: textColor }}>
                        {finalStr}
                    </Typography>
                }
            </Box>
        );
        return null;
    }

    const renderDescription = (): JSX.Element | null => {

        if (
            (
                paymentType.type === PaymentType.EDENRED
                || paymentType.type === PaymentType.SWILE
            )
            && (!login || data.user_authentication_state.is_anonymous)
        ) {
            return (
                <Typography variant="caption" style={{ color: theme.palette.text.disabled }}>
                    {intl.formatMessage({ id: "payment.paymentChoice.type.edenred.description" })}
                </Typography>
            )
        }

        return null;
    }

    /**
     * Get all the icons associated with payments
     */
    const renderOnlinePaymentIcons = (): JSX.Element | null => {

        let arrayOfLogos: JSX.Element[] = [];
        const methodTypes = paymentType.method_types ?? getMethodTypesIfEmpty();

        // Sort the method types to have CB first to not separate the Restaurant Ticket logos
        const sortedMethodTypes = _.cloneDeep(methodTypes).sort((a) => {
            if (a === PaymentMethodType.CB) {
                return -1;
            }
            return 1;
        });

        sortedMethodTypes.forEach((methodType, index) => {

            switch (methodType) {

                case PaymentMethodType.CB:
                    arrayOfLogos.push(getSvgImg("CB Logo", CB, index));
                    arrayOfLogos.push(getSvgImg("VISA Logo", VISA, (-index - 1)));
                    arrayOfLogos.push(getSvgImg("MASTERCARD Logo", MASTERCARD, (-index - 1) * 2));
                    break;

                case PaymentMethodType.UP:
                    arrayOfLogos.push(getSvgImg("UP Logo", UP, index));
                    break;

                case PaymentMethodType.EDENRED:
                    arrayOfLogos.push(getSvgImg("EDENRED Logo", EDENRED, index));
                    break;

                case PaymentMethodType.SWILE:
                    arrayOfLogos.push(getSvgImg("SWILE Logo", SWILE, index));
                    break;

                case PaymentMethodType.SODEXO:
                    arrayOfLogos.push(getSvgImg("SODEXO Logo", SODEXO, index));
                    break;

                case PaymentMethodType.NATIXIS:
                    arrayOfLogos.push(getSvgImg("BIMPLY_APETIZ Logo", BIMPLY_APETIZ, index));
                    break;

            }
        });

        if (arrayOfLogos.length > 0) {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    gap={.7}
                >
                    {arrayOfLogos}
                </Box>
            );
        }
        return null;
    }

    const getMethodTypesIfEmpty = (): PaymentMethodType[] => {

        if (isLyraMarketplaceSavedCard) {
            return [];
        }

        switch (paymentType.type) {

            case PaymentType.TABLE:
            case PaymentType.LYF_WEB:
            case PaymentType.CONNECTOR_WALLET:
                break;

            case PaymentType.EDENRED:
                return [PaymentMethodType.EDENRED];

            case PaymentType.SWILE:
                return [PaymentMethodType.SWILE];

            case SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS:
                return [
                    PaymentMethodType.NATIXIS,
                    PaymentMethodType.SODEXO,
                    PaymentMethodType.UP,
                ];

            default:
                return [PaymentMethodType.CB];
        }

        return [];
    }

    /**
     * Get the image associated with an SVG
     */
    const getSvgImg = (alt: string, src: string, index: number) => {

        return (
            <Box key={index}>
                <img
                    alt={alt}
                    height="20"
                    src={src}
                    style={{
                        opacity: greyOutCard ? .4 : 1
                    }}
                />
            </Box>
        )
    }

    const getCardStyle = (): React.CSSProperties => {

        const style: React.CSSProperties = {
            backgroundColor: theme.palette.background.paper,
            boxShadow: "rgb(0 0 0 / 10%) 0px 1px 5px 0px",
            cursor: greyOutCard ? "auto" : "pointer",
        }

        if (greyOutCard) {
            style.backgroundColor = theme.palette.grey[100];
        }

        if (isLyraMarketplaceSavedCard && !greyOutCard) {
            style.border = `1px solid ${theme.palette.primary.main}`;
        }

        return style;
    }

    if (!paymentType) {
        return null;
    }

    return (
        <Box
            style={getCardStyle()}
            width="100%"
            borderRadius="5px"
            display="flex"
            alignItems="center"
            padding={2}
            gap={3}
            data-test={`payment_${paymentType.type}`}
            onClick={() => {
                if (!greyOutCard) {
                    onClick()
                }
            }}
        >

            <Box
                display="flex"
                alignItems="center"
                style={{ color: greyOutCard ? theme.palette.grey[500] : theme.palette.primary.main }}
            >
                {getIcon()}
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                flex={1}
                gap={.5}
            >

                <Typography variant="h5" style={{ color: greyOutCard ? theme.palette.grey[500] : "inherit" }}>
                    {getTitle()}
                </Typography>

                {renderDescription()}
                {renderMinMaxAmounts()}
                {renderOnlinePaymentIcons()}
                {paymentType.type === PaymentType.CONNECTOR_WALLET && renderWalletBalance()}

            </Box>


        </Box>
    );
}

export default PaymentCard;

