import { Box, Dialog, useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import log from 'loglevel';
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AUTHENTICATED_QUERY_PARAM_NAME, FROM_QUERY_PARAM_NAME, SIGNIN_AUTHORIZATION_CODE_QUERY_PARAM_NAME, SIGNIN_QUERY_PARAM_NAME, SIGNIN_STATE_QUERY_PARAM_NAME, SIGNIN_UUID_QUERY_PARAM_NAME } from '../../authentication/configs/AuthenticationRouterConfig';
import { SignInAuthenticated } from '../../authentication/models/SignInAuthenticated';
import SignInAuthorizeParams from '../../authentication/models/SignInAuthorizeParams';
import AuthenticationActions from '../../authentication/redux/AuthenticationActions';
import CategoriesPage from '../../Categories/pages/CategoriesPage';
import SubcategoriesPage from '../../Categories/pages/SubcategoriesPage';
import LoaderComponent from '../../Common/components/LoaderComponent';
import AlternateToolbarComponent from '../../Common/components/toolbar/AlternateToolbarComponent';
import ToolbarComponent from '../../Common/components/toolbar/ToolbarComponent';
import useHeaderDisplay from '../../Common/customHooks/useHeaderDisplay';
import useHistoryTracking from '../../Common/customHooks/useHistoryTracking';
import useRedirectUrl from '../../Common/customHooks/useRedirectUrl';
import PageError from '../../Common/pages/PageError';
import { useHeaderHeight } from '../../config/hooks/useHeaderHeight';
import * as ROUTES from '../../config/routes';
import { RouteParams } from '../../config/routes';
import useDeviceContext from '../../config/useDeviceContext';
import { isDevEnv } from '../../config/variables';
import useCustomerInformationModalDecision from '../../customers/hook/useCustomerInformationModalDecision';
import CustomerInformationModal from '../../customers/pages/CustomerInformationModal';
import HappyHourPage from '../../deals/pages/HappyHour';
import Menu from '../../deals/pages/Menu';
import { DEFAULT_LANGUAGE } from '../../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderStatus, PaymentType } from '../../my-lemonade-library/model/Order';
import { AUTHENTICATE_JWT_QUERY_PARAM_NAME, AUTHENTICATION_AUTHORIZE_KEYWORD, AUTHENTICATION_PROVIDER_KEY } from '../../my-lemonade-library/src/authentications/configs/AuthenticationApiRoutes';
import AuthorizeCodeType from '../../my-lemonade-library/src/authentications/models/AuthorizeCodeType';
import { SignInProviders } from '../../my-lemonade-library/src/authentications/models/BaseUser';
import { DISCOUNT_REF } from '../../my-lemonade-library/src/discounts/configs/DiscountsApiRoutes';
import { TABLE_LINK_KEY, TABLE_LINK_KEYWORD } from '../../my-lemonade-library/src/locations/configs/LocationsApiRoutes';
import { getTableServiceTypes } from '../../my-lemonade-library/src/tables/services/TableService';
import { THEME_QUERY_PARAM_NAME } from '../../my-lemonade-library/src/theme/models/ThemeRouterConfig';
import { ThemeList } from '../../my-lemonade-library/src/theme/models/ThemesList';
import { isUserMasterOfTheOrder } from '../../orders/helpers/OrderHelpers';
import { useLoyaltyInformation } from '../../orders/hooks/useLoyaltyInformation';
import OrderSummaryPage from '../../orders/pages/OrderSummaryPage';
import orderActions, { default as OrderAction, default as OrderActions } from '../../orders/redux/OrderActions';
import StripeRoot from '../../Payment/components/StripeRoot';
import useCustomerEmailForWallet from '../../Payment/hooks/useCustomerEmailForWallet';
import SharePaymentPage from '../../Payment/pages/SharePaymentPage';
import paymentsActions from '../../Payment/redux/PaymentsActions';
import ProductsPage from '../../Products/pages/ProductsPage';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import themeActions from '../../theme/redux/ThemeActions';
import DialogShareOrder from '../components/DialogShareOrder';
import RouterComponent from '../components/Navigation';
import { default as LocationActions, default as locationActions } from '../redux/LocationActions';
import LocationHomeComponent from './Home';
import LocationHomeDeskTop from './LocationHomeDesktop';

/**
 * get the params in the URL (here we are looking for ?orderId=xxxxxx)
 * @returns 
 */
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface LocationHomeProps { }

//This is the entry point for every url starting with /tablelink/:key
const LocationHome: React.FC<LocationHomeProps> = () => {

    const dispatch = useDispatch();

    let match = useRouteMatch<RouteParams>();
    const key = match.params.tableLinkKey;

    const theme = useTheme();
    const isDesktopRender = useMediaQuery(theme.breakpoints.up("sm"));
    const deviceContext = useDeviceContext(theme);
    const headerHeight = useHeaderHeight();

    const history = useHistory();
    const showHeader = useHeaderDisplay();
    useRedirectUrl();
    useCustomerEmailForWallet();
    useHistoryTracking();

    const query = useQuery();
    let signinProvider = query.get(SIGNIN_QUERY_PARAM_NAME)?.trim();
    const fromProvider = query.get(FROM_QUERY_PARAM_NAME)?.trim();
    const authenticatedParam = query.get(AUTHENTICATED_QUERY_PARAM_NAME) ? query.get(AUTHENTICATED_QUERY_PARAM_NAME) as SignInAuthenticated : null;
    const signinProviderCode = query.get(AUTHENTICATION_PROVIDER_KEY);
    const authorizationCode = query.get(SIGNIN_AUTHORIZATION_CODE_QUERY_PARAM_NAME);
    const state = query.get(SIGNIN_STATE_QUERY_PARAM_NAME);
    const discountRefUrl = query.get(DISCOUNT_REF);

    const { themeName } = useTypedSelector((state: RootState) => state.theme);


    // Loyalty
    useLoyaltyInformation();

    let themeQuery = themeName
    if (query.get(THEME_QUERY_PARAM_NAME) === ThemeList.GET_RESTO) {
        themeQuery = ThemeList.GET_RESTO;
    }
    // From = signin + theme
    if (fromProvider) {
        signinProvider = fromProvider;
        if (fromProvider === SignInProviders.GETRESTO) {
            themeQuery = ThemeList.GET_RESTO;
            // Presence of a jwt token ensure we are in lyf app
        } else if (fromProvider === SignInProviders.LYF && query.get(AUTHENTICATE_JWT_QUERY_PARAM_NAME)) {
            themeQuery = ThemeList.LYF;
        }
    } else if (signinProviderCode) {
        signinProvider = signinProviderCode;
    }

    const lang = useTypedSelector(state => state.lang);
    const { loading: authenticationLoading, data } = useTypedSelector((state: RootState) => state.authentication);
    const userId = data.user_authentication_state.user?.uid;
    const { formToDisplay } = useTypedSelector((state: RootState) => state.customerInformationModal);
    const { order, initOrders: initOrdersState, currentOrder: currentOrderState, openModal: openOrderModal } = useTypedSelector((state: RootState) => state.order);
    const { tableLinkId, selectedTable, selectedCatalog, selectedLocation, redirect_url, errorMessages, isLoading: locationLoading, invalidServiceType, discount_ref_from_url } = useTypedSelector((state: RootState) => state.locations);

    const { isTherePaymentData } = useTypedSelector((state: RootState) => state.payment);
    const lyramarketplaceIsLogged = userId ? isTherePaymentData[PaymentType.LYRA_MARKETPLACE]?.[userId] : null;
    const isLyramarketplaceEnabled = selectedLocation?.supported_payment_types?.some(p => p.type === PaymentType.LYRA_MARKETPLACE);

    // The hook wihch decides if and what we have to display on the customer information modal
    useCustomerInformationModalDecision();

    /**
     * useEffect use to check discount when a user is connected
     */
    useEffect(() => {
        if (selectedCatalog && selectedLocation) {
            dispatch(OrderActions.computeOrderPrice(selectedCatalog, selectedLocation, selectedTable));
        }
    }, [selectedCatalog, dispatch, order.service_type, order.customer?.uid])

    useEffect(() => {

        log.debug('Location home refresh')

        if (themeQuery !== ThemeList.DEFAULT) {
            dispatch(themeActions.changeTheme(themeQuery))
        }

        if (key === AUTHENTICATION_AUTHORIZE_KEYWORD) {
            if (signinProviderCode && Object.values(SignInProviders).includes(signinProviderCode as SignInProviders) &&
                authorizationCode && state) {
                const signinParams: SignInAuthorizeParams = {
                    provider: signinProviderCode as SignInProviders,
                    authorizationCode: authorizationCode,
                    authorizationCodeType: AuthorizeCodeType.OAUTH_AUTHORIZATION_CODE,
                    state: state,
                    uuid: query.get(SIGNIN_UUID_QUERY_PARAM_NAME),
                    redirect: true
                }
                log.info("Signin authorize params", signinParams);

                dispatch(AuthenticationActions.signInAuthorize(signinParams));
            } else {
                log.error(`Can't authorize: ${signinProviderCode} not known`)
                history.push(ROUTES.getErrorFullRoute(AUTHENTICATION_AUTHORIZE_KEYWORD))
            }
        } else if (key === TABLE_LINK_KEYWORD) {
            if (query.get(AUTHENTICATE_JWT_QUERY_PARAM_NAME)) {
                if (isDevEnv()) {
                    // Log in error to be able to retrieve jwt on server sid
                    log.error(`Received jwt: ${query.get(AUTHENTICATE_JWT_QUERY_PARAM_NAME)}`);
                } else {
                    log.debug(`Received jwt: ${query.get(AUTHENTICATE_JWT_QUERY_PARAM_NAME)}`);
                }
            }
            // A generic url is used and the tablelink is given in query param
            // Useful for Lyf third party
            const tableLinkId = query.get(TABLE_LINK_KEY);
            if (tableLinkId) {
                history.push(`${ROUTES.getHomeFullRoute(tableLinkId)}${window.location.search} `)
            } else {
                log.error(`Tablelink load without ${TABLE_LINK_KEY} query param`);
            }
        } else {

            dispatch(locationActions.loadLocation(
                key,
                query,
                signinProvider as SignInProviders,
                authenticatedParam,
                themeQuery,
            ));
        }

    }, [dispatch, match.params.tableLinkKey]);

    /**
     * If we are on those pages, we have to CLOSE the modal. These pages
     * are the ones listed at the bottom of this file, in the switch component.
     * Else we open the modal.
     * This useEffect does not replace the actions openModal and closeModal in the rest of
     * the code, it simply does the work when you open the webapp directly to a given
     * URL (or if you refresh for example)
     */
    useEffect(() => {

        // This condition is like a "wait for the page to load": open or close the modal when
        // the tablelinkId has been loaded
        if (tableLinkId) {

            // TODO: to be improved / refactor
            if (history.location.pathname === "/" + tableLinkId + ROUTES.CategoriesPage
                || history.location.pathname === "/" + tableLinkId + ROUTES.SubcategoriesPage
                || history.location.pathname === "/" + tableLinkId + ROUTES.ProductsPage
                || history.location.pathname === "/" + tableLinkId + ROUTES.HappyHour
                || history.location.pathname === "/" + tableLinkId + ROUTES.Menu
                || history.location.pathname === "/" + tableLinkId
                || history.location.pathname === "/" + tableLinkId + "/"
            ) {
                dispatch(OrderAction.closeModal());
            }
            else {
                dispatch(OrderAction.openModal());
            }
        }

    }, [dispatch, tableLinkId, authenticationLoading, history.location.pathname])

    /**
     * Listen to the current URL and remove the "waiting_for_submission" status if needed.
     * Useful when doing a share order and the master user goes back from the payment
     * process -> the other users won't be stuck on the "waiting_for_submission" page.
     */
    useEffect(() => {

        // TODO: clean, find a better method to idenfity the pages
        if (
            isUserMasterOfTheOrder(order.master_user_uid, data.user_authentication_state.user?.uid)
            && order.status === OrderStatus.WAITING_SUBMISSION
            && (
                history.location.pathname === "/" + tableLinkId + ROUTES.CategoriesPage
                || history.location.pathname === "/" + tableLinkId + ROUTES.SubcategoriesPage
                || history.location.pathname === "/" + tableLinkId + ROUTES.ProductsPage
                || history.location.pathname === "/" + tableLinkId + ROUTES.HappyHour
                || history.location.pathname === "/" + tableLinkId + ROUTES.Menu
                || history.location.pathname === "/" + tableLinkId
                || history.location.pathname === "/" + tableLinkId + "/"
                || history.location.pathname === "/" + tableLinkId + ROUTES.OrderSummary
                || history.location.pathname === "/" + tableLinkId + ROUTES.OrderSummary + "/"
            )
        ) {
            log.info("Master user browsing a page which is not in the finalize order process: remove 'waiting_for_submission' status");
            dispatch(orderActions.setStatusWaitingSubmission(false));
        }

    }, [dispatch, tableLinkId, history.location.pathname, order, data.user_authentication_state.user]);

    /**
     * Update the discount_from_url redux variable from the discount ref in the URL
     */
    useEffect(() => {

        if (discountRefUrl && (!discount_ref_from_url || discount_ref_from_url !== discountRefUrl)) {
            dispatch(LocationActions.getDiscountRefFromUrl(discountRefUrl));
        }
    }, [discountRefUrl, discount_ref_from_url]);

    /**
     * Get to know if the user has LyraMarketplace payment data
     */
    useEffect(() => {
        if (
            selectedLocation
            && isLyramarketplaceEnabled
            && _.isNil(lyramarketplaceIsLogged)
            && userId
        ) {
            dispatch(paymentsActions.isTherePaymentData(selectedLocation.account_id, selectedLocation.id, [PaymentType.LYRA_MARKETPLACE], userId));
        }
    }, [isLyramarketplaceEnabled, selectedLocation, lyramarketplaceIsLogged]);

    /**
     * Render the correct header. 
     * The legacy header must be rendered on the categories page in grid mode
     * @returns Header if conditions are met
     */
    const renderHeader = (): JSX.Element | void => {
        if (
            history.location.pathname === "/" + tableLinkId + ROUTES.CategoriesPage
            && selectedCatalog
            && themeName !== ThemeList.GET_RESTO
        ) {
            return <ToolbarComponent />

        } else if (showHeader) {
            return <AlternateToolbarComponent />

        } else {
            return
        }
    }

    let subtitleId = "404page.contactLocation";
    if (selectedTable?.service_type === SupportedServiceType.EAT_IN) {
        subtitleId = "404page.waiter";
    }

    if (redirect_url) {
        window.location.assign(redirect_url)
    }

    // Keep loading to avoid displaying not available page
    // TODO: keep loading if jwt in url ?
    if (authorizationCode && key === AUTHENTICATION_AUTHORIZE_KEYWORD) {
        log.warn(`Keep loading because of authentication process: authorizationCode ${authorizationCode}, signinProvider ${signinProvider}, authenticated ${authenticatedParam}, anonymous ${data.user_authentication_state.is_anonymous}, user id ${data.user_authentication_state.user?.uid} `);
        return <LoaderComponent />
    }

    if (selectedTable.disable) {
        log.warn(`The table ${selectedTable?.ref} is disabled`);
        return <PageError subtitleId={subtitleId} />
    }

    if (invalidServiceType) {
        log.warn(`The table ${selectedTable?.ref} is disabled`);
        return <PageError subtitleId={subtitleId} />
    }

    if (errorMessages) { //An error page need to be created
        log.warn(`Errors prevent the webapp from loading`, errorMessages);
        // TODO: redirect to 404 page
        return <PageError subtitleId={subtitleId} />
    }

    if (
        locationLoading
        || lang.isLoading
        || initOrdersState.loading
        || authenticationLoading
        || (currentOrderState.sending && deviceContext.mobile_device)
    ) {
        return <LoaderComponent />
    }

    if (selectedTable.id && (!getTableServiceTypes(selectedTable) || getTableServiceTypes(selectedTable)?.length === 0)) {
        log.warn(`The table ${selectedTable?.ref} does not have any service_type`, { selectedTable });
        return <PageError subtitleId={subtitleId} />
    }

    return (
        <StripeRoot>

            <Helmet htmlAttributes={{ lang: (selectedCatalog && selectedCatalog.language) ? selectedCatalog.language : DEFAULT_LANGUAGE }} />

            {formToDisplay !== null &&
                < CustomerInformationModal />
            }

            {
                isDesktopRender ? (
                    <Box height={1} id="desktop" width={1}>

                        {showHeader && <ToolbarComponent />}

                        <Box
                            marginTop={showHeader ? headerHeight : 0}
                            position="absolute"
                            width="100vw"
                            height={showHeader ? `calc(${100}% - ${headerHeight})` : "100%"}
                            style={{
                                overflowX: 'auto',
                            }}
                            bgcolor={theme.palette.background.default}
                        >
                            <Switch>
                                <Route exact path={ROUTES.LocationHome}>
                                    <LocationHomeComponent />
                                </Route>
                                <Route>
                                    <LocationHomeDeskTop />
                                </Route>
                            </Switch>
                        </Box>
                    </Box>

                ) : (
                    <Box id="mobile" height={1} width={1}>

                        {renderHeader()}

                        <Box
                            marginTop={showHeader ? headerHeight : 0}
                            position="absolute"
                            width="100vw"
                            height={showHeader ? `calc(${100}% - ${headerHeight})` : "100%"}
                            bgcolor={theme.palette.background.default}
                        >
                            <Switch>
                                <Route exact path={ROUTES.LocationHome}>
                                    <LocationHomeComponent />
                                </Route>
                                <Route exact path={ROUTES.LocationHome + ROUTES.CategoriesPage} component={CategoriesPage} />
                                <Route exact path={ROUTES.LocationHome + ROUTES.SubcategoriesPage} component={SubcategoriesPage} />
                                <Route exact path={ROUTES.LocationHome + ROUTES.ProductsPage} component={ProductsPage} />
                                <Route exact path={ROUTES.LocationHome + ROUTES.HappyHour} component={HappyHourPage} />
                                <Route exact path={ROUTES.LocationHome + ROUTES.Menu} component={Menu} />
                                <Route exact path={ROUTES.LocationHome + ROUTES.OrderSummary} component={OrderSummaryPage} />
                                <Route exact path={ROUTES.LocationHome + ROUTES.PaymentShareItems} component={SharePaymentPage} />
                                <Dialog
                                    fullScreen
                                    fullWidth
                                    open={openOrderModal}
                                    disableEscapeKeyDown
                                // Previously in MUI v4: disableBackdropClick
                                // onClose={(event, reason) => {
                                //     if (reason !== 'backdropClick') {
                                //         handleClose(event, reason);
                                //     }
                                // }}
                                >
                                    <RouterComponent />
                                </Dialog>
                            </Switch>
                        </Box>
                    </Box>
                )
            }

            <DialogShareOrder
                desktop={isDesktopRender}
            />

        </StripeRoot>
    );

}
// TODO : Handle 404Page
export default LocationHome

