import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cursor } from '../../../Common/StyledComponents';
import * as ROUTES from '../../../config/routes';
import { DESKTOP_MENU_BORDER_RADIUS } from '../../../config/theme';
import { Deal } from '../../../my-lemonade-library/model/Catalog';
import { useTypedSelector } from '../../../redux/root-reducer';

/**
 * Deal List of type Menu use in desktop version
 * @param props 
 */
export default function MenuList(props: any) {

    const { selectedCatalog, tableLinkId } = useTypedSelector(state => state.locations)

    const theme = useTheme();
    const history = useHistory();
    const [dealRefSelected, setDealRefSelected] = useState<string | null>(null)

    const isDealSelected = (dealRef: string) => {
        if (dealRef === dealRefSelected && history.location.pathname.includes(ROUTES.Menu)) {
            return true
        }
        return false
    }
    const menuDeals = selectedCatalog?.data.deals.filter(deal => deal.lines.length > 1);

    if (menuDeals && menuDeals.length) {
        return (

            <Box mb={3} style={{ backgroundColor: theme.palette.background.paper, borderRadius: DESKTOP_MENU_BORDER_RADIUS }}>
                <Box p={3} >

                    {menuDeals.map((deal: Deal, index: number) => (
                        !deal.disable &&

                        <Box
                            key={index}
                            mb={(index === menuDeals.length - 1) ? 0 : 2}
                            onClick={() => {
                                setDealRefSelected(deal.ref)
                                history.push(ROUTES.LocationHome.replace(":tableLinkKey", tableLinkId) + ROUTES.Menu + "?item=" + deal.ref)
                            }}
                        >
                            <Typography variant="subtitle1" style={{ padding: "5px", ...cursor, color: isDealSelected(deal.ref) ? theme.palette.primary.main : theme.palette.text.primary }}>{deal.name}</Typography>

                            <Divider
                                style={{
                                    backgroundColor: isDealSelected(deal.ref) ? theme.palette.primary.main : theme.palette.text.primary,
                                    margin: 0,
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        )
    }
    return null
}