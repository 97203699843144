import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Typography, useTheme } from '@mui/material';
import { getRadiusFromTheme } from '../../Common/helper/ThemeHelper';
import DataTestConst from '../../config/DataTestConst';

type PlusMinusQuantityButtonProps = {
    quantity: string | number,
    onAdd: () => void,
    onMinus: () => void,
    padding: number,
    isSRender?: boolean,
    smallButtons?: boolean
    dataTestSuffix?: string
};

//This component take all available space it needs a contaier to the right size
const PlusMinusQuantityButton = (props: PlusMinusQuantityButtonProps) => {

    const {
        quantity,
        onAdd,
        onMinus,
        padding,
        isSRender,
        smallButtons,
    } = props;

    const theme = useTheme();

    // The size of + and - buttons
    const squareSize: string = smallButtons ? "35px" : "58px";

    return (
        <Box
            display="flex"
            height="100%"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            px={padding + "px"}
            borderRadius={getRadiusFromTheme(theme)}
            gap={smallButtons ? 2 : 4}
        >

            <Box
                data-test={props.dataTestSuffix ? `${DataTestConst.DECREASE_QUANTITY}_${props.dataTestSuffix}` : DataTestConst.DECREASE_QUANTITY}
                onClick={() => onMinus()}
                height={squareSize}
                width={squareSize}
                borderRadius={getRadiusFromTheme(theme)}
                border={`1px solid ${theme.palette.secondary.main}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ cursor: quantity <= 1 ? '' : 'pointer' }}
            >
                <RemoveIcon color="secondary" fontSize='small' />
            </Box>

            <Typography
                variant={isSRender ? "h2" : "h5"}
                color='secondary'
            >
                {quantity}
            </Typography>

            <Box
                data-test={props.dataTestSuffix ? `${DataTestConst.INCREASE_QUANTITY}_${props.dataTestSuffix}` : DataTestConst.INCREASE_QUANTITY}
                onClick={() => onAdd()}
                height={squareSize}
                width={squareSize}
                borderRadius={getRadiusFromTheme(theme)}
                border={`1px solid ${theme.palette.secondary.main}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ cursor: 'pointer' }}
            >
                <AddIcon color='secondary' fontSize='small' />
            </Box>

        </Box>
    );

}

PlusMinusQuantityButton.defaultProps = { padding: 10 }

export default PlusMinusQuantityButton

