import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Element } from 'react-scroll';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DeviceContext } from '../../../App';
import { shouldDisplayCategory, subCategoriesList } from '../../../Categories/services/categoriesServices';
import { List } from '../../../Common/StyledComponents';
import FabButtonOrder from '../../../Common/components/FabButtonOrder';
import * as ROUTES from '../../../config/routes';
import { getDealRoute } from '../../../deals/configs/DealRoutes';
import { isTemporallyAvailableForOrder } from '../../../my-lemonade-library/functions/Helpers';
import { Categorie, CategoryDisplayMode, Deal, Product, getTimezoneName } from '../../../my-lemonade-library/model/Catalog';
import DealExt from '../../../my-lemonade-library/model/DealExtended/DealExt';
import { Order } from '../../../my-lemonade-library/model/Order';
import { ProductExtended } from '../../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { CatalogExtended } from '../../../my-lemonade-library/model/catalogExtended/CatalogExtended';
import TextInputBottomModal from '../../../orders/components/TextInputBottomModal';
import { useTypedSelector } from '../../../redux/root-reducer';
import Cards from '../../component/Card';
import ProductsItemListComponent from '../../component/ProductsItemListComponent';
import productService from '../../services/ProductService';
import ProductModal from '../ProductModal';

interface ProductsPageDesktopProps { }

/**
 * Component in charge to display display product on desktop version
 * Mapping of categories and subCategories then map the productList to display and call <ProductListComponent :> for each product
 */
const ProductsPageDesktop: React.FC<ProductsPageDesktopProps> = (props) => {

    const history = useHistory();
    const { supportedLanguages, currentLang } = useTypedSelector((state) => state.lang)
    const { selectedCatalog, tableLinkId, selectedLocation, selectedTable } = useTypedSelector(state => state.locations);
    const { order } = useTypedSelector(state => state.order)

    const [productOpen, setProductOpen] = useState<boolean>(false);
    const [openProductNoteModal, setOpenProductNoteModal] = useState<boolean>(false);
    const [itemNote, setItemNote] = useState<string>("")
    const [selectedProduct, setSelectedProduct] = useState<ProductExtended | null>(null);
    const { tablet_device, mobile_device } = useContext(DeviceContext)
    const match = useRouteMatch<ROUTES.RouteParams>();

    const theme = useTheme();

    const openModal = (item: ProductExtended | DealExt) => {
        const deal = item as DealExt;
        const product = item as Product;
        // Use the deal property to check if it's a deal or not
        if (deal.lines) {
            history.push(getDealRoute(tableLinkId, deal))
        } else {
            if (!mobile_device && (product.image || product.description)) {
                history.push(ROUTES.getProductsDetailFullRoute(match.params.tableLinkKey, product.category_ref!, product.ref));
            } else {
                setSelectedProduct(product);
                setProductOpen(true);
            }
        }
    }

    const closeModal = () => {

        setSelectedProduct(null);
        setProductOpen(false);
    }


    /**
     * Return list of product to display, conditions on category are already checked when this function is called
     * Only responsabilites is to check the product conditions (disable)
     * TODO : Check also service type when SupportedServiceType will be provided to product
     * @param cate 
     * @param selectedCatalog 
     */
    const listProducts = (order: Order, cate: Categorie, selectedCatalog: CatalogExtended, displayUnavailableItem?: boolean): ProductExtended[] => {
        let productReferenceByCategory: Product[] | undefined = selectedCatalog.data.productsByCategory ? selectedCatalog.data.productsByCategory[cate.ref] : undefined;
        if (!productReferenceByCategory) {
            productReferenceByCategory = [];
        }
        if (displayUnavailableItem) {
            return productReferenceByCategory
        }
        return productReferenceByCategory.filter((product) => productService.canDisplayItem(order, product, selectedCatalog));
    }

    const listDeals = (cate: Categorie, selectedCatalog: CatalogExtended): DealExt[] => {

        let dealsReferenceByCategory: DealExt[] | undefined = selectedCatalog.data.dealsByCategory ? selectedCatalog.data.dealsByCategory[cate.ref] : undefined;
        if (!dealsReferenceByCategory) {
            dealsReferenceByCategory = [];
        }
        return dealsReferenceByCategory.filter(deal => {
            let available = true
            if (deal.restrictions) {
                available = isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), deal.restrictions, false)
            }
            return (!deal.disable && available)
        })
    }


    if (selectedCatalog && selectedLocation && selectedTable) {

        return (
            <Box
                padding={theme.spacing(0, 0, 1, 1)}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >

                {selectedProduct &&
                    <>
                        <ProductModal
                            open={productOpen}
                            closeModal={closeModal}
                            selectedProduct={selectedProduct}
                            note={itemNote}
                            setNote={setItemNote}
                        />
                        <TextInputBottomModal
                            open={openProductNoteModal}
                            setOpen={() => {
                                setProductOpen(true)
                                setOpenProductNoteModal(false)
                            }}
                            titleId="product_modal.add_product_note"
                            placeHolderId="Order.cart.customer_notes.dialog.placeholder"
                            text={itemNote}
                            setText={setItemNote}
                            desktop
                        />

                    </>
                }

                {// Map all the categories in Catalog

                    selectedCatalog.data.categories.filter(cate => !cate.parent_ref && (!cate.disable || cate.display_if_disabled)).map((cate: Categorie, index: number) =>

                        // Check if the category has to be displayed 
                        shouldDisplayCategory(order, cate, selectedCatalog, null, selectedTable) ?

                            (
                                <Element
                                    key={index}
                                    name={cate.ref}
                                    id={cate.ref}
                                    style={{ alignSelf: 'flex-start', width: "100%", margin: theme.spacing(index === 0 ? 0 : 2, 0) }}
                                >
                                    <List>

                                        <Typography
                                            variant="h2"
                                            color="textPrimary"
                                        >
                                            {cate.name}
                                        </Typography>
                                        {/**
                                         * Check if the category have subcategory. If true, map subCategory and for each check
                                         *  avaibility and render subCategory products or render category products
                                         */

                                            subCategoriesList(order, cate, selectedCatalog, selectedTable).length ?

                                                subCategoriesList(order, cate, selectedCatalog, selectedTable).map((subCategory: Categorie, subCategoryIndex: number) =>

                                                    <Element
                                                        key={subCategoryIndex}
                                                        name={subCategory.ref}
                                                        id={subCategory.ref}
                                                        style={{ alignSelf: 'flex-start', width: '100%' }}

                                                    >
                                                        <Typography color="textPrimary" style={{ margin: theme.spacing(1, 0), textAlign: "center" }}>
                                                            {subCategory.name}
                                                        </Typography>


                                                        <Box
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            flexWrap="wrap"
                                                        >
                                                            <AutoSizer disableHeight>
                                                                {({ height, width }: { height: number, width: number }) =>
                                                                    <Box display="flex" width={width} flexWrap="wrap">

                                                                        {listDeals(subCategory, selectedCatalog).map((deal: Deal, i: number) => (

                                                                            <Box key={i}>
                                                                                {
                                                                                    cate.display && cate.display === CategoryDisplayMode.GRID ? (
                                                                                        <Cards
                                                                                            openModal={openModal}
                                                                                            item={deal}
                                                                                            categoryIcon={cate.icon}
                                                                                        />
                                                                                    ) :
                                                                                        (<ProductsItemListComponent
                                                                                            openModal={openModal}
                                                                                            item={deal}
                                                                                            categoryIcon={cate.icon}
                                                                                            languages={supportedLanguages}
                                                                                            currentLang={currentLang}
                                                                                            catalogLang={selectedCatalog.language}
                                                                                        />)
                                                                                }

                                                                            </Box>
                                                                        ))}

                                                                        {listProducts(order, subCategory, selectedCatalog, selectedLocation.display_unavailable_product).map((product: Product, i: number) => (
                                                                            <Box key={i} >
                                                                                {cate.display && cate.display === CategoryDisplayMode.GRID ? (
                                                                                    <Cards
                                                                                        containerWidth={width}
                                                                                        openModal={openModal}
                                                                                        item={product}
                                                                                        categoryIcon={cate.icon}
                                                                                        disable={!productService.canDisplayItem(order, product, selectedCatalog)}
                                                                                    />
                                                                                ) :
                                                                                    (
                                                                                        <ProductsItemListComponent
                                                                                            width={width}
                                                                                            openModal={openModal}
                                                                                            item={product}
                                                                                            categoryIcon={cate.icon}
                                                                                            languages={supportedLanguages}
                                                                                            currentLang={currentLang}
                                                                                            catalogLang={selectedCatalog.language}
                                                                                        />
                                                                                    )}
                                                                            </Box>

                                                                        ))}
                                                                    </Box>}
                                                            </AutoSizer>

                                                        </Box>
                                                    </Element>
                                                )
                                                :
                                                (
                                                    <Box
                                                        display="flex"
                                                        flexWrap="wrap"
                                                        width={1}
                                                    >
                                                        <AutoSizer disableHeight>
                                                            {({ height, width }: { height: number, width: number }) =>
                                                                <Box display="flex" width={width} flexWrap="wrap">

                                                                    {listDeals(cate, selectedCatalog).map((deal: Deal, i: number) => (

                                                                        <Box key={i}>
                                                                            {
                                                                                cate.display && cate.display === CategoryDisplayMode.GRID ? (
                                                                                    <Cards
                                                                                        containerWidth={width}
                                                                                        openModal={openModal}
                                                                                        item={deal}
                                                                                        categoryIcon={cate.icon}
                                                                                    />
                                                                                ) :
                                                                                    (<ProductsItemListComponent
                                                                                        width={width}
                                                                                        openModal={openModal}
                                                                                        item={deal}
                                                                                        categoryIcon={cate.icon}
                                                                                        languages={supportedLanguages}
                                                                                        currentLang={currentLang}
                                                                                        catalogLang={selectedCatalog.language}
                                                                                    />)
                                                                            }
                                                                        </Box>

                                                                    ))}

                                                                    {listProducts(order, cate, selectedCatalog, selectedLocation.display_unavailable_product).map((product: Product, i: number) => (
                                                                        <Box key={i}>
                                                                            {
                                                                                cate.display && cate.display === CategoryDisplayMode.GRID ? (
                                                                                    <Cards
                                                                                        containerWidth={width}

                                                                                        openModal={openModal}
                                                                                        item={product}
                                                                                        categoryIcon={cate.icon}
                                                                                        disable={!productService.canDisplayItem(order, product, selectedCatalog)}
                                                                                    />
                                                                                ) : (<ProductsItemListComponent
                                                                                    openModal={openModal}
                                                                                    item={product}
                                                                                    width={width}
                                                                                    categoryIcon={cate.icon}
                                                                                    languages={supportedLanguages}
                                                                                    currentLang={currentLang}
                                                                                    catalogLang={selectedCatalog.language}
                                                                                />
                                                                                )
                                                                            }
                                                                        </Box>

                                                                    ))}
                                                                </Box>
                                                            }
                                                        </AutoSizer>
                                                    </Box>
                                                )}
                                    </List>

                                </Element>
                            )
                            : ""
                    )
                }
                {tablet_device &&
                    <Box style={{ position: "fixed", bottom: 10 }}>
                        <FabButtonOrder />
                    </Box>
                }

            </Box>
        )
    }
    return null
}

export default ProductsPageDesktop;