import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Checkbox, FormControlLabel, IconButton, Typography, useTheme } from '@mui/material';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import * as ROUTES from '../../config/routes';
import { RouteParams } from '../../config/routes';
import DealActions from '../../deals/redux/actions';
import { OrderDeal, OrderItem, OrderItemExtended, OrderOption } from '../../my-lemonade-library/model/Order';
import { MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { RootState, useTypedSelector } from '../../redux/root-reducer';
import OrderAction from '../redux/OrderActions';
import ReadOnlyProductDisplay from './ReadOnlyProductDisplay';


interface OrderDealDisplayProps {

    dealItems: OrderItem[];
    dealKey: string,

    readOnly: boolean;
    readOnlyDisabled?: boolean;  // grey out the item only if it's readOnly
    readOnlyPendingPayment?: boolean;  // Displays a chip to indicate that the item is pending payment

    moreDetails: boolean;
    selectedItems?: OrderItemToPay[];
    onSelect?: (val: OrderItemToPay[]) => void;
    isSharePayment?: boolean;
    alignWithCheckBoxes?: boolean;
}

const OrderDealDisplay: React.FC<OrderDealDisplayProps> = (props) => {

    const { dealItems, readOnly, readOnlyDisabled, readOnlyPendingPayment, moreDetails,
        selectedItems, onSelect, isSharePayment, alignWithCheckBoxes, dealKey } = props;

    const intl = useIntl();
    const theme = useTheme();

    const { order } = useTypedSelector((state: RootState) => state.order)
    const { selectedCatalog, selectedTable, selectedLocation } = useTypedSelector((state: RootState) => state.locations)
    const dispatch = useDispatch();
    const history = useHistory();
    let match = useRouteMatch<RouteParams>()
    const [dealSelected, setDealSelected] = useState<OrderDeal>()
    const [isChecked, setIsChecked] = useState<boolean>(false)

    useEffect(() => {
        log.debug('dealSelected')
        if (dealItems && dealItems.length) {
            const dealKey = dealItems[0].deal_line?.deal_key;
            if (dealKey && order.deals[dealKey]) {
                setDealSelected(order.deals[dealKey])
            }
        }
    }, [dealItems, order])

    useEffect(() => {
        if (selectedItems) {
            const indexes = dealItems.map(dealItem => (
                selectedItems.findIndex(item => item.index === (dealItem as OrderItemExtended).index)
            ))
            if (indexes.every(index => index !== -1)) {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }
        }
    }, [selectedItems])

    const editDeal = (ref: string) => {
        const deal = selectedCatalog && dealSelected ? selectedCatalog.data.deals.find(dl => dl.ref === dealSelected.ref) : undefined
        if (deal) {
            dealItems.forEach(item => {
                dispatch(DealActions.addDealItem(item, deal, selectedCatalog!, selectedLocation!, selectedTable.service_type!, order, selectedTable));
            })
            history.push(ROUTES.LocationHome.replace(":tableLinkKey", match.params.tableLinkKey) + ROUTES.Menu + "?item=" + ref)
        }
    }

    const displayDealItems = (): string => {
        return dealItems.map((item: OrderItem, index: number) => (
            `${item.product_name}${item.options.map((elem: OrderOption, index: number) => {
                return (index === 0 ? " - " : ", ") + elem.name
            }).join("")}`
        )).join(", ")
    }

    const deleteDeal = () => {
        // First delete the items of the deal, and after delete the deal to avoid empty deals.
        dealItems.forEach((item: OrderItem) => {
            dispatch(OrderAction.removeItem(item.sku_ref, item.options, selectedCatalog, selectedLocation!, selectedTable));
        })
        dispatch(OrderAction.removeDeal(dealKey))
    }

    const handleCheckbox = () => {
        if (onSelect) {
            let itemsToPayArray: OrderItemToPay[] = []
            dealItems.forEach(item => {
                const orderItemExt: OrderItemExtended = item as OrderItemExtended
                itemsToPayArray.push({ index: orderItemExt.index, quantity: isChecked ? 0 : 1 })
            })
            onSelect(itemsToPayArray)
        }
    }

    if (order && dealSelected) {

        return (
            <Box
                width="100%"
                px={3}
                py={1}
                pl={alignWithCheckBoxes && !readOnly ? 7 : 3}
                border="solid"
                style={{
                    borderWidth: "0em 0em .1em 0em",
                    borderColor: theme.palette.grey[100]
                }}
                data-test={`order-menu-item.ref.${dealSelected.ref}`}
            >
                {readOnly
                    ? <ReadOnlyProductDisplay
                        name={dealSelected.name}
                        productRef={dealSelected.ref}
                        price={MoneyToStringWithSymbol(dealSelected.price_with_options
                            ? dealSelected.price_with_options
                            : dealSelected.price)}
                        details={displayDealItems()}
                        disabled={readOnlyDisabled}
                        pendingPayment={readOnlyPendingPayment}
                    />

                    : <Box>
                        <Box display={"flex"} flexDirection={"row"} justifyContent="space-between">
                            <Box alignItems="start" pr={3}>
                                {isSharePayment
                                    ? <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={<CircleUnchecked style={{ color: theme.palette.text.disabled }} />}
                                                checkedIcon={<CheckCircleIcon color='primary' />}
                                                checked={isChecked}
                                                onChange={() => handleCheckbox()}
                                                style={{ height: '30px' }}
                                            />}
                                        label={
                                            <Typography
                                                variant='h5'
                                                data-test="order-menu-item.name"
                                            >
                                                {dealSelected.name}
                                            </Typography>
                                        }
                                    />

                                    : <Typography color="textSecondary" variant='h5' data-test="order-menu-item.name">
                                        {dealSelected.name}
                                    </Typography>
                                }


                                <Box
                                    ml={isSharePayment ? 4 : 0}
                                    style={
                                        moreDetails
                                            ? {}
                                            : {
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 3
                                            }
                                    }>
                                    <Typography color={theme.palette.text.disabled} variant='caption' data-test="order-menu-item.items">
                                        {displayDealItems()}
                                    </Typography>
                                </Box>

                            </Box>

                            {
                                !readOnly && !isSharePayment

                                    ? (<Box
                                        display="flex"
                                        flexDirection="row"
                                        m={0}
                                        alignSelf="start"
                                        alignItems="start"
                                        justifyContent='end'
                                    >
                                        <IconButton
                                            style={{ padding: 0, paddingRight: theme.spacing(1) }}
                                            onClick={() => editDeal(dealSelected.ref)}
                                            size="large">
                                            <CreateIcon style={{ color: theme.palette.grey[500], paddingTop: 0 }} />
                                        </IconButton>

                                        <IconButton style={{ padding: 0 }} onClick={() => deleteDeal()} size="large">
                                            <DeleteIcon style={{ color: theme.palette.grey[500] }} />
                                        </IconButton>
                                    </Box>)

                                    : null
                            }

                        </Box>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Box
                                ml={isSharePayment ? 4 : 0}
                            >
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle1"
                                >
                                    {intl.formatMessage({ id: "Price" })}
                                </Typography>
                            </Box>

                            <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                data-test="order-menu-item.price"
                            >
                                {MoneyToStringWithSymbol(dealSelected.price_with_options
                                    ? dealSelected.price_with_options
                                    : dealSelected.price)}
                            </Typography>

                        </Box>
                    </Box>
                }
            </Box>
        );
    }
    return null

}

export default OrderDealDisplay;