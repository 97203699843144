import HomeWorkIcon from '@mui/icons-material/HomeWork';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { Position as TurfPosition } from '@turf/helpers';
import log from "loglevel";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CustomerAddressDisplay from '../../customers/components/CustomerAddressDisplay';
import TimeSelectCard from "../../customers/components/TimeSelectCard";
import TimeSelectForm from "../../customers/components/TimeSelectForm";
import { DeliveryOrderExpectation, OrderExpectation } from "../../customers/models/OrderExpectation";
import { SupportedServiceType } from "../../my-lemonade-library/model/Location";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import Address from "../../my-lemonade-library/src/common/models/Address";
import { getAddressFromCustomer } from '../../my-lemonade-library/src/customers/services/CustomerHelper';
import { DeliveryAddressTypeChoice } from "../../my-lemonade-library/src/delivery/models/DeliveryAddressTypeChoice";
import { RootState, useTypedSelector } from "../../redux/root-reducer";
import { checkDeliveryAvailableAPI } from "../helpers/DeliveryHelpers";
import { getNewAddressPlaceHolder } from "../services/DeliveryServices";
import AddressDisplay from "./AddressDisplay";
import DeliveryAddressTextField from "./DeliveryAddressTextField";
import ExpectedTimeAsap from "./ExpectedTimeAsap";

interface DeliveryFullFormProps {
    setFullScreen: (param: boolean) => void;
    showAddressTextField: DeliveryAddressTypeChoice | undefined;
    setShowAddressTextField: (param: DeliveryAddressTypeChoice | undefined) => void;
    showTimeSelectScreen?: boolean;
    setShowTimeSelectScreen?: (param: boolean) => void;
    orderExpectation: OrderExpectation;
    setOrderExpectation: (orderExpectation: OrderExpectation) => void;
    isGetRestoTheme?: boolean;
    hideTimeSelector?: boolean
}

/**
 * This component will render the delivery information selector form. 
 * The user can chose the address and the time
 */
const DeliveryFullForm: React.FC<DeliveryFullFormProps> = (props) => {

    const { orderExpectation, setOrderExpectation, setFullScreen, showAddressTextField, setShowAddressTextField,
        showTimeSelectScreen, setShowTimeSelectScreen, isGetRestoTheme = false, hideTimeSelector = false } = props;

    const theme = useTheme();
    const intl = useIntl();
    const { selectedLocation, selectedTable } = useTypedSelector((state: RootState) => state.locations);
    const { data } = useTypedSelector((state: RootState) => state.authentication);
    const customer: Customer | null = data.user_authentication_state.user;

    const [displayDateTimeSelection, setDisplayDateTimeSelection] = useState<boolean>(false);

    /**
     * The "fixed address" part can be an address taken from the customer info (latest delivery order) or can be
     * imposed by the restaurant via the table restriction. It's not editable by the user (except for the delivery notes)
     */
    const [fixedAddressExpectation, setFixedAddressExpectation] = useState<DeliveryOrderExpectation>();
    const [fixedAddressLoading, setFixedAddressLoading] = useState<boolean>(false);
    const [fixedAddressError, setFixedAddressError] = useState<boolean>(false);

    const [newAddressExpectation, setNewAddressExpectation] = useState<DeliveryOrderExpectation>({ addressType: DeliveryAddressTypeChoice.NEW_ADDRESS });

    /**
     * Tracks which address type has been selected. Only for UI purposes, the actual address with the final type choice
     * is stored in the "orderExpectation" object (local state of parent component).
     */
    const [deliveryAddressEditType, setDeliveryAddressEditType] = useState<DeliveryAddressTypeChoice>();

    /**
     * Filling the fixed address with the customer latest one if available.
     * NOTE: The availability will be checked when the user clicks on the address.
     */
    useEffect(() => {

        const latestAddress = getAddressFromCustomer(customer ?? undefined);

        // Do not override the fixed address if it's coming from the table restriction
        if (latestAddress && fixedAddressExpectation?.addressType !== DeliveryAddressTypeChoice.FIXED_ADDRESS) {
            const expectation: DeliveryOrderExpectation = {
                addressType: DeliveryAddressTypeChoice.LAST_USED,
                address: latestAddress,
            }
            if (customer?.delivery_notes) {
                expectation.delivery_note = customer.delivery_notes;
            }
            setFixedAddressExpectation(expectation);
        }
    }, [customer]);

    /**
     * If the table has a fixed address in its restriction, we set it in the "fixedAddress" field (and we'll hide the
     * address selector)
     * NOTE: the availability is checked right now because the user doesn't have any other address choice.
     */
    useEffect(() => {
        if (selectedTable.delivery_restrictions?.address?.position) {
            const address = selectedTable.delivery_restrictions.address
            const latitude = selectedTable.delivery_restrictions.address.position.latitude
            const longitude = selectedTable.delivery_restrictions.address.position.longitude
            const expectation: DeliveryOrderExpectation = {
                addressType: DeliveryAddressTypeChoice.FIXED_ADDRESS,
                address,
                delivery_note: selectedTable.delivery_restrictions?.delivery_notes,
            }
            setFixedAddressExpectation(expectation);
            checkAndApplyFixedAddress(
                latitude,
                longitude,
                address,
                selectedTable.delivery_restrictions.delivery_notes,
                DeliveryAddressTypeChoice.FIXED_ADDRESS
            );
        }
    }, [selectedTable]);

    /**
     * 
     * @param address 
     * @param deliveryZone 
     * @param deliveryNotes 
     */
    const onNewAddressEnteredOrUpdated = (deliveryExpectation: DeliveryOrderExpectation) => {

        const { address, delivery_zone, addressType, delivery_note } = deliveryExpectation;

        if (address && delivery_zone && addressType) {
            setDeliveryAddressEditType(DeliveryAddressTypeChoice.NEW_ADDRESS)
            setOrderExpectation({
                ...orderExpectation,
                address: address,
                addressType: addressType,
                delivery_zone: delivery_zone,
                delivery_note: delivery_note,
            })

            // Reset the time as the delay depends on the delivery zone
            if (orderExpectation.expected_asap === false && orderExpectation.dateTimeSelected) {
                setDateTimeAndAsap(false, undefined)
            }
        }
    }

    const setDateTimeAndAsap = (isAsap: boolean, date?: Date) => {
        setOrderExpectation({
            ...orderExpectation,
            expected_asap: isAsap,
            dateTimeSelected: date
        })
    }

    /**
     * Fixed address in "FIXED_ADDRESS" mode (i.e. from restrictions) cannot be clicked.
     * It means that we're in "LAST_USED" mode.
     */
    const onLatestAddressClicked = () => {
        const customerAddress: Address | null | undefined = fixedAddressExpectation?.address;
        if (customerAddress?.position?.latitude && customerAddress.position.longitude) {
            checkAndApplyFixedAddress(
                customerAddress.position.latitude,
                customerAddress.position.longitude,
                customerAddress,
                fixedAddressExpectation?.delivery_note,
                DeliveryAddressTypeChoice.LAST_USED
            );
        }
        else {
            log.error("The customer address is not valid, cannot set it");
        }
    }

    /**
     * Given an address, latitude and longitude, call the whole process of getting a
     * delivery zone, fetching the API, etc. Then if everything went well, set the
     * address and delivery zone in the parent component.
     * @param latitude 
     * @param longitude 
     * @param address 
     * @param addressType
     * @returns 
     */
    const checkAndApplyFixedAddress = async (
        latitude: number,
        longitude: number,
        address: Address,
        deliveryNotes: string | undefined,
        addressType: DeliveryAddressTypeChoice | undefined,
    ) => {

        setDeliveryAddressEditType(addressType);

        if (!address || !selectedLocation) {
            setFixedAddressError(true);
            return;
        }

        // Display the loading circle and message
        setFixedAddressLoading(true);

        const coordinates: TurfPosition = [longitude, latitude];
        const checkDeliveryAvailableResult = await checkDeliveryAvailableAPI(coordinates, address, selectedLocation, selectedTable);
        const zoneFound = checkDeliveryAvailableResult?.checked_delivery_zone;

        if (zoneFound) {

            if (selectedTable.delivery_restrictions?.delivery_provider_ref) {
                zoneFound.supported_provider_refs = [selectedTable.delivery_restrictions.delivery_provider_ref];
            }

            setFixedAddressError(false);
            setOrderExpectation({
                ...orderExpectation,
                ...fixedAddressExpectation,
                delivery_zone: zoneFound,
                addressType,
                address,
                delivery_note: deliveryNotes,
            });

        } else {
            setFixedAddressError(true);
        }
        setFixedAddressLoading(false);
    }

    if (!selectedLocation) {
        return null;
    }

    return (

        <Box
            width={1}
            height={showAddressTextField ? 1 : "fit-content"}
        >

            {!showAddressTextField &&

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={1}
                    style={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                    px={isGetRestoTheme ? 2 : 0}
                    border={isGetRestoTheme ? 1 : 0}
                    borderLeft={0}
                    borderRight={0}
                    borderColor='grey.300'
                >

                    <Box width={1}>

                        <Typography
                            variant='h5'
                            style={{ alignSelf: 'flex-start', paddingTop: theme.spacing(2) }}
                        >
                            {intl.formatMessage({
                                id: 'customer_information_modal.delivery_address.title',
                                defaultMessage: 'Address'
                            })}
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width={1}
                        >
                            {
                                //////////////////
                                // NEW ADDRESS
                                //////////////////
                            }
                            {!selectedTable.delivery_restrictions?.address &&
                                <AddressDisplay
                                    dataTestInput="select-delivery-address-button"
                                    dataTestReminder="delivery-form-delivery-note-reminder"
                                    isInput
                                    onInputClick={() => {
                                        setShowAddressTextField(DeliveryAddressTypeChoice.NEW_ADDRESS);
                                        setFullScreen(true);
                                    }}
                                    icon={<SearchOutlinedIcon style={{ color: theme.palette.grey[500] }} />}
                                    addressToDisplay={getNewAddressPlaceHolder(newAddressExpectation.address)[0]}
                                    addressNote={newAddressExpectation.delivery_note}
                                    checked={deliveryAddressEditType === DeliveryAddressTypeChoice.NEW_ADDRESS}
                                />
                            }

                            {
                                //////////////////
                                // FIXED ADDRESS
                                //////////////////
                            }

                            {fixedAddressExpectation &&
                                <>
                                    {selectedTable.delivery_restrictions?.address

                                        ? <CustomerAddressDisplay
                                            isGetRestoTheme={isGetRestoTheme}
                                            address={selectedTable.delivery_restrictions.address.address_1}
                                            postalCode={selectedTable.delivery_restrictions.address.postal_code}
                                            city={selectedTable.delivery_restrictions.address.city}
                                            name={selectedTable.name}
                                        />

                                        : <AddressDisplay
                                            dataTestInput="select-delivery-last-address"
                                            dataTestReminder="delivery-form-last-address-delivery-note-reminder"
                                            isInput
                                            onInputClick={() => onLatestAddressClicked()}
                                            icon={<HomeWorkIcon style={{ color: theme.palette.grey[500] }} />}
                                            title={intl.formatMessage({ id: "customer_information_modal.delivery.customer_address" })}
                                            addressToDisplay={getNewAddressPlaceHolder(fixedAddressExpectation.address)[0]}
                                            addressNote={fixedAddressExpectation.delivery_note}
                                            checked={deliveryAddressEditType === DeliveryAddressTypeChoice.LAST_USED}
                                            onNoteClick={() => {
                                                setShowAddressTextField(DeliveryAddressTypeChoice.LAST_USED);
                                                setFullScreen(true);
                                            }}
                                        />
                                    }

                                    {fixedAddressLoading &&
                                        <Box
                                            data-test="loader-check-last-address"
                                            marginY={1}
                                            display="flex"
                                            width={1}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress
                                                size="1em"
                                                style={{
                                                    color: theme.palette.primary.main,
                                                    margin: theme.spacing(0, 2, 0, 0)
                                                }}
                                            />
                                            <Typography color="primary">
                                                {intl.formatMessage({ id: "customer_information_modal.delivery.form.loading" })}
                                            </Typography>
                                        </Box>
                                    }

                                    {fixedAddressError &&
                                        <Box
                                            marginBottom={1}
                                            display="flex"
                                            width={1}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography color="primary" data-test="customer_information_modal.delivery.error">
                                                {intl.formatMessage({ id: "customer_information_modal.delivery.error" })}
                                            </Typography>
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
            }

            {
                isGetRestoTheme
                && !showTimeSelectScreen
                && !showAddressTextField
                &&
                <Box my={1}></Box>
            }

            {!showTimeSelectScreen
                && !showAddressTextField
                && setShowTimeSelectScreen
                && setDateTimeAndAsap
                && !hideTimeSelector
                &&

                <Box
                    style={{ backgroundColor: theme.palette.background.paper }}
                    p={isGetRestoTheme ? 2 : 0}
                    border={isGetRestoTheme ? 1 : 0}
                    borderLeft={0}
                    borderRight={0}
                    borderColor='grey.300'
                >
                    <Typography variant='h5' style={{ marginBottom: theme.spacing(2) }}>
                        {intl.formatMessage({
                            id: 'customer_information_modal.dateTime.schedule',
                            defaultMessage: 'Schedule'
                        })}
                    </Typography>

                    <ExpectedTimeAsap
                        deliveryZoneRef={orderExpectation.delivery_zone ? orderExpectation.delivery_zone.ref : undefined}
                        onChange={setDateTimeAndAsap}
                        checked={orderExpectation.expected_asap}
                        dateTimeSelected={orderExpectation.dateTimeSelected}
                        serviceType={SupportedServiceType.DELIVERY}
                    />


                    <TimeSelectCard
                        onClick={() => {
                            setDateTimeAndAsap(false)
                            setDisplayDateTimeSelection(true);
                        }}
                        checked={orderExpectation.expected_asap === false}
                    />
                    {
                        displayDateTimeSelection
                        && !showAddressTextField
                        && orderExpectation.expected_asap === false
                        && setDateTimeAndAsap
                        &&

                        <TimeSelectForm
                            deliveryZoneRef={orderExpectation.delivery_zone?.ref}
                            serviceType={SupportedServiceType.DELIVERY}
                            dateTimeSelected={orderExpectation.dateTimeSelected}
                            onDateTimeSelectedChange={setDateTimeAndAsap}
                            isGetRestoTheme={isGetRestoTheme}
                        />
                    }
                </Box>
            }

            {
                showAddressTextField &&

                <DeliveryAddressTextField
                    onPreviousClick={() => {
                        setShowAddressTextField(undefined);
                        setFullScreen(false);
                    }}
                    addressToEdit={showAddressTextField === DeliveryAddressTypeChoice.NEW_ADDRESS ? newAddressExpectation : fixedAddressExpectation ?? {}}
                    onAddressToEditChange={showAddressTextField === DeliveryAddressTypeChoice.NEW_ADDRESS ? setNewAddressExpectation : setFixedAddressExpectation}
                    onNewAddressEntered={onNewAddressEnteredOrUpdated}
                    isGetRestoTheme={isGetRestoTheme}
                    updateOnlyNote={showAddressTextField === DeliveryAddressTypeChoice.LAST_USED}
                />
            }


        </Box >
    );
}

export default DeliveryFullForm;
