export enum CategoryReferenceLevel1 {
    ADULT_BEVERAGES = "Adult Beverages",
    NON_ALCOHOLIC_BEVERAGES = "Non-Alcoholic Beverages",
    HOT_BEVERAGES = "Hot Beverages",

    STARTER = "Starter",
    MAIN_COURSE = "Main Course",
    DESSERT = "Dessert"
}

export const categoryReferenceLevel1Keywords: { [key in CategoryReferenceLevel1]: string[] } = {
    [CategoryReferenceLevel1.ADULT_BEVERAGES]: ["adult"],
    [CategoryReferenceLevel1.NON_ALCOHOLIC_BEVERAGES]: ["non alcoholic", "non-alcoholic", "non-alcohol"],
    [CategoryReferenceLevel1.HOT_BEVERAGES]: ["hot"],

    [CategoryReferenceLevel1.STARTER]: [],
    [CategoryReferenceLevel1.MAIN_COURSE]: [],
    [CategoryReferenceLevel1.DESSERT]: []
}

export const category1_options = Object.values(CategoryReferenceLevel1);