import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";
import { LOCATION_ROUTE } from "../../locations/configs/LocationsApiRoutes";

export const ORDER_ID_PARAM_KEY = "orderId";
export const ORDER_REF_PARAM_KEY = "orderRef";
export const ORDER_ID_PARAM = `:${ORDER_ID_PARAM_KEY}`;
export const ORDER_REF_PARAM = `:${ORDER_REF_PARAM_KEY}`;
export const ORDER_CREATE = `${ROUTES_PREFIX_V1}/orders`;
export const ORDER_IMPORT = `${ORDER_CREATE}/import`;
export const ORDER_BY_ID = `${ORDER_CREATE}/${ORDER_ID_PARAM}`;
export const ORDER_QR_CODE = `${ORDER_BY_ID}/qrcode`;
export const ORDER_INVOICE = `${ORDER_BY_ID}/invoice`;
export const ORDER_PAY = `${ORDER_BY_ID}/payments`;
export const ORDER_MANUAL_PAY = `${ORDER_BY_ID}/manual-payments`;
export const ORDER_EARN_LOYALTY = `${ORDER_BY_ID}/earn-loyalty`;
export const ORDER_ADD_ITEMS = `${ORDER_BY_ID}/items`;
export const ORDER_CHANGE_TABLE = `${ORDER_BY_ID}/table`;
export const ORDER_REFUND = `${ORDER_BY_ID}/refund`;
export const ORDER_REFRESH_STATUS = `${ORDER_CREATE}/refresh_status`;
export const ORDERS_FETCH_TABLE = `${ORDER_CREATE}/fetch-table`;
export const LOCATION_ORDERS = `${LOCATION_ROUTE}/${MylemonadeScopes.ORDERS}`;
export const LOCATION_ORDER_BY_ID = `${LOCATION_ORDERS}/${ORDER_ID_PARAM}`;
export const ORDERS_PENDING = `${LOCATION_ROUTE}/${MylemonadeScopes.ORDERS}/connector-pending`;
export const GET_ORDER_BY_KEY = "by";
export const ORDER_BY_REF_KEY = "byRef";
export const ORDER_BY_RECEIPT_REF_KEY = "byReceiptRef";
export const ORDER_TOGGLE_LOYALTY_USAGE = `${ORDER_BY_ID}/toggle-loyalty-usage`;
export const ORDER_ADD_CONTRIBUTOR = `${ORDER_BY_ID}/add-contributor`;
export const ORDER_RETRY = `${ORDER_BY_ID}/retry`;
export const ORDER_PRINT_RECEIPT = `${LOCATION_ORDER_BY_ID}/print-receipt`;

export const FROM_DATE_KEY = "from";

const ordersApiRoutes = {
    ORDER_CREATE,
    ORDER_IMPORT,
    ORDER_BY_ID,
    ORDER_PAY,
    ORDER_MANUAL_PAY,
    ORDER_EARN_LOYALTY,
    ORDER_ADD_ITEMS,
    ORDER_CHANGE_TABLE,
    ORDER_REFUND,
    ORDER_QR_CODE,
    ORDER_INVOICE,
    ORDER_REFRESH_STATUS,
    ORDERS_FETCH_TABLE,
    ORDER_TOGGLE_LOYALTY_USAGE,
    ORDER_ADD_CONTRIBUTOR,
    LOCATION_ORDERS,
    ORDERS_PENDING,
    ORDER_RETRY,
    ORDER_PRINT_RECEIPT,
};
export default ordersApiRoutes;