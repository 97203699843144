import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import log from 'loglevel';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DeviceContext } from '../../App';
import { isEntityOrderableNow } from '../../catalog/services/CatalogService';
import AddButton from '../../Common/components/AddButton';
import EntityUnavailableText from '../../Common/components/EntityUnavailableText';
import useShowAlcoholDisclaimer from '../../Common/hooks/useShowAlcoholDisclaimer';
import { AddButtonRedirect } from '../../Common/models/AddButtonRedirect';
import { isTemporallyAvailableForOrder } from '../../my-lemonade-library/functions/Helpers';
import { Deal, DealLine, getTimezoneName, Product, Sku } from '../../my-lemonade-library/model/Catalog';
import { CatalogExtended } from '../../my-lemonade-library/model/catalogExtended/CatalogExtended';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderItem, OrderOption } from '../../my-lemonade-library/model/Order';
import { SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { moneyToNumber, numberToMoney } from '../../my-lemonade-library/src/common/models/Money';
import FullDealSku from '../../my-lemonade-library/src/deals/models/FullDealSku';
import { computeSkuDealPriceNumber, getDealLineRef } from '../../my-lemonade-library/src/deals/services/DealHelper';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import AlcoholDisclaimerModal from '../../Products/component/AlcoholDisclaimerModal';
import ProductModal from '../../Products/pages/ProductModal';
import { useTypedSelector } from '../../redux/root-reducer';
import actions from '../redux/actions';
import DealLinesDisplay from './DealLinesDisplay';

/**
 * Component in charge to display the items list from a deal type Menu
 * @param props 
 */
interface DealDisplayProps {
    deal: DealExt,
    interactif: boolean,
    onSubmit: (redirect: AddButtonRedirect) => void,
    displayOrderButton: boolean
}
const DealDisplay: React.FC<DealDisplayProps> = (props) => {

    const { deal, onSubmit } = props

    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();
    const refs = useRef<HTMLDivElement[]>([]);
    const dispatch = useDispatch();

    const { selectedCatalog, isLoading, selectedTable, selectedLocation } = useTypedSelector(state => state.locations)
    const { listItems, dealPrice } = useTypedSelector(state => state.deals)
    const { order } = useTypedSelector(state => state.order)

    const isEntityOrderableNowResult = isEntityOrderableNow(selectedCatalog, selectedLocation, selectedTable, deal);

    const [currentDealLineIndex, setCurrentDealLineIndex] = useState<number>(0)
    const [open, setOpen] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
    const [selectedSku, setSelectedSku] = useState<SkuExtended | undefined>(undefined)

    /**
     * Price is updated each time something is added
     */
    const [currentDealPrice, setCurrentDealPrice] = useState<number>(0);

    const [accordionsExpander, setAccordionsExpander] = useState<boolean[]>()
    const [selectedSkus, setSelectedSkus] = useState<{ skuRef: string, product: Product | null }[]>([])
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [openAlcoholModal, setOpenAlcoholModal] = useState<boolean>(false)
    const [addButtonRedirect, setAddButtonRedirect] = useState<AddButtonRedirect>(AddButtonRedirect.CATALOG);

    const { tablet_portrait, mobile_device, desktop_device } = useContext(DeviceContext)
    const smView = useMediaQuery(theme.breakpoints.up("sm"))
    const mdView = useMediaQuery(theme.breakpoints.up('md'))

    const shouldDisplayAlcoholDisclaimer = useShowAlcoholDisclaimer(selectedSkus, true)
    const selectedDeal: DealExt | null = deal
    const hasSomeOption = deal?.lines?.some((dealLine) => dealLine.skus.length > 1);


    // Display the minimum price of the deal or display the current price if higher
    useEffect(() => {
        if (selectedCatalog) {
            const numDealStartingPrice: number = selectedDeal.starting_price
                ? moneyToNumber(selectedDeal.starting_price, false, selectedDeal)
                : 0
            if (dealPrice > numDealStartingPrice) {
                setCurrentDealPrice(dealPrice)
            } else {
                setCurrentDealPrice(numDealStartingPrice)
            }
            const missingSku = selectedSkus.find((sku) => !sku.skuRef)
            if (listItems.length === selectedDeal.lines.length && !missingSku) {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
        }
    }, [selectedCatalog, dealPrice, listItems, selectedDeal, selectedSkus]);


    // Set the base state for the accordions and the selected skus
    useEffect(() => {
        if (!accordionsExpander) {
            setAccordionsExpander(deal.lines.map(line => true))
        }

        if (selectedSkus.length === 0) {
            setSelectedSkus(deal.lines.map(line => ({ skuRef: '', product: null })))
        }
    }, [deal])


    /**
    * Open/Close accordions
    * @param index 
    * @param bool Optionnal parameter to force only open or close
    */
    const handleAccordion = (index: number, bool?: boolean): void => {

        if (accordionsExpander) {

            const tempArray: boolean[] = [...accordionsExpander]
            tempArray[index] = (bool !== undefined && bool !== null) ? bool : !accordionsExpander[index]
            setAccordionsExpander(tempArray)
        }
    }

    const getDealTranslation = (translationKey?: string, defaultTranslation?: string): string | undefined => {

        if (translationKey && intl.messages[translationKey]) {
            return intl.formatMessage({ id: translationKey });
        }

        log.debug(`Missing translation for deal (name or description id: ${translationKey})`);
        return defaultTranslation;
    }

    /**
     * getFullDealSkuList
     * Retrieve the list of full deal sku including for each one: product, sku, deal_price, full_extra_charge
     * @param line 
     */
    const getFullDealSkuList = (line: DealLine) => {

        let productIncluded: FullDealSku[] = []

        if (selectedCatalog) {
            let minLinePrice = Number.MAX_VALUE;
            //Map skus ref in line
            line.skus.forEach(dealSku => {

                const productList = selectedCatalog.data.products;

                // iter product
                productList.forEach((product: Product) => {
                    // TODO check category as well ?
                    const productAvailable = !product.disable && (!product.restrictions || isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), product.restrictions, false));
                    // iter sku for eachProduct
                    product.skus.forEach((sku: Sku) => {
                        //if sku is in deal, build the full deal sku
                        // TODO: check restriction as well
                        if (sku.ref === dealSku.ref && !sku.disable && productAvailable) {

                            let dealPrice = computeSkuDealPriceNumber(sku, line);
                            if (!dealPrice) {
                                dealPrice = 0;
                            }
                            if (dealPrice < minLinePrice) {
                                minLinePrice = dealPrice;
                            }
                            const productInDeal: FullDealSku = {
                                ...dealSku,
                                product: product,
                                sku: sku,
                                deal_price: numberToMoney(dealPrice ? dealPrice : 0, selectedCatalog.currency)
                            }
                            productIncluded.push(productInDeal)

                        }
                    })
                })

                // Now that we have computed the price for each, conpute the full extra charge
                productIncluded.forEach((fullDealSku) => {
                    const fullExtraCharge = moneyToNumber(fullDealSku.deal_price, false, fullDealSku) - minLinePrice;
                    if (fullExtraCharge) {
                        fullDealSku.full_extra_charge = numberToMoney(fullExtraCharge, selectedCatalog.currency);
                    } else {
                        fullDealSku.full_extra_charge = undefined;
                    }
                });
            })
        }
        return productIncluded
    }


    // Modal in order to select options
    const openModal = (product: Product, sku: SkuExtended, index: number) => {
        setSelectedProduct(product)
        setSelectedSku(sku)
        setCurrentDealLineIndex(index)
        setOpen(true)
    }


    // final order constitution
    const updateItemOrder = (product: Product, skuSelected: Sku, lineIndex: number) => {
        const orderItems: OrderItem = {
            product_ref: product.ref,
            product_name: product.name,
            sku_ref: skuSelected.ref,
            quantity: 1,
            options: [],
            price: selectedDeal.lines[lineIndex].pricing_value as string,
            deal_line: {
                deal_key: selectedDeal.ref,
                deal_line_ref: getDealLineRef(selectedDeal, lineIndex),
                deal_line_index: lineIndex
            }
        }
        return orderItems as OrderItem
    }


    const updateItemOption = (product: Product, skuSelected: Sku, lineIndex: number, optionArray?: any[]) => {

        if (skuSelected.option_list_refs && skuSelected.option_list_refs.length && open === false) {
            openModal(product, skuSelected, lineIndex)
        } else if (selectedTable.service_type !== SupportedServiceType.VIEW) {

            let orderItem: OrderItem = updateItemOrder(product, skuSelected, lineIndex)
            const optionOrderList: any[] = []
            if (optionArray) {
                optionArray.forEach(opt => {
                    const option: OrderOption = {
                        name: opt.name,
                        option_list_name: opt.option_list_ref,
                        ref: opt.ref,
                        option_list_ref: opt.option_list_ref,
                        price: opt.price,
                    };
                    optionOrderList.push(option)
                })
            }
            orderItem = { ...orderItem, options: optionOrderList } as OrderItem

            dispatch(actions.addDealItem(
                orderItem, selectedDeal, selectedCatalog!, selectedLocation!, selectedTable.service_type!, order, selectedTable
            ));

            const tempSelectedSkus = [...selectedSkus]
            tempSelectedSkus[lineIndex] = { skuRef: skuSelected.ref, product }
            setSelectedSkus(tempSelectedSkus)
            handleAccordion(lineIndex, false)
        }
    }


    const imageToRender = (deal: Deal, selectedCatalog: CatalogExtended) => {
        if (deal.image) {
            return deal.image
        } else {
            const skuListRef: string[] = []
            deal.lines.forEach(line => {
                line.skus.forEach(sku => {
                    skuListRef.push(sku.ref)
                })
            })
            let imageToRender: string = ""
            let i = 0
            while (i < (skuListRef.length)) {
                const skuRef = skuListRef[i]
                const product = selectedCatalog.data.products.find(product => product.skus.find(sku => sku.ref === skuRef))
                if (product && product.image) {
                    imageToRender = product.image
                    i = skuListRef.length
                } else {
                    i++
                }

            }
            return imageToRender
        }
    }

    const handleSubmitDeal = (redirect: AddButtonRedirect) => {

        setAddButtonRedirect(redirect);

        if (shouldDisplayAlcoholDisclaimer) {
            setOpenAlcoholModal(true);
        } else {
            onSubmit(redirect);
        }
    }

    if (selectedDeal && !isLoading && deal && selectedCatalog) {

        return (

            <Box
                id="deal_display"
                width={1}
                height={1}
                display="flex"
                position='relative'
                flexDirection={mdView ? "row" : "column"}
            >

                {selectedProduct &&
                    <ProductModal
                        open={open}
                        closeModal={() => setOpen(false)}
                        buttonTitleId="deals.product.chooseButton"
                        selectedProduct={selectedProduct}
                        dealSku={selectedSku}
                        hideQuantityButton={true}
                        onAddProduct={
                            (selectedProduct: Product, selectedOptions: OrderOption[], selectedSku: Sku, quantity: number) =>
                                updateItemOption(selectedProduct, selectedSku, currentDealLineIndex, selectedOptions)
                        }
                        hideReorder
                        forceImageDisplay
                        forceDescriptionDisplay
                        forcedBackToLabel={getDealTranslation(translationService.getDealNameTranslationKey(selectedDeal), selectedDeal.name)}
                    />
                }

                <AlcoholDisclaimerModal
                    open={openAlcoholModal}
                    onClose={() => setOpenAlcoholModal(false)}
                    onAccept={() => {
                        setOpenAlcoholModal(false)
                        onSubmit(addButtonRedirect)
                    }}
                />

                {smView &&
                    <Box
                        pb={3}
                        width={mdView ? "50%" : "100%"}
                        height={mdView ? "100%" : "30%"}
                        style={{
                            position: mdView ? "absolute" : "unset",
                            top: 0,
                            left: 0,
                            background: `url(${imageToRender(deal, selectedCatalog)})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            borderRadius: "20px"
                        }} />
                }

                <Box
                    px={2}
                    my={2}
                    width="100%"
                    alignSelf="center"
                    textAlign="left"
                >
                    <Typography variant='h2'>
                        {getDealTranslation(translationService.getDealNameTranslationKey(selectedDeal), selectedDeal.name)}
                    </Typography>
                </Box>

                {
                    selectedDeal.description && mobile_device ? (

                        <Box
                            px={2}
                            mb={2}
                            width="100%"
                            alignSelf="center"
                            textAlign="left"
                            color={theme.palette.text.disabled}
                        >
                            <Typography variant="body1">
                                {getDealTranslation(translationService.getDealDescriptionTranslationKey(selectedDeal), selectedDeal.description)}
                            </Typography>
                        </Box>
                    ) : ("")
                }

                <Box
                    width={1}
                    style={!mobile_device ? { overflow: 'scroll', overflowX: 'hidden' } : {}}
                >
                    <Box
                        mb={
                            isEntityOrderableNowResult
                                ? mobile_device ? 10 : 4
                                : 1
                        }
                        width={1}
                    >

                        {!mobile_device && (
                            <Box
                                p={theme.spacing(tablet_portrait ? 1 : 0, 2, 0, 2)}
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                width={1}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => history.goBack()}
                                    startIcon={
                                        <ArrowBackIosIcon
                                            fontSize='small'
                                            sx={{ color: theme.palette.secondary.main }}
                                        />
                                    }
                                >
                                    <FormattedMessage id="product_detail.back.button" />
                                </Button>

                                <Box
                                    pt={3}
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignSelf="flex-start"
                                    width="100%"
                                >
                                    <Typography variant="h2" color="textPrimary" style={{ flex: 1 }}>
                                        {getDealTranslation(translationService.getDealNameTranslationKey(selectedDeal), selectedDeal.name)}
                                    </Typography>

                                </Box>

                                {
                                    selectedDeal.description && (

                                        <Box
                                            mb={2}
                                            width="100%"
                                            alignSelf="center"
                                            textAlign="left"
                                            color={theme.palette.text.disabled}
                                        >
                                            <Typography variant="body1">
                                                {getDealTranslation(translationService.getDealDescriptionTranslationKey(selectedDeal), selectedDeal.description)}
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </Box>
                        )}
                        {//Map each deal line
                            selectedDeal.lines && hasSomeOption && selectedDeal.lines.map((line: DealLine, lineIndex: number) => {

                                {
                                    // If the line has only one sku, do not show as it's already selected by INIT_DEAL
                                }
                                return (line.skus.length == 0 || (line.skus.length == 1 && line.hide)) ? (<></>) : (
                                    <Box
                                        key={lineIndex}
                                        pb={lineIndex + 1 < selectedDeal.lines.length
                                            && accordionsExpander
                                            && accordionsExpander[lineIndex]
                                            && mobile_device
                                            ? 4
                                            : 0}
                                        position='relative'
                                        mr={mdView ? 2 : 0}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        width={1}
                                        bgcolor='secondary'
                                    >

                                        {accordionsExpander &&
                                            <Accordion
                                                ref={(element) => { refs.current[lineIndex] = element as HTMLDivElement }}
                                                style={mobile_device
                                                    ? { width: '100%' }
                                                    : { width: '100%', backgroundColor: theme.palette.background.default }
                                                }
                                                expanded={accordionsExpander && accordionsExpander[lineIndex]}
                                                onChange={() => handleAccordion(lineIndex)}
                                            >

                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                >

                                                    <Box display='flex' flexDirection='column'>
                                                        <Typography
                                                            variant="h5"
                                                            color="textSecondary"
                                                            style={{
                                                                alignSelf: 'flex-start',
                                                                textTransform: "capitalize",
                                                            }}
                                                        >
                                                            {line.name ? line.name.toUpperCase()
                                                                : (line.label ? line.label.toUpperCase : "line " + lineIndex)
                                                            }
                                                        </Typography>

                                                        {selectedSkus[lineIndex]
                                                            && selectedSkus[lineIndex].product
                                                            && line.skus.some(sku => sku.ref === selectedSkus[lineIndex].skuRef) &&
                                                            <Typography variant='body1' style={{ color: theme.palette.text.disabled }}>
                                                                {intl.formatMessage({
                                                                    id: translationService.getProductNameTranslationKey(selectedSkus[lineIndex].product as Product),
                                                                    defaultMessage: selectedSkus[lineIndex].product?.name
                                                                })}
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </AccordionSummary>

                                                <AccordionDetails style={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                >
                                                    <DealLinesDisplay
                                                        dealSkus={getFullDealSkuList(line)}
                                                        index={lineIndex}
                                                        onChange={updateItemOption}
                                                        selected={selectedSkus[lineIndex].skuRef}
                                                    />
                                                </AccordionDetails>

                                            </Accordion>
                                        }
                                    </Box>
                                )
                            })
                        }

                    </Box>

                    {/* Add button */}
                    {
                        isEntityOrderableNowResult
                            ? <Box
                                position={!mobile_device ? 'inherit' : 'fixed'}
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                                style={{
                                    bottom: theme.spacing(2),
                                    left: theme.spacing(2),
                                    right: theme.spacing(2),
                                }}
                            >
                                {selectedCatalog
                                    ? <AddButton
                                        onSubmit={handleSubmitDeal}
                                        price={numberToMoney(currentDealPrice, selectedCatalog.currency)}
                                        isButtonDisabled={isButtonDisabled}
                                        displayShadow
                                        displayFastCheckoutButton={!desktop_device}
                                    />
                                    : null
                                }
                            </Box>

                            : <Box
                                width={1}
                                display="flex"
                                padding={2}
                            >
                                <EntityUnavailableText
                                    serviceType={order.service_type}
                                    entity={selectedDeal}
                                />
                            </Box>
                    }
                </Box>
            </Box>
        )
    }

    return (
        <div>no deal</div>
    )

}

export default DealDisplay;
